// Npm
import { Fragment, useEffect, useState } from "react";
import { StyleSheet, css } from "aphrodite";
import { Colors } from "../../utils/colors";

// Component
import squareOne from "../../assets/square-one.png";
import squareTwo from "../../assets/square-two.png";
import squareThree from "../../assets/square-three.png";
import MembershipLevel from "../MembershipLevel";

import Text from "../Text";
import Button from "../Button";
import Wrapper from "../Wrapper";
import ComingSoonModal from "../Modals/ComingSoonModal";
import { OPENING_DATE } from "../../utils/constants";

const Membership = ({ isMobileDimension }) => {
  const [isOpen, setIsOpen] = useState(false);
  const levels = [
    {
      icon: squareOne,
      label: "Level 1",
      description: "Member",
      text: "Membership to the Lobby3 DAO gives you access to our community. Benefits include:",
      price: "0.07 ETH ea.",
      perks: [
        [
          "Discord Access",
          "Access to the member discord, where we will gather like-minded innovators and leaders in the Web3 space",
        ],
        [
          "Status Updates",
          "Updates on advocacy efforts directly from Andrew Yang, as well as from policy experts working in the trenches",
        ],
        [
          "AMAs w/ Andrew Yang",
          "Frequent Ask-Me-Anything or various Q&A events with Andrew Yang and other leaders in the community",
        ],
        [
          "Events & Merch",
          "Early access and discounted rates on community events, merchandise, and other perks",
        ],
        [
          "Voting",
          "One token = one vote on community issues, guest speakers, policy suggestions, and more",
        ],
      ],
    },
    {
      icon: squareTwo,
      label: "Level 2",
      description: "Advocate",
      text: "Advocates in the Lobby3 DAO have the ability to contribute to the direction and voice of this community. Benefits include:",
      price: "1 ETH ea.",
      perks: [
        [
          "Exclusive Leadership Access",
          "Advocates will be invited to monthly virtual (and when possible, in-person) meetings with special guests, partner organizations, and Web3 leaders to ask questions and help shape our community’s policy priorities for the movement",
        ],
        [
          "VIP Event Access",
          "Advocate token holders will have free access to exclusive in-person events the Lobby3 DAO will host, including an invitation to the first Andrew Yang Web3 VIP event at Bitcoin 2022 in April 2022",
        ],
        [
          "Exclusive Merch & NFTs",
          "All Advocates will receive an exclusive pin upon purchase (provided you are willing to provide us a mailing address), in addition to future AirDrops",
        ],
        [
          "Website Recognition",
          "Advocate token holders will have their names (or aliases) on the Lobby3 website as founding members",
        ],
        ["All Level 1 Perks"],
      ],
    },
    {
      icon: squareThree,
      label: "Level 3",
      description: "Founder",
      text: "These are founding members of Lobby3, joining an exclusive group of invite-only leaders looking to put major resources to work for high-impact lobbying efforts. Click below to request an invitation. Benefits include:",
      price: "40 ETH ea.",
      href: "https://forms.gle/TffE9WY5sTnXhTs96",
      perks: [
        [
          "One-on-one access to Andrew Yang and the Lobby3 Team",
          "Founders will become part of a group of 100 leaders in the Web3 space, interacting directly with Andrew Yang and the Lobby3 team",
        ],
        [
          "Andrew Yang Event Attendance / Participation",
          "The group will vote to bring Andrew Yang as a keynote speaker and/or attendee to five events of their choice per year (subject to scheduling and minimal exceptions). These can include casual meals, small roundtables, or formal keynote speeches",
        ],
        [
          "VIP Intimate Access Group Meetings",
          "Off-the-record group meetings with policy leaders, to get involved in shaping advocacy efforts to decentralize economic opportunity",
        ],
        [
          "Exclusive Network",
          "Join a network of 100 other Web3 influencers and players in the space with an aligned mission of building a positive, decentralized economic future for all",
        ],
        ["All Level 1 & 2 Perks"],
      ],
    },
  ];

  return (
    <Fragment>
      <Wrapper style={styles.backgroundWhite}>
        <div className={css(styles.container)} id={"membership"}>
          <>
            <Text type={"h1"} label={"Membership"} style={styles.title} />
            <Text
              style={styles.subtitle}
              label={
                "Lobby3 token holders receive membership to this one-of-a-kind community with the following special privileges:"
              }
            />
          </>
        </div>
      </Wrapper>
      <div className={css(styles.tiers)}>
        {levels.map((level, idx) => {
          return (
            <MembershipLevel
              key={`membership-level-${idx}`}
              level={level}
              index={idx}
              href={level.href}
              to={
                OPENING_DATE < new Date().getTime()
                  ? !level.href && "/mint/membership"
                  : ""
              }
              onClick={() => {
                if (!level.href && OPENING_DATE > new Date().getTime()) {
                  setIsOpen(true);
                }
              }}
              isMobileDimension={isMobileDimension}
            />
          );
        })}
      </div>
      <ComingSoonModal
        isOpen={isOpen}
        closeModal={() => {
          setIsOpen(false);
        }}
      />
    </Fragment>
  );
};

const styles = StyleSheet.create({
  container: {
    background: "#fff",
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    padding: "80px 160px 0 0",
    maxWidth: 1750,
    "@media only screen and (max-width: 767px)": {
      padding: 40,
      paddingLeft: 0,
    },
  },

  tiers: {
    overflow: "scroll",
    whiteSpace: "nowrap",
    maxWidth: 1750,
    margin: "0 auto",
    background: "#fff",
    padding: "0px 40px 30px 40px",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "flex-start",

    "@media only screen and (max-width: 767px)": {
      // width: "100%",
    },
  },

  title: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    whiteSpace: "pre-wrap",
    padding: "20px 0",
    paddingLeft: 160,
    "@media only screen and (max-width: 767px)": {
      paddingLeft: 40,
    },
  },
  subtitle: {
    marginBottom: 20,
    paddingLeft: 160,
    "@media only screen and (max-width: 767px)": {
      paddingLeft: 40,
    },
  },
  backgroundWhite: {
    background: "#fff",
  },
});

export default Membership;
