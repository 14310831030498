import { StyleSheet, css } from "aphrodite";
import { Colors } from "../utils/colors";
import { push as Menu } from "react-burger-menu";
import { useState } from "react";

// Component
import Text from "./Text";
import Link from "./Link";

const BurgerMenuStyles = {
  bmBurgerButton: {
    position: "absolute",
    width: 30,
    height: 25,
    right: 10,
    top: 10,
  },
  bmBurgerBars: {
    background: Colors.charcoal(),
  },
  bmBurgerBarsHover: {
    background: "#a90000",
  },
  bmCrossButton: {
    height: 30,
    width: 30,
  },
  bmCross: {
    background: "#bdc3c7",
  },
  bmMenuWrap: {
    position: "fixed",
    height: "unset",
  },
  bmMenu: {
    background: Colors.charcoal(),
    padding: "2.5em 1.5em 0",
    fontSize: "1.15em",
  },
  bmMorphShape: {
    fill: Colors.charcoal(),
  },
  bmItemList: {
    color: "#b8b7ad",
    padding: "0.8em",
    height: "unset",
  },
  bmItem: {
    display: "inline-block",
    margin: "25px 0",
  },
  bmOverlay: {
    background: "rgba(0, 0, 0, 0.3)",
    width: "100vw",
    height: "100vh",
  },
};

// const NavbarTab = ({ item }) => {
//   const href = item.label.split(" ").join("-");

//   return (
//     <a className={css(styles.tab) + " bm-item"} href={`#${href}`}>
//       <Text style={styles.label}>{item.label}</Text>
//     </a>
//   );
// };

const NavbarTab = ({ item }) => {
  const href = item.label.split(" ").join("-");

  // if (item.href) {
  //   return (
  //     <Link className={css(styles.tab) + " bm-item"} to={item.href}>
  //       <Text style={styles.label}>{item.label}</Text>
  //     </Link>
  //   );
  // }

  return (
    <a
      className={css(styles.tab) + " bm-item"}
      href={item.href ? item.href : `/#${href}`}
      target={item.target ? "_blank" : null}
      rel="noreferrer"
    >
      <Text style={styles.label}>{item.label}</Text>
    </a>
  );
};

const NavMenuMobile = () => {
  const menuItems = [
    {
      label: "how it works",
    },
    {
      label: "membership",
    },
    {
      label: "roadmap",
    },
    {
      label: "faq",
    },
    {
      label: "team",
    },
    {
      label: "our story",
      href: "/ourstory",
    },
    {
      label: "discord",
      href: "https://discord.com/invite/AUfTNJqeTN",
      target: true,
    },
  ];

  return (
    <Menu styles={BurgerMenuStyles} right={true}>
      {menuItems.map((item, idx) => {
        return <NavbarTab key={`navbar-item-${idx}`} item={item} />;
      })}
    </Menu>
  );
};

const styles = StyleSheet.create({
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    width: "100%",
    color: Colors.gray(),
  },
  rowContainer: {
    flexDirection: "row",
  },
  tab: {
    height: 25,
    marginBottom: 10,
    textTransform: "uppercase",
    textDecoration: "unset",
    color: Colors.gray(),
  },
  horizontalTab: {
    padding: "10px 15px",
  },
  label: {
    fontFamily: "GT Flexa Trial",
    cursor: "pointer",
    transition: "all ease-in-out 0.2s",
    paddingBottom: 20,
    ":hover": {
      color: Colors.purple(),
      textDecoration: "underline",
    },
  },
});

export default NavMenuMobile;
