// Npm
import { StyleSheet, css } from "aphrodite";
import { useRef, useState, useEffect } from "react";
import { Colors } from "../utils/colors";


const Dropdown = ({ showDropdown, children }) => {
  const dropdownRef = useRef();
  const shadowTopRef = useRef();
  const shadowBottomRef = useRef();

  const [isTop, setIsTop] = useState(false);
  const [isBottom, setIsBottom] = useState(false);

  function onScroll(e) {
    const { scrollHeight, scrollTop, clientHeight } = e.target;

    const _isTop = scrollTop === 0;
    const _isBottom = scrollHeight - scrollTop === clientHeight;

    setIsTop(_isTop);
    setIsBottom(_isBottom);
  }

  return (
    <div 
      className={css(styles.dropdown, showDropdown ? styles.show : styles.hide)} 
      ref={dropdownRef}
      onScroll={onScroll}
    >
      <div 
        className={css(styles.shadowTop, isTop && styles.showShadowTop)}
        ref={shadowTopRef}
      />
      {children}
      <div 
        className={css(styles.shadowBottom, isBottom && styles.showShadowBottom)}
        ref={shadowBottomRef}
      />
    </div>
  );
};

const styles = StyleSheet.create({

  dropdown: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    justifyContent: "flex-start",
    alignItems: "center",
    transition: "all .25s ease",
    position: "relative"
  },
  show: {
    visibility: "visible",
    opacity: 1,
    height: "unset",
    zIndex: "auto",
    maxHeight: 500,
    overflowY: "scroll",
    boxSizing: "border-box",
    paddingRight: 20
  },
  hide: {
    visibility: "hidden",
    opacity: 0,
    height: 0,
    overflow: "hidden",
    zIndex: -1
  },
  shadowTop: {
    height: 20,
    position: "sticky",
    top: 0,
    boxShadow: "none",
    transition: "box-shadow 0.3s",
  },
  showShadowTop: {
    boxShadow: "inset 0 8px 5px -5px rgb(0 0 0 / 0.4)",
  },
  shadowBottom: {
    height: 20,
    position: "sticky",
    bottom: 0,
    boxShadow: "none",
    transition: "box-shadow 0.3s",
  },
  showShadowBottom: {
    boxShadow: "inset 0 8px -5px 5px rgb(0 0 0 / 0.4)",
    
  },
});

export default Dropdown;