// Npm
import { useEffect } from "react";
import { StyleSheet, css } from "aphrodite";

// Components
import Text from "../../Text";
import Tooltip from "../../Tooltip";
import Loader from "../../SpinLoader";

// Utils
import { Colors } from "../../../utils/colors";
import checkIcon from "../../../assets/icons/check.svg";

const AvatarCard = ({ userToken, chosenAvatarToken, onClick, isActive }) => {
  const {
    nft_image,
    level,
    created_at,
    updated_at,
    name,
    transaction_hash: txHash,
  } = userToken;

  useEffect(() => {
    // renderStatus();
  });

  function _onClick(e) {
    e && e.preventDefault();
    const nextState = !isActive;
    onClick && onClick(userToken, nextState);
  }

  async function renderStatus() {
    const receipt = await window?.web3?.eth?.getTransactionReceipt(txHash);
    const { status } = receipt;

    switch (status) {
      case true:
        return null;
      case false:
        return null;
      default:
        return (
          <Tooltip>
            {/* <Text label={"Pending"} /> */}
            <Loader />
            {/* <EthLoader /> */}
          </Tooltip>
        );
    }
  }

  return (
    <div
      className={css(styles.card, isActive && styles.activeCard)}
      onClick={_onClick}
    >
      <div className={css(styles.imageContainer) + " shiny-thumbnail"}>
        <div className={css(styles.statusContainer)}></div>
        <img
          className={css(styles.image)}
          alt={`userToken: ${name}`}
          src={nft_image}
        />
      </div>
      <div
        className={
          css(
            styles.checkmarkContainer,
            isActive && styles.activeCheckmarkContainer
          ) + " checkmark-container"
        }
      >
        {isActive && <img styles={css(styles.checkmark)} src={checkIcon} />}
      </div>
      <div
        className={
          css(styles.description, isActive && styles.activeDescription) +
          " description"
        }
      >
        <div className={css(styles.metadata)}>
          <Text style={styles.label}>{"Name:"}</Text>
          <Text style={styles.value} clamp={true} label={name} />
        </div>
      </div>
    </div>
  );
};

const styles = StyleSheet.create({
  card: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    boxSizing: "border-box",
    border: `1px solid ${Colors.gray()}`,
    cursor: "pointer",
    userSelect: "none",
    overflow: "hidden",
    borderRadius: 3,
    ":hover": {
      borderColor: Colors.lime(),
      boxShadow: `0 0 10px ${Colors.lime(0.7)}`,
    },
    ":hover .description": {
      backgroundColor: Colors.lime(),
    },
    ":hover .checkmark-container": {
      display: "flex",
    },
  },
  activeCard: {
    borderColor: Colors.lime(),
    boxShadow: `0 0 10px ${Colors.lime(0.7)}`,
  },
  imageContainer: {
    height: 220,
    minHeight: 220,
    maxHeight: 220,
    width: "100%",
    position: "relative",
    overflow: "hidden",
    "@media only screen and (max-width: 767px)": {
      height: "auto",
      minHeight: "auto",
      maxHeight: "auto",
    },
  },
  image: {
    height: 220,
    minHeight: 220,
    maxHeight: 220,
    width: "100%",
    minWidth: 200,
    objectFit: "cover",
    background: Colors.gray(),
    "@media only screen and (max-width: 767px)": {
      height: "auto",
      minHeight: "auto",
      maxHeight: "auto",
      objectFit: "fill",
    },
  },
  description: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
    width: "100%",
    minWidth: 200,
    boxSizing: "border-box",
    padding: 15,
    background: "#FFF",
    height: "auto",
    minHeight: "auto",
    "@media only screen and (max-width: 767px)": {
      height: "auto",
      minHeight: "unset",
    },
  },
  activeDescription: {
    backgroundColor: Colors.lime(),
  },
  metadata: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
  },
  label: {
    fontFamily: "Poppins",
    fontWeight: "100%",
    color: Colors.charcoal(),
    fontSize: 12,
  },
  value: {
    fontFamily: "GT Flexa Trial",
    fontWeight: "bold",
    letterSpacing: 1,
    color: Colors.charcoal(0.8),
    fontSize: 13,
  },
  statusContainer: {
    position: "absolute",
    top: 10,
    left: 10,
    width: 30,
    height: 30,
  },
  checkmarkContainer: {
    position: "absolute",
    display: "none", // flips to flex onHover by parent
    justifyContent: "center",
    alignItems: "center",
    top: 10,
    right: 10,
    height: 20,
    minHeight: 20,
    maxHeight: 20,
    width: 20,
    minWidth: 20,
    maxWidth: 20,
    borderRadius: "100%",
    backgroundColor: Colors.lime(),
    boxShadow: "0 0 10px rgba(0, 0, 0, 0.3)",
    zIndex: 0,
  },
  activeCheckmarkContainer: {
    display: "flex",
  },
});

export default AvatarCard;
