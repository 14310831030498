import { css, StyleSheet } from "aphrodite"
import { useEffect, useRef } from "react";
import { Colors } from "../utils/colors";

import Text from "./Text";
import arrowUp from "../assets/arrow-up.png";


const Input = ({
  id = null,
  required = false,
  value,
  type = "text", 
  label, 
  placeholder, 
  containerStyle,
  labelStyle, 
  inputStyle,
  onChange,
  max,
}) => {

  const numInput = useRef();

  useEffect(() => {
    if (numInput?.current) {
      numInput.current.onchange = function(e) {
        // reset error state on new input
        e.target.setCustomValidity('')
      };

      numInput.current.oninvalid = function(e) {
        if (!e.target.validity.valid) {
          const errorType = e.target.validity;
          if (errorType.rangeOverflow) {
            return e.target.setCustomValidity("Please mint only a max of 10 at a time. \nThis protects you from losing gas fees.");
          }
          if (errorType.rangeUnderflow) {
            return e.target.setCustomValidity("Please mint at least 1 token!");
          }
        }
      }


    }
  })

  function _onChange(e) {
    if (type === "number") {
      e.target.value = e.target.value.replace(/[^0-9]*/g,'');
    }
    onChange(e);
  }

  function _handleUpClick(e) {
    e && e.stopPropagation();
    _resetErrorState();
    if (value === undefined || value === null) return;
    if (value === max) return;
    const _value = Number(value) + 1;
    const synthetic = {
      target: { value: _value.toString() }
    };
    _onChange(synthetic);
  }

  function _handleDownClick(e) {
    e && e.stopPropagation();
    _resetErrorState();
    if (value === undefined || value === null) return;
    if (value === 0) return;
    const _value = Number(value) - 1;
    const synthetic = {
      target: { value: _value.toString() }
    };
    _onChange(synthetic);
  }

  function _resetErrorState() {
    try{numInput?.current.setCustomValidity('')}catch(e){}
  }

  const labelProps = {
    type: "h4",
    label,
    style: [styles.label, labelStyle && labelStyle]
  };

  const inputProps = {
    id,
    className: css(styles.input, inputStyle && inputStyle),
    value,
    placeholder,
    type, 
    required,
    onChange: _onChange
  };

  if (type === "number") {
    inputProps.min = "1";
    inputProps.step="1"
    inputProps.onKeyDown= (e) => e.key === "." && e.preventDefault();
    if (max) inputProps.max = max;
    inputProps.className = css(styles.input, styles.numberInput)
  }

  const arrowUpProps = {
    src: arrowUp,
    className: css(styles.arrowUp, value >= max && styles.disabled),
    alt: "arrow-up",
    draggable: false,
    onClick: _handleUpClick,
    required: required,
  }

  const arrowDownProps = {
    src: arrowUp,
    className: css(styles.arrowDown, value <= 0 && styles.disabled),
    alt: "arrow-down",
    draggable: false,
    onClick: _handleDownClick,
    required: required
  }


  return (
    <div className={css(styles.container, containerStyle)}>
      <Text {...labelProps} />

      {type === "number" ? (
        <div className={css(styles.numberInputContainer)}>
          <input ref={numInput} {...inputProps} />
          <div className={css(styles.arrowContainer)}>
            <img {...arrowUpProps} /> 
            <img {...arrowDownProps} /> 
          </div>
        </div>
      ) : (
        <input {...inputProps} />
      )} 
    </div>
  )
};

const styles = StyleSheet.create({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    width: "100%"
  },
  label: {
    textTransform: "uppercase",
    fontFamily: "GT Flexa Trial",
    marginBottom: 10,
    color: Colors.charcoal(),
    fontWeight: "bold",
    width: "100%"
  },
  input: {
    textTransform: "uppercase",
    fontFamily: "GT Flexa Trial",
    padding: 10,
    color: Colors.charcoal(),
    width: "100%",
    fontSize: 22
  },

  numberInputContainer: {
    display: "flex",
    width: "100%",
    alignItems: "center",
    position: "relative"
  },
  numberInput: {
    width: "100%",
    height: 45,
  },
  // ARROW STYLES

  arrowContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    position: "absolute",
    right: 10,
    // top: 0
    // width: "100%"
    // height: 
  },
  arrowUp: {
    width: 25,
    cursor: "pointer",
    marginBottom: 3,
    userSelect: "none",
    ":hover": {
      filter: "contrast(20)",
      opacity: 0.6
    }
  },
  arrowDown: {
    width: 25,
    transform: 'rotate(180deg)',
    marginTop: 3,
    userSelect: "none",
    cursor: "pointer",
    ":hover": {
      filter: "contrast(20)",
      opacity: 0.6

    }
  },
  disabled: {
    pointerEvents: "none",
    opacity: 0.3
  }
});

export default Input;