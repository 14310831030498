// Npm
import { useEffect, useState, forwardRef } from "react";
import { Group, Image as KonvaImage } from "react-konva";
import { Colors } from "../utils/colors";
import useImage from "use-image";
import Konva from "konva";

const Avatar = forwardRef(
  (
    {
      avatar,
      numOfAvatars,
      showTooltip,
      hideTooltip,
      handleZIndex,
      returnZIndex,
      onLastAvatarDrawn,
    },
    avatarRef
  ) => {
    const [image] = useImage(avatar.image);
    const [hover, setHover] = useState(false);
    const [nftImage, setNftImage] = useState(null);

    useEffect(() => {
      if (avatar.id === numOfAvatars - 1) {
        onLastAvatarDrawn();
      }
      avatarRef?.current?.to({
        opacity: 1,
        easing: Konva.Easings.EaseIn,
        duration: 0.5,
      });

    }, []);

    const handleMouseEnter = (e) => {
      handleZIndex(avatar.id);
      setHover(true);

      if (!nftImage) {
        const _nftImage = new window.Image();
        _nftImage.src = avatar.nftImage;
        _nftImage.onload = () => {
          setNftImage(_nftImage);
          showTooltip(e, avatar.tooltip, _nftImage);
        };
      } else {
        showTooltip(e, avatar.tooltip, nftImage);
      }
    };

    const handleOnMouseMove = (e) => {};

    const handleOnMouseOut = (e) => {
      hideTooltip();
      returnZIndex();
      setHover(false);
    };

    if (!avatar.image || !avatar.nftImage) return null;

    return (
      <>
        {/* AVATAR */}
        <Group>
          {/* <Rect
        fill={Konva.Util.getRandomColor()}
        x={avatar.x + 20}
        y={avatar.y + 27}
        width={25}
        height={25}
        // rotate={100}
        rotation={56}
        skewY={-0.23}
      /> */}
          <KonvaImage
            ref={avatarRef}
            image={image}
            key={avatar.id}
            id={`mural_avatar_${avatar.id}`}
            x={avatar.x}
            y={avatar.y}
            height={avatar.height}
            width={avatar.width}
            opacity={0}
            draggable={false}
            rotation={avatar.rotation}
            shadowColor={
              avatar.isUserOwnAvatar ? Colors.lime() : Colors.lightCharcoal(0.5)
            }
            shadowBlur={avatar.isUserOwnAvatar ? 20 : 8}
            shadowOpacity={1}
            shadowOffsetX={0}
            shadowOffsetY={0}
            scaleX={hover ? 1.3 : 1}
            scaleY={hover ? 1.3 : 1}
            onMouseEnter={handleMouseEnter}
            onMouseMove={handleOnMouseMove}
            onMouseOut={handleOnMouseOut}
          />
        </Group>
      </>
    );
  }
);

export default Avatar;
