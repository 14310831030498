import { StyleSheet, css } from "aphrodite";
import React, { useState, useEffect } from "react";
import { Colors } from "../../utils/colors";

// Components
import OptionCard from "./OptionCard";
import Text from "../Text";
import EthLoader from "../EthLoader";
import Select from "../Select";
import ColorSelector from "../ColorSelector";

import {
  AVATAR_SHIRTS,
  AVATAR_PANTS,
  AVATAR_HANDS,
  AVATAR_FACES,
} from "../../utils/constants";
import colorWheel from "../../assets/icons/color-wheel.svg";
import Button from "../Button";

const Options = ({
  selector,
  selector: { label, type },
  shirt,
  pants,
  hands,
  faces,
  orientation,
  setShirt,
  setPants,
  setHands,
  setFaces,
  userNfts,
  color,
  setColor,
  isFetchingUserNfts,
  pageKey,
  _getUserNfts,
  totalNFTCount,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState([]);

  // DROPDOWN FILTER FOR NFT IMAGES
  const [filterOptions, setFilterOptions] = useState(["all"]);
  const [activeFilterOption, setActiveFilterOption] = useState("all");

  // COLOR SELECTOR STATE
  const [showColorMenu, setShowColorMenu] = useState(false);

  const isNFTOptions = type === "nfts";

  useEffect(() => {
    // close color menu on switch
    setShowColorMenu(false);
    setOptions(_formatOptions());
  }, [selector]);

  useEffect(() => {
    _formatFilterOptions(userNfts);
  }, [userNfts]);

  function _formatFilterOptions(nfts) {
    const options = nfts.map((nft) => nft.title);
    setFilterOptions(["all", ...options]);
  }

  function _formatOptions() {
    const _options = [];
    switch (type) {
      case "hands":
        _options.push(...AVATAR_HANDS());
        break;
      case "shirts":
        _options.push(...AVATAR_SHIRTS());
        break;
      case "pants":
        _options.push(...AVATAR_PANTS());
        break;
      case "faces":
        _options.push(...AVATAR_FACES());
        break;
      case "nfts":
        _options.push(...userNfts);
        break;
      default:
        return _options;
    }
    return _options;
  }

  function _handleFilterChange(filter) {
    setActiveFilterOption(filter?.value);
  }

  function _handleOptionSelection(option, cubeSurfaceIndex) {
    switch (type) {
      case "hands":
        return setHands(option);
      case "shirts":
        return setShirt(option);
      case "pants":
        return setPants(option);
      case "nfts":
      case "faces":
        return _handleFaceSelection(option, cubeSurfaceIndex);
      default:
        return null;
    }
  }

  function _handleFaceSelection(option, cubeSurfaceIndex) {
    if (cubeSurfaceIndex === null || cubeSurfaceIndex === undefined) {
      cubeSurfaceIndex = 2;
    }

    const _faces = [...faces];
    _faces[cubeSurfaceIndex] =
      _faces[cubeSurfaceIndex] !== option ? option : "";
    setFaces(_faces);
  }

  function _renderHeaderByType() {
    let num = options.length;
    if (type === "nfts") {
      num = totalNFTCount;
    }

    const left = (
      <Text style={styles.title} type={"h4"}>
        {`${label} (${num})`}
      </Text>
    );

    const right = _renderHeaderOption();

    return (
      <div className={css(styles.header)}>
        {left}
        {right}
      </div>
    );
  }

  function _renderHeaderOption() {
    switch (type) {
      case "faces":
        return (
          <div
            className={css(
              styles.cubeColorSection,
              showColorMenu && styles.active
            )}
            onClick={() => setShowColorMenu(!showColorMenu)}
          >
            <span className={css(styles.cubeText)}>Select Cube Color</span>
            <div
              className={css(
                styles.colorButton,
                showColorMenu && styles.activeButton
              )}
              id={"color-button"}
            >
              <img src={colorWheel} className={css(styles.colorIcon)} />
            </div>
          </div>
        );
      case "nfts":
        return (
          <Select
            values={filterOptions}
            onChange={_handleFilterChange}
            menuStyle={{
              background: Colors.gray(),
              fontSize: 16,
              "@media only screen and (max-width: 767px)": {
                fontSize: 14,
              },
            }}
            controlStyle={{
              minWidth: "unset",
              width: 165,
              background: Colors.gray(),
              cursor: "pointer",
              fontSize: 16,
              "@media only screen and (max-width: 767px)": {
                fontSize: 14,
              },
            }}
          />
        );
      default:
        return null;
    }
  }

  const optionCardProps = {
    type,
    shirt,
    pants,
    hands,
    faces,
    orientation,
    onClick: _handleOptionSelection,
  };

  const optionListProps = {
    options,
    type,
    activeFilterOption,
    optionCardProps,
    isFetchingUserNfts,
    _getUserNfts,
  };

  const emptyNftState = isNFTOptions && options.length === 0;

  return (
    <div className={css(styles.container)}>
      <div className={css(styles.titleContainer)}>{_renderHeaderByType()}</div>
      <div className={css(styles.optionsWrapper)}>
        <div
          className={css(emptyNftState ? styles.flexContainer : styles.options)}
        >
          {showColorMenu && (
            <>
              <ColorSelector
                closeOverlay={() => setShowColorMenu(false)}
                color={color}
                onColorSelect={setColor}
              />
            </>
          )}
          <OptionsList {...optionListProps} pageKey={pageKey} />
        </div>
        {pageKey && (
          <div className={css(styles.buttonWrapper)}>
            <Button
              label={"View More"}
              noArrow={true}
              isLoading={isLoading}
              style={styles.buttonContainer}
              buttonStyles={styles.viewMore}
              onClick={async () => {
                setIsLoading(true);
                await _getUserNfts();
                setIsLoading(false);
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

const OptionsList = ({
  options,
  type,
  activeFilterOption,
  optionCardProps,
  isFetchingUserNfts,
  pageKey,
  _getUserNfts,
}) => {
  if (type === "nfts") {
    if (isFetchingUserNfts) {
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center ",
          }}
        >
          <Text
            type={"h4"}
            label={"Fetching NFTs..."}
            style={styles.emptyText}
          />
          <EthLoader isLoading={true} style={styles.ethIcon} />
        </div>
      );
    }

    if (!isFetchingUserNfts && !options.length) {
      return (
        <Text
          type={"h4"}
          label={"NFTs not found from your wallet."}
          style={styles.emptyText}
        />
      );
    }

    const _nfts = options.filter((nft) => {
      if (activeFilterOption === "all") return nft;
      return nft?.title === activeFilterOption;
    });

    return (
      <>
        {_nfts.map((nft, index) => (
          <OptionCard
            key={`optionCard-nfts-${type}-${index}`}
            option={nft}
            index={index}
            {...optionCardProps}
          />
        ))}
      </>
    );
  }

  return options.map((option, index) => (
    <OptionCard
      key={`optionCard-${type}-${index}`}
      option={option}
      index={index}
      {...optionCardProps}
    />
  ));
};

const styles = StyleSheet.create({
  flexContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    background: Colors.lightCharcoal(),
    height: 470,
    minHeight: 470,
    maxHeight: 495,
    border: `1px solid ${Colors.medCharcoal()}`,
    margin: "15px 20px",
    boxSizing: "border-box",
    zIndex: 2,
    "@media only screen and (max-width: 767px)": {
      width: "100%",
      padding: 20,
      background: Colors.charcoal(),
    },
  },
  container: {
    padding: "0 20px",
    width: "100%",
    minHeight: 550,
    "@media only screen and (max-width: 1000px)": {
      padding: 0,
    },
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    "@media only screen and (max-width: 767px)": {
      flexDirection: "column",
      alignItems: "flex-start",
    },
  },
  buttonWrapper: {
    paddingRight: 40,
    display: "flex",
  },
  optionsWrapper: {
    overflowY: "scroll",
    maxHeight: 500,
  },
  buttonContainer: {
    marginTop: 24,
    marginLeft: "auto",
  },
  options: {
    position: "relative",
    display: "grid",
    gridGap: 15,
    padding: "15px 20px",
    boxSizing: "border-box",
    gridTemplateColumns: "1fr 1fr 1fr",
    background: Colors.medCharcoal(0.6),
    "@media only screen and (max-width: 1000px)": {
      gridTemplateColumns: "1fr 1fr 1fr",
      padding: "20px 0",
    },
    "@media only screen and (max-width: 767px)": {
      gridTemplateColumns: "1fr 1fr",
    },
  },
  titleContainer: {
    position: "sticky",
    top: 0,
    left: 0,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0px 20px 15px 20px",
    boxSizing: "border-box",
    height: 51,
    minHeight: 51,
    maxHeight: 51,
    zIndex: 2,
    "@media only screen and (max-width: 1000px)": {
      padding: 0,
    },
    "@media only screen and (max-width: 767px)": {
      boxSizing: "content-box",
      height: "auto",
      minHeight: "auto",
      maxHeight: "auto",
      padding: "20px 0 30px",
    },
  },
  title: {
    color: Colors.gray(),
    textTransform: "uppercase",
    letterSpacing: 1,
    fontWeight: "bold",
    "@media only screen and (max-width: 767px)": {
      width: "100%",
      paddingBottom: 10,
    },
  },
  cubeColorSection: {
    marginLeft: "auto",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    color: Colors.gray(),
    ":hover": {
      color: Colors.lime(),
    },
    ":hover #color-button": {
      background: Colors.gray(0.5),
      boxShadow: "0 0 10px rgba(255, 255, 255, 0.7)",
    },
  },
  active: {
    color: Colors.lime(),
  },
  cubeText: {
    fontSize: 16,
    marginRight: 8,
  },
  emptyText: {
    color: Colors.gray(),
  },
  ethIcon: {
    marginTop: 20,
    width: 50,
    animation: "spin 2s infinite, bounce 2s infinite",
  },
  colorButton: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: 28,
    width: 28,
    borderRadius: 100,
    background: Colors.lightCharcoal(),
    cursor: "pointer",

    "@media only screen and (max-width: 767px)": {
      justifyContent: "flex-start",
    },
  },
  colorIcon: {
    height: 30,
  },
  activeButton: {
    background: Colors.gray(0.5),
    borderColor: Colors.lime(),
    boxShadow: "0 0 10px rgba(255, 255, 255, 0.7)",
  },
});

export default Options;
