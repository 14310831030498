// Npm
import { StyleSheet, css } from "aphrodite";
import { Colors } from "../../utils/colors";

// Component
import pixelDesign from "../../assets/pixel-design.png";
import Text from "../Text";
import Wrapper from "../Wrapper";

const About = () => {
  return (
    <Wrapper>
      <div className={css(styles.container)}>
        <div className={css(styles.contentLeft)}>
          <Text type={"h1"} style={styles.title}>
            {"What is Lobby3?"}
          </Text>
          <Text style={styles.body}>
            Lobby3 is a new Web3 community designed to give the people a
            stronger voice in Washington, DC — so we can build a more prosperous
            economic future together.
          </Text>
          <Text style={styles.body}>
            The emergence of Web3 offers a historic opportunity: to decentralize
            economic opportunity, eliminate barriers to financial inclusion,
            combat poverty, and advance the human condition.
          </Text>
          <Text style={styles.body}>
            Unfortunately, ill-informed and poorly-designed policy can prevent
            Web3 from reaching its full potential.
          </Text>
          <Text style={styles.body}>
            That’s why we’re building Lobby3 — a project to fund effective
            policy advocacy and educate lawmakers about the positive potential
            of Web3.
          </Text>
          <Text style={styles.body}>
            By building on the unique voice and influence of Andrew Yang and the
            Yang Gang, Lobby3 will help shape a decentralized economic future
            for all.
          </Text>
        </div>
        <img
          className={css(styles.pixelDesign)}
          src={pixelDesign}
          alt="pixel-design"
          draggable={false}
        />
      </div>
    </Wrapper>
  );
};

const styles = StyleSheet.create({
  container: {
    background: "#fff",
    display: "flex",
    alignItem: "flex-start",
    padding: "80px 160px",
    minHeight: 500,
    width: "100%",
    boxSizing: "border-box",
    position: "relative",
    maxWidth: 1750,
    "@media only screen and (max-width: 767px)": {
      padding: 40,
      flexDirection: "column",
      alignItems: "flex-end",
    },
  },
  contentLeft: {
    // border: '1px solid black'
    zIndex: 2,
  },
  contentRight: {},
  title: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    whiteSpace: "pre-wrap",
    color: Colors.charcoal(),
    padding: "20px 0",
  },
  body: {
    color: Colors.charcoal(),
    padding: "15px 0",
    width: 700,
    "@media only screen and (max-width: 767px)": {
      width: "100%",
    },
  },
  pixelDesign: {
    height: 350,
    paddingLeft: 40,
    marginTop: "auto",
    "@media only screen and (max-width: 767px)": {
      width: 200,
      position: "relative",
      paddingLeft: 0,
    },
  },
});

export default About;
