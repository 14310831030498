// Npm
import { StyleSheet, css } from "aphrodite";
import { useState, useEffect } from "react";
import { Colors } from "../../utils/colors";

// Component
import Text from "../Text";
import Wrapper from "../Wrapper";

import roadMap from "../../assets/roadmap.png";
import numberOne from "../../assets/number-one.png";
import numberTwo from "../../assets/number-two.png";
import numberThree from "../../assets/number-three.png";
import numberFour from "../../assets/number-four.png";
import arrowRightDark from "../../assets/arrow-right-dark.png";

const ROADMAP_POINTS = [
  {
    title: "Building Advocacy Infrastructure",
    text: "Lobby3 will fund issue-based advocacy organizations in DC to build the core advocacy infrastructure. These organizations will bring together lobbyists and stakeholders with relationships with the key committees and players on Capitol Hill.",
    icon: numberOne,
  },
  {
    title: "Educating Policymakers",
    text: "Lobby3’s partners will work to educate key lawmakers in Congress and across the country on the positive potential impacts of Web3. This work will pave the way for smart, well-informed policymaking in years ahead.",
    icon: numberTwo,
  },
  {
    title: "Pilot Programs",
    text: "In key states and districts across the country, our advocacy team will forge partnerships with Governors, Mayors and other local elected officials to run Web3 pilot programs — geared toward demonstrating the capacity of blockchain technology to reduce poverty, lift barriers to financial inclusion, and create greater economic opportunity for marginalized communities.",
    icon: numberThree,
  },
  {
    title: "Lobby3 DAO",
    text: "Once the initial lobby infrastructure is built, a portion of proceeds will be used to fund the Lobby3 DAO. This DAO will vote on policy ideas, community priorities, and where DAO treasury funds should be used to further our mission. For more info on Lobby3 DAO, see our FAQ section.",
    icon: numberFour,
  },
];

const Roadmap = () => {
  return (
    <Wrapper>
      <div className={css(styles.container)} id={"roadmap"}>
        <Text type={"h1"} style={styles.title} label={"Roadmap"} />
        <div className={css(styles.row)}>
          <Text style={styles.body}>
            {
              "Andrew Yang has always been committed to pushing forward big ideas - not just in theory - but in practice. That is why proceeds from Lobby3 will directly fund policy advocacy operations in DC, dedicated to decentralizing economic opportunity and educating policymakers on Web3. Here is the roadmap for our advocacy:"
            }
          </Text>
        </div>
        <div className={css(styles.contentContainer)}>
          <div className={css(styles.content)}>
            {ROADMAP_POINTS.map((point, i) => {
              return (
                <RoadMapPoint key={`roadmap-${i}`} point={point} index={i} />
              );
            })}
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

const RoadMapPoint = ({ point, index }) => {
  const [isOpen, setIsOpen] = useState(true);

  function toggleExpandedView() {
    setIsOpen(!isOpen);
  }
  const { title, text, icon } = point;

  const isEven = (index + 1) % 2 === 0;

  return (
    <div className={css(styles.pointContainer)} onClick={toggleExpandedView}>
      <img
        src={icon}
        draggable={false}
        className={css(styles.numberIcon, isEven && styles.evenNumber)}
      />
      <div className={css(styles.point, isOpen && styles.pointExpanded)}>
        <Text style={styles.pointTitle} label={title} />
        <Text>
          <div className={isOpen ? null : "clamp2"}>{text}</div>
        </Text>
        {!isOpen ? <div className={css(styles.blur)} /> : null}
        <img
          src={arrowRightDark}
          draggable={false}
          className={
            css(styles.arrowIcon, isOpen && styles.arrowOpen) + " arrow"
          }
          draggable={false}
        />
      </div>
    </div>
  );
};

const styles = StyleSheet.create({
  container: {
    background: "#fff",
    padding: "80px 160px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    minHeight: 500,
    width: "100%",
    boxSizing: "border-box",
    position: "relative",
    maxWidth: 1750,
    "@media only screen and (max-width: 767px)": {
      padding: 40,
    },
  },
  row: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
    width: "100%",
    "@media only screen and (max-width: 767px)": {
      flexDirection: "column",
    },
  },
  title: {
    width: "100%",
    color: Colors.charcoal(),
    padding: "20px 0",
  },
  body: {
    color: Colors.charcoal(),
    padding: "15px 0",
    // width: "calc(50% - 10px)",
    "@media only screen and (max-width: 767px)": {
      width: "100%",
    },
  },
  contentContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "30px 0",
    marginTop: 30,
  },
  content: {
    display: "flex",
    flexDirection: "column",
    transition: "all ease-in-out 0.2s",
    backgroundImage: `url(${roadMap})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  },
  pointContainer: {
    display: "flex",
    alignItems: "center",
    margin: "25px 0",
    maxWidth: 800,
  },
  point: {
    marginLeft: 30,
    border: "1px solid black",
    display: "flex",
    flexDirection: "column",
    background: "#fff",
    padding: 25,
    position: "relative",
    cursor: "pointer",
    ":hover .arrow": {
      opacity: 0.7,
    },
  },
  pointExpanded: {
    paddingBottom: 40,
  },
  pointTitle: {
    textTransform: "uppercase",
    fontFamily: "GT Flexa Trial",
    fontWeight: "bold",
    marginBottom: 20,
  },
  blur: {
    background: "linear-gradient(rgba(250, 250, 250, 0) 0%, #FCFCFC 80.38%)",
    height: "100%",
    position: "absolute",
    zIndex: 3,
    bottom: 0,
    width: "95%",
  },
  arrowIcon: {
    position: "absolute",
    bottom: 5,
    right: 10,
    zIndex: 4,
    cursor: "pointer",
    width: 50,
    ":hover": {
      opacity: 0.7,
    },
  },
  arrowOpen: {
    transform: "rotate(180deg)",
  },
  numberIcon: {
    width: 100,
    "@media only screen and (max-width: 767px)": {
      width: 50,
    },
  },
  evenNumber: {
    paddingLeft: 50,
    "@media only screen and (max-width: 767px)": {
      paddingLeft: 0,
    },
  },
});

export default Roadmap;
