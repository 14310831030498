// Npm
import { StyleSheet, css } from "aphrodite";
import { Fragment, useEffect, useState } from "react";
import ReactTooltip from "react-tooltip";

import { Colors } from "../../../utils/colors";
import rotateIcon from "../../../assets/icons/rotate.png";

const AvatarRotationButton = ({ orientation, onClick, style }) => {

  function _formatTooltip() {
    const newOrientation = orientation === "front" ? "back" : "front";
    return `Rotate to ${newOrientation}`;
  }

  return (
    <Fragment>
      <div
        className={css(styles.orientationButton, style && style)}
        onClick={onClick}
        data-for={`avatar-rotation-button`}
        data-tip
        data-iscapture
      >
        <img
          src={rotateIcon}
          className={css(styles.rotateIcon)}
          alt={"rotate-icon"}
          draggable={false}
        />
      </div>
      <ReactTooltip
        id={`avatar-rotation-button`}
        getContent={_formatTooltip}
      />
    </Fragment>
  )
}

const styles = StyleSheet.create({
  orientationButton: {
    position: "absolute",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    top: 30,
    right: 20,
    background: Colors.lime(),
    height: 50,
    width: 50,
    borderRadius: 80,
    cursor: "pointer",
    userSelect: "none",
    transition: "all ease-out 0.1s",
    ":hover": {
      boxShadow: "0 0 10px rgba(255, 255, 255, 0.7)",
    },
  },
  rotateIcon: {
    height: 20,
  },
});

export default AvatarRotationButton;