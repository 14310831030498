import { StyleSheet, css } from "aphrodite";
import React, { useRef, useState, useEffect } from "react";
import { ColorPicker, useColor } from "react-color-palette";
import "react-color-palette/lib/css/styles.css";
import { Colors } from "../utils/colors";

import checkCircle from "../assets/icons/checkcircle-white.svg";
import checkCircleActive from "../assets/icons/checkcircle-lime.svg";

const ColorSelector = ({ color, onColorSelect, closeOverlay }) => {
  const [_color, _setColor] = useColor(color);
  const [activeSurface, setActiveSurface] = useState(null);

  useEffect(() => {
    _setColor(_color);
  }, [color]);

  function _onChange(newColor) {
    if (_color.hex === newColor.hex) return;
    _setColor(newColor);
    onColorSelect && onColorSelect(newColor);
  }

  function _handleSurfaceSelection(index) {
    setActiveSurface(index);
  }

  const buttons = new Array(3).fill(1).map((face, i) => {
    const classNames = [styles.icon];
    if (i === 0) classNames.push(styles.left);
    if (i === 2) classNames.push(styles.right);

    return (
      <img
        key={`color-surface-${i}`}
        className={css([classNames])}
        src={activeSurface === i ? checkCircle : checkCircleActive}
        draggable={false}
        onClick={(e) => {
          e && e.preventDefault();
          e && e.stopPropagation();
          _handleSurfaceSelection(i);
        }}
      />
    );
  });

  return (
    <div className={css(styles.overlay)} onClick={closeOverlay}>
      <div onClick={(e) => e.stopPropagation()}>
        <ColorPicker
          width={456}
          height={228}
          color={color}
          onChange={_onChange}
          hideHSV
          dark
        />
      </div>
    </div>
  );
};

const styles = StyleSheet.create({
  overlay: {
    position: "absolute",
    top: 0,
    left: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: Colors.charcoal(0.8),
    height: "100%",
    width: "100%",
    zIndex: 2,
  },
  row: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: 60,
  },
  icon: {
    height: 50,
    padding: 5,
    cursor: "pointer",
    userSelect: "none",
    ":hover": {
      // color: Colors.lime()
      // boxShadow: '0 0 4px rgba(305, 255, 255, 0.3)',
    },
  },
  left: {
    transform: "rotate(-90deg)",
  },
  right: {
    transform: "rotate(90deg)",
  },
  activeIcon: {
    color: Colors.lime(),
  },
});

export default ColorSelector;
