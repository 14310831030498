import { useEffect, useState } from "react";
import detectEthereumProvider from "@metamask/detect-provider";
import { useParams, useNavigate, Navigate } from "react-router-dom";
import * as Sentry from "@sentry/browser";

import MintHeader from "./MintHeader";
import AvatarCreator from "./AvatarCreator";
import UserSummary from "./UserSummary";
import FooterRow from "./FooterRow";
import NavMenuMobile from "../../NavMenuMobile";
import MintMembership from "./MintMembership";
import SelectMembership from "./SelectMembership";
import MintFlowTimeline from "./MintFlowTimeline";
import AvatarConfirmation from "./AvatarConfirmation";

import {
  getUserTokens,
  getEnsFromAddress,
  alchemyGetNftsByAddress,
} from "../../../config/api";

import { css, StyleSheet } from "aphrodite";
import { MEMBERSHIP_LEVELS, OPENING_DATE } from "../../../utils/constants";
import { Colors } from "../../../utils/colors";
import Congratulations from "./Congratulations";
import { toast } from "react-toastify";

const PAGE_NUMBER_MAP = {
  0: "membership",
  1: "checkout",
  2: "collection",
  3: "avatar",
  4: "confirmation",
  5: "complete",
};

const MintScreen = ({ isMobileDimension }) => {
  const { slug } = useParams();
  const navigate = useNavigate();

  // PAGE STATE
  const [_page, _setPage] = useState();

  // TOKEN SELECTION STATE
  const [userMembershipTier, setUserMembershipTier] = useState(
    MEMBERSHIP_LEVELS[0]
  );

  // WALLET STATE
  const [isFetchingUserEns, setisFetchingUserEns] = useState(false);
  const [userAddress, setUserAddress] = useState(null);
  const [userEns, setUserEns] = useState(null);
  const [userIsConnected, setUserIsConnected] = useState(false);

  // NFT STATE
  const [isFetchingUserNfts, setIsFetchingUserNfts] = useState(true);
  const [userNfts, setUserNfts] = useState([]);
  const [pageKey, setPageKey] = useState(null);
  const [totalNFTCount, setTotalNFTCount] = useState(0);

  // TOKEN / AVATAR LIST STATE
  const [isFetchingUserTokens, setIsFetchingUserTokens] = useState(true);
  const [userTokens, setUserTokens] = useState([]); // tokens / avatars that the user ownes

  // TOKEN / AVATAR CUSTOMIZER
  const [chosenTokensHash, setChosenTokenHash] = useState({});
  const [userAvatar, setUserAvatar] = useState({});

  // NETWORK STATE
  const [networkChainId, setNetworkChainId] = useState(null);
  const [networkErrorMessage, setNetworkErrorMessage] = useState("");

  useEffect(() => {
    if (!_page && slug !== PAGE_NUMBER_MAP[0]) {
      setPage(0);
    }
  }, [_page]);

  useEffect(() => {
    _connectMetamask();
  }, []);

  useEffect(() => {
    _initState();
  }, [userAddress]);

  useEffect(() => {
    _scrollToTop();
    if (_page === 1 || _page === 2) {
      _getUserTokens(userAddress);
    }

    if (_page < 2) {
      setChosenTokenHash({});
    }
  }, [_page]);

  function _initState() {
    if (!userAddress) return _handleDisconnect();
    _getUserNfts();
    _getUserTokens(userAddress);
    _handleConnect();
  }

  function _resetUserWalletState() {
    setUserIsConnected(false);
    setIsFetchingUserTokens(false);
    setisFetchingUserEns(false);
    setIsFetchingUserNfts(false);
    setUserAddress(null);
    setUserEns(null);
    setUserNfts([]);
    setUserTokens([]);
    window.localStorage.clear();
    setNetworkErrorMessage("Please connect wallet");
  }

  function _scrollToTop() {
    // if (_page === 0 || _page === 1) return window.scrollTo(0, 50);
    // window.scrollTo(0, 200);
    window.scrollTo(0, 115);
  }

  async function _getUserTokens(address) {
    !isFetchingUserTokens && setIsFetchingUserTokens(true);
    const _userTokens = await getUserTokens(address);
    setUserTokens(_userTokens);
    setIsFetchingUserTokens(false);
  }

  async function _getUserNfts() {
    if (!pageKey) {
      !isFetchingUserNfts && setIsFetchingUserNfts(true);
    }
    const { userOwnedNfts, pageKeyNext, totalCount } =
      await alchemyGetNftsByAddress(userAddress, pageKey);
    setUserNfts([...userNfts, ...userOwnedNfts]);
    setPageKey(pageKeyNext);
    setTotalNFTCount(totalCount);
    setIsFetchingUserNfts(false);
  }

  async function _connectMetamask() {
    if (!window.ethereum) {
      toast.error("Please install MetaMask.", {
        toastId: "customize-avatar",
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return null;
    }
    const provider = await detectEthereumProvider();

    // we want to choose metamask's provider
    if (window.ethereum !== provider) window.ethereum = provider;
    _addEthEventListeners();

    const isMetamaskUnlocked = await window.ethereum._metamask.isUnlocked();

    if (isMetamaskUnlocked) {
      window.ethereum
        .request({ method: "eth_requestAccounts" })
        .then(_handleAccountsChanged)
        .catch(_handleError);

      window.ethereum
        .request({ method: "eth_chainId" })
        .then(_handleChainChanged)
        .catch(_handleError);
    } else {
      _handleDisconnect();
      toast.error("Please login to Metamask.", {
        toastId: "customize-avatar",
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      await window.ethereum.enable();
    }
  }

  /**
   * Adds EventListeners to detect changes
   * @returns null
   */
  function _addEthEventListeners() {
    window.ethereum.on("connect", _handleConnect);
    window.ethereum.on("disconnect", _handleDisconnect);
    window.ethereum.on("accountsChanged", _handleAccountsChanged);
    window.ethereum.on("chainChanged", _handleChainChanged);
    window.ethereum.on("message", _handleMetamaskMessage);
  }

  async function _getEnsFromAddress(address) {
    !isFetchingUserEns && setisFetchingUserEns(true);
    const _address = address || userAddress;
    const ens = await getEnsFromAddress(_address);
    setisFetchingUserEns(false);
    setUserEns(ens);
  }

  async function _handleConnect() {
    const isMetamaskUnlocked = await window.ethereum._metamask.isUnlocked();
    if (!isMetamaskUnlocked) return _handleDisconnect();
    const isEthConnected = window.ethereum.isConnected();
    setUserIsConnected(isMetamaskUnlocked && isEthConnected);
    setNetworkErrorMessage(null);
  }

  function _handleDisconnect(_error) {
    _resetUserWalletState();
  }

  function _handleAccountsChanged(_accounts) {
    if (!_accounts.length) return _handleDisconnect();

    const account = _accounts[0];
    // no need to refetch data

    if (account === userAddress) return;

    _getEnsFromAddress(account);
    window.localStorage.setItem("eth_address", account);
    setUserAddress(account); // triggers useEffect
    setNetworkErrorMessage(null);
  }

  function _handleChainChanged(_chainId) {
    if (networkChainId === _chainId) return;
    /**   Hex   Dec    Network Name
          0x1	   1	   Ethereum Main Network (Mainnet)
          0x3	   3	   Ropsten Test Network
          0x4	   4	   Rinkeby Test Network
          0x5	   5	   Goerli Test Network
          0x2a	 42	   Kovan Test Network
    */
    setNetworkChainId(_chainId);
  }

  function _handleError(err) {
    let errMsg = "";

    switch (err?.code) {
      case 4001:
        // request rejected by user
        break;
      case -32602:
        // params invalid
        break;
      case -32603:
        // internal error
        break;
      case -32002:
        errMsg = "Please unlock your Metamask Wallet to connect.";
        break;
      default:
        errMsg =
          err?.message || "Hm something went wrong. Please try again later!";
        break;
    }

    setNetworkErrorMessage(errMsg);
  }

  function _handleMetamaskMessage(message) {
    console.log("message", message);
  }

  function confirmMembershipTier(membership) {
    setUserMembershipTier(membership);
    setPage(1);
  }

  function setPage(page) {
    _setPage(page);
    navigate(`/mint/${PAGE_NUMBER_MAP[page]}`);
  }

  function handleAvatarTokenClick(token, shouldAdd) {
    const _chosenTokensHash = { ...chosenTokensHash, [token.id]: shouldAdd };
    setChosenTokenHash(_chosenTokensHash);

    for (let i = 0; i < MEMBERSHIP_LEVELS.length; i++) {
      const curLevel = MEMBERSHIP_LEVELS[i];
      if (curLevel.tier.toLocaleLowerCase() === token.level) {
        setUserMembershipTier(curLevel);
        break;
      }
    }
  }

  // PROPS
  const pageProps = {
    page: _page,
    setPage,
    isMobileDimension,
    slug,
  };
  const walletProps = {
    userIsConnected,
    userEns,
    userAddress,
    isFetchingUserEns,
    networkErrorMessage,
    connectMetamask: _connectMetamask,
  };
  const avatarProps = {
    userAvatar,
    setUserAvatar,
    userNfts,
    _getUserNfts,
    isFetchingUserNfts,
    pageKey,
    totalNFTCount,
  };
  const membershipProps = {
    userTokens,
    userMembershipTier,
    isFetchingUserTokens,
    confirmMembershipTier,
  };
  const tokenProps = {
    userTokens,
    isFetchingUserTokens,
    chosenTokensHash,
    onTokenClick: handleAvatarTokenClick,
  };

  return (
    <div
      className={css(
        styles.container,
        ["collection", "avatar", "confirmation", "complete"].includes(slug) &&
          styles.black
      )}
    >
      {OPENING_DATE > new Date().getTime() && <Navigate to="/" />}
      <MintHeader {...walletProps} />
      <NavMenuMobile />
      <MintFlowTimeline {...pageProps} slug={slug} />
      {slug === "membership" ? (
        <SelectMembership {...pageProps} {...membershipProps} />
      ) : slug === "checkout" ? (
        <MintMembership {...pageProps} {...membershipProps} />
      ) : slug === "collection" ? (
        <UserSummary {...tokenProps} />
      ) : slug === "avatar" ? (
        <AvatarCreator {...avatarProps} userNfts={userNfts} />
      ) : slug === "confirmation" ? (
        <AvatarConfirmation
          {...avatarProps}
          {...pageProps}
          {...membershipProps}
          {...tokenProps}
        />
      ) : slug === "complete" ? (
        <Congratulations
          {...avatarProps}
          {...pageProps}
          {...membershipProps}
          {...tokenProps}
        />
      ) : null}
      {slug !== "membership" && (
        <FooterRow {...pageProps} chosenTokensHash={chosenTokensHash} />
      )}
    </div>
  );
};

const styles = StyleSheet.create({
  container: {
    transition: "all ease-in-out 0.2s",
    minHeight: "calc(100vh - 50px - 122px)",
  },
  black: {
    background: Colors.charcoal(),
  },
});

export default MintScreen;
