import { useState, useRef, useCallback } from "react";
import { css, StyleSheet } from "aphrodite";
import ProgressBar from "@ramonak/react-progress-bar";

// Components
import Text from "../../Text";
import EthLoader from "../../EthLoader";
import Wrapper from "../../Wrapper";
import AvatarGenerator from "../../avatar/AvatarGenerator";
import AvatarSummary from "./AvatarSummary";
import { Colors } from "../../../utils/colors";
import CascadingImage from "../../CascadingImage";
import { checkNFTExists } from "../../../config/api";
import { useInterval } from "../../../config/hooks";

const POLLING_INTERVAL = 3000;

const Congratulations = ({
  setPage,
  userAvatar,
  chosenTokensHash,
  userMembershipTier: {
    assets: { background },
  },
}) => {
  const backAvatarRef = useRef();

  const [nft, setNFT] = useState(null);
  const [progress, setProgress] = useState(0);
  const [isFetchingNFT, setIsFetchingNFT] = useState(true);
  const _checkNFTExists = useCallback(checkNFTExists, [nft]);

  useInterval(
    () => {
      const profileId = Object.keys(chosenTokensHash)[0];

      if (!nft) {
        _checkNFTExists(profileId).then((res) => {
          if (res.exists) {
            const BASE_URL =
              process.env.REACT_APP_ENV === "production"
                ? "https://api.lobby3.io"
                : process.env.REACT_APP_ENV === "staging"
                ? "https://staging-api.lobby3.io"
                : "http://localhost:8000";

            setNFT(BASE_URL + `/profile/${res.tokenId}/image/`);
            setIsFetchingNFT(false);
          }
        });
      }
    },
    !nft ? POLLING_INTERVAL : null
  );

  useInterval(
    () => {
      const progression =
        progress > 85
          ? progress + 0.05
          : progress < 20
          ? progress + 1
          : progress + 0.15;
      setProgress(progression);
    },
    progress >= 100 ? null : 100
  );

  const avatarContainerBackgroundStyle = {
    backgroundImage: `url(${background})`,
    backgroundColor: Colors.gray(),
  };

  return (
    <Wrapper background={Colors.charcoal()}>
      <div className={css(styles.container)}>
        <div className={css(styles.titleContainer)}>
          <Text type={"h2"} style={styles.title} label={"Congratulations!"} />
          <Text
            style={styles.subtitle}
            label={
              <>
                Thank you for joining Lobby3! We are excited to bring the voices
                of Web3 to Capitol Hill with you!
                <br />
                <br />
                Here are some helpful links as we move forward:
                <br />
                1. Join our (token-gated){" "}
                <a
                  href="https://discord.com/invite/AUfTNJqeTN"
                  target="_blank"
                  rel="noreferrer"
                  style={{ color: "#fff" }}
                >
                  Discord group
                </a>{" "}
                to start participating with other members.
                <br />
                2. Check out your avatar in our mural on the{" "}
                <a
                  href={"/"}
                  target={"_blank"}
                  rel="noreferrer"
                  style={{ color: "#fff" }}
                >
                  homepage.
                </a>
                <br />
                3. If you need to make changes to your avatar,{" "}
                <a
                  href={"/mint/membership"}
                  target={"_blank"}
                  rel="noreferrer"
                  style={{ color: "#fff" }}
                >
                  click here.
                </a>
                <br />
                <br />
                Thanks for joining our fight!
                <br />
                <br />
                <italic style={{ fontStyle: "italic" }}>The Lobby3 Team</italic>
              </>
            }
          />
        </div>
        <div className={css(styles.content)}>
          <div className={css(styles.left)}>
            <Text type={"h3"} style={styles.label} label={"NFT"} />
            <div
              className={css(styles.avatarContainer, nft && styles.noPadding)}
              style={avatarContainerBackgroundStyle}
            >
              {isFetchingNFT ? (
                <>
                  <CascadingImage src={background} />
                  <div className={css(styles.backAvatarBehind)}>
                    <AvatarGenerator
                      key={"avatar-confirmation-back"}
                      {...userAvatar}
                      ref={backAvatarRef}
                      orientation={"back"}
                      staticHead={true} //important for screenshot
                      showFloor={false}
                    />
                  </div>
                  <div
                    className={css(styles.overlay)}
                    style={{ backgroundImage: `url(${background})` }}
                  >
                    <Text type={"h3"} label={"Generating Token..."} />
                    <ProgressBar
                      completed={progress}
                      customLabel={""}
                      isLabelVisible={false}
                      bgColor={Colors.ethYellow()}
                      margin={10}
                      maxCompleted={100}
                      width={250}
                    />
                    <EthLoader isLoading={true} style={styles.ethLoader} />
                  </div>
                </>
              ) : (
                <img src={nft} className={css(styles.nft)} />
              )}
            </div>
          </div>
          <div className={css(styles.right)}>
            <Text type={"h3"} style={styles.label} label={"Attributes"} />
            <AvatarSummary userAvatar={userAvatar} />
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

const styles = StyleSheet.create({
  inputContainer: {
    width: "100%",
    marginBottom: 30,
  },
  container: {
    padding: "25px 50px",
    width: "100%",
    boxSizing: "border-box",
    "@media only screen and (max-width: 767px)": {
      padding: 20,
    },
  },
  titleContainer: {
    width: "100%",
    maxWidth: 1200,
    margin: "0 auto",
  },
  title: {
    color: Colors.gray(),
    paddingBottom: 20,
  },
  subtitle: {
    whiteSpace: "pre-wrap",
    color: Colors.gray(),
    paddingBottom: 30,
  },
  label: {
    textTransform: "uppercase",
    color: Colors.gray(),
    fontSize: 28,
    marginBottom: 15,
  },
  input: {
    width: "100%",
    color: "#000",
    boxSizing: "border-box",
    background: Colors.gray(),
  },
  content: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
    background: Colors.medCharcoal(),
    width: "100%",
    margin: "0 auto",
    padding: 30,
    maxWidth: 1200,
    boxSizing: "border-box",
    borderRadius: 5,
    border: "1px solid #FAFAFA",
    position: "relative",
    "@media only screen and (max-width: 1000px)": {
      flexDirection: "column",
      alignItems: "center",
    },
    "@media only screen and (max-width: 767px)": {
      padding: "10px 0",
      border: "none",
      background: Colors.charcoal(),
    },
  },
  left: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    width: "45%",
    "@media only screen and (max-width: 1000px)": {
      width: "100%",
      boxSizing: "border-box",
    },
  },
  right: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    width: "50%",
    "@media only screen and (max-width: 1000px)": {
      width: "100%",
      marginTop: 30,
    },
  },
  avatarContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
    width: "100%",
    height: "100%",
    padding: "80px 50px 20px 50px",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    border: `1px solid ${Colors.gray()}`,
    boxSizing: "border-box",
    overflow: "hidden",
  },
  noPadding: {
    padding: 0,
  },
  nft: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },
  frontAvatar: {
    zIndex: 1,
    marginRight: -200, //offset
  },
  frontAvatarBehind: {
    zIndex: -1,
    marginRight: 0,
  },
  backAvatar: {
    zIndex: 1,
    marginLeft: -200,
  },
  backAvatarBehind: {
    zIndex: -1,
    marginLeft: 0,
  },
  overlay: {
    position: "absolute",
    top: 0,
    left: 0,
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: Colors.gray(),
    pointerEvents: "none",
    zIndex: 5,
  },
  editButton: {
    position: "absolute",
    top: 15,
    right: 15,
    padding: "10px 30px",
    color: Colors.charcoal(),
    background: Colors.lime(),
    borderRadius: 3,
    cursor: "pointer",
    fontFamily: "Poppins",
    fontSize: 18,
    boxShadow: `0 0 10px ${Colors.lightCharcoal(0.5)}`,
    ":hover": {},
  },
  rotateButton: {
    top: 15,
    right: "usnet",
    left: 15,
    boxShadow: `0 0 10px ${Colors.lightCharcoal(0.5)}`,
  },
  avatarSquare: {
    position: "absolute",
    bottom: 80,
    height: 140,
  },
  button: {
    marginTop: 40,
    width: "100%",
    padding: "15px 0",
    height: 70,
  },
  ethLoader: {
    width: 30,
    marginTop: 20,
  },
});

export default Congratulations;
