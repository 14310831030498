import { StyleSheet, css } from "aphrodite";
import { Colors } from "../utils/colors";
import { Link } from "react-router-dom";

// Component
import Text from "./Text";

const NavbarTab = ({ item, onClick, horizontalAlignment }) => {
  const href = item.label.split(" ").join("-");

  if (item.href && !item.target) {
    return (
      <Link
        className={css(styles.tab, horizontalAlignment && styles.horizontalTab)}
        to={item.href}
      >
        <Text style={styles.label}>{item.label}</Text>
      </Link>
    );
  }

  return (
    <a
      className={css(styles.tab, horizontalAlignment && styles.horizontalTab)}
      href={item.href ? item.href : `/#${href}`}
      target={item.target ? "_blank" : null}
      rel="noreferrer"
    >
      <Text style={styles.label}>{item.label}</Text>
    </a>
  );
};

const NavMenu = ({ horizontalAlignment = false }) => {
  const menuItems = [
    {
      label: "how it works",
    },
    {
      label: "membership",
    },
    {
      label: "roadmap",
    },
    {
      label: "faq",
    },
    {
      label: "team",
    },
    {
      label: "our story",
      href: "/ourstory",
    },
    {
      label: "discord",
      href: "https://discord.com/invite/AUfTNJqeTN",
      target: true,
    },
  ];

  function handleOnClick() {
    //todo: anchor tag to scroll user to section
  }

  return (
    <div
      className={css(
        styles.container,
        horizontalAlignment && styles.rowContainer
      )}
    >
      {menuItems.map((item, idx) => {
        return (
          <NavbarTab
            key={`navbar-item-${idx}`}
            item={item}
            onClick={handleOnClick}
            horizontalAlignment={horizontalAlignment}
          />
        );
      })}
    </div>
  );
};

const styles = StyleSheet.create({
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    width: "100%",
    color: Colors.gray(),

    "@media only screen and (min-width: 1200px)": {
      display: "grid",
      margin: "16px auto",
      "grid-template-columns": "auto auto",
      "grid-row": "auto auto",
      "grid-column-gap": 32,
      // grid-row-gap: 20px;
    },
  },
  rowContainer: {
    flexDirection: "row",
    flexWrap: "wrap",

    "@media only screen and (min-width: 1200px)": {
      display: "flex",
      margin: 0,
    },
  },
  tab: {
    height: 25,
    marginBottom: 10,
    textTransform: "uppercase",
    textDecoration: "unset",
    color: Colors.gray(),

    "@media only screen and (max-width: 1023px)": {
      height: "unset",
    },
  },
  horizontalTab: {
    padding: "10px 15px",
  },
  label: {
    fontFamily: "GT Flexa Trial",
    cursor: "pointer",
    transition: "all ease-in-out 0.2s",
    ":hover": {
      color: Colors.purple(),
      textDecoration: "underline",
    },
  },
});

export default NavMenu;
