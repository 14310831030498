import { Link as RouterLink } from "react-router-dom";
import { StyleSheet, css } from "aphrodite";


const Link = ({ to, style, children }) => {

  return (
    <RouterLink className={css(styles.link, style && style)} to={to}>
      {children}
    </RouterLink>
  )
};


const styles = StyleSheet.create({
  link: {
    textDecoration: "unset",
    // textTransformation: "unset",
    color: "unset",
    cursor: "pointer",
    ":hover": {
      // do something
    }
  }
});

export default Link;