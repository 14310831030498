// Npm
import { StyleSheet, css } from "aphrodite";
import { useNavigate } from "react-router";

// Components
import teamOne from "../assets/team/team-one.png";

const OurStoryScreen = ({ isMobileDimension }) => {
  const navigate = useNavigate();

  const html = `I’ve felt indebted to the Web3 community ever since many of you backed my presidential campaign in 2020.  Most of you likely know that I ran on a policy of Universal Basic Income – an idea I championed, in some part, because it would empower creatives to thrive in a way our current system simply doesn’t.  Blockchain technology, and NFTs in particular, are making this a reality for millions every day.   

What you might not know is that in the early days of my campaign, I also championed a policy I called Digital Social Credits (DSCs) - a system where tokens or currencies outside the traditional financial systems serve as incentives for different behaviors that currently go unrewarded: volunteering, helping a neighbor, reducing your carbon footprint. Here again, blockchain-based ecosystems (that many of you are building) are making it happen for millions right now.  
  
For the last number of years, my professional goal has been to eradicate poverty. It was why I launched my presidential campaign and it is why, when I realized our political parties were not designed to be effective, I launched a third party to advocate for democracy reform. Digital assets - including cryptocurrencies, NFTs, community tokens, etc. - are leading the greatest episode of value creation in our lifetime. Trillions of dollars are flowing into this new world, presenting a tremendous opportunity to build new systems and new wealth in the fight against poverty. 
  
The biggest threat to Web3 succeeding in the long term, in my opinion, are ill-informed policies from leaders who have yet to understand the benefits of the future that is possible. We’ve already seen what this looks like. The broker definition debacle that transpired as part of the infrastructure bill that passed last year is a harbinger of what’s to come if we fail to properly educate and work with congressional leaders on the potential of Web3.  We also have to avoid the politicization of this set of technologies and be seen as an industry like any other.  The community needs an organization and a seat at the legislative table. As one prominent lobbyist told me, “if you’re not at the table, you’re on the menu.”
  
This is why I am launching Lobby3 – a blockchain-based fundraising effort and community aimed at building a powerful lobbying infrastructure that can bring the voices of Web3 to Washington DC.  We have to ensure that any new regulations are mindful of the realities of Web3 firms and create an environment here in the US where innovation can continue to thrive.  
  
I’m writing this to explain what this vision will look like and why we can do it:
  
<h4 style="margin: 0px; font-size: 30px;">1. The Concept</h4>
By now, you probably know: Washington DC is wildly out of touch and behind the times when it comes to anything technological but especially anything to do with the blockchain. I spoke to lobbyists who talked with politicians on Capitol Hill about Web3. They frequently heard two things: 
  
Isn’t that just used for money laundering and human trafficking? You’re the only person who has ever said anything positive to me about crypto - except for industry lobbyists whose insights I take with a grain of salt.
  
It’s worth pausing on this second point. Much of (though not all of) the lobbying energy up to this point has come directly from corporate players in the crypto space. Understandably, these companies are inherently met with some skepticism by our nation’s representatives and seen as being financially self-interested in their pursuits. 
  
The opportunity in the Web3 lobbying world is a group with no specific financial interest at stake. One that represents the people, not the industry. It is a non-partisan, organized lobbying effort that can work on both sides of the aisle and therefore be massively effective on Capitol Hill when new legislation is drafted. I’ve experienced this first hand when lobbying for cash relief with a non-profit I founded in 2020, Humanity Forward—successfully lobbying on behalf of pandemic-era stimulus checks and an expansion to the child tax credit. Our efforts worked because our bi-partisan efforts avoided making cash relief a political scapegoat for the left or the right, but instead, worked behind the scenes to present the benefits to all Americans, regardless of political ideology.
   
Lobby3 is meant to be a lobby for the people, and specifically, for the poorest among us. That is the perspective we can bring to Congress to build political support for Web3. We will leverage the strong reputation we’ve built as champions for the people with a goal to eliminate poverty. We can tell the stories of the real people whose lives are being improved. This will be a unique and powerful lane to occupy as we give Web3 a voice on Capitol Hill.
  
I know we can present an organized, cohesive, well-messaged campaign in Washington DC on behalf of the Web3 community, because it will be true.
  
<h4 style="margin: 0px; font-size: 30px;">2. What We Do</h4>
The proceeds from Lobby3 will directly fund policy advocacy operations in DC, dedicated to decentralizing economic opportunity and educating policymakers on Web3. The infrastructure I’m describing would have several components to it:
  
<strong>Building Advocacy Infrastructure</strong>
  
Lobby3 will fund issue-based advocacy organizations in DC to build the core advocacy infrastructure. These organizations will bring together lobbyists and stakeholders with relationships with the key committees and players on Capitol Hill. This is the biggest missing piece and critical to getting Web3 a seat at the table.
  
<strong>Educating Policymakers</strong>
  
Lobby3’s partners will work to educate key lawmakers in Congress and across the country on the positive potential impacts of Web3. This work will pave the way for smart, well-informed policymaking in years ahead. 
  
<strong>Pilot Programs</strong>
  
In key states and districts across the country, our advocacy team will forge partnerships with Governors, Mayors and other local elected officials to run Web3 pilot programs — geared toward demonstrating the capacity of blockchain technology to reduce poverty, lift barriers to financial inclusion, and create greater economic opportunity for marginalized communities.
  
<strong>Lobby3 DAO</strong>
  
Once the initial lobby infrastructure is built, a portion of proceeds will be used to fund a Lobby3 DAO treasury controlled by its members - the token holders. This DAO will vote on policy ideas, community priorities, and where DAO treasury funds should be used to further our mission. For more info on Lobby3 DAO, see our FAQ section.
  
<h4 style="margin: 0px; font-size: 30px;">3. The DAO</h4>
DAOs naturally appeal to me. A push towards greater transparency and empowerment of people is a beautiful thing. We want to make Lobby3 as people-empowered as possible while also recognizing that we need to allow the experts (in this case the lobbyists) the latitude they need to make decisions. 
  
If a situation with China arises that provides a strategic opportunity for our lobbying team to persuade a right-wing Senator that such-and-such favorable action on Web3 would help position the US in relation to China - they need to make that decision quickly without constraint or approval processes. 
  
However, this organization is about bringing the voices of Web3 to DC. As such, we will rely on Lobby3 DAO to guide priorities as well as to create proposals for further action to take. What’s more, we’ll keep the members of Lobby3 as up-to-date and informed as possible about everything happening on Capitol Hill (understanding, again, that there will be sensitive conversations, decisions, etc. that require privacy.)
  
Simply put, all Lobby3 token holders will have voting rights to determine the community’s policy preferences and prioritize our direction, and Tier II and III members will have the ability to propose new policies and ideas for the community to vote on and/or participate in. 
  
<h4 style="margin: 0px; font-size: 30px;">4. The Token</h4>
We structured this community using membership tokens because we believe in the incredible work this community is building, and it’s important to me to show the power of Web3 in organizing to our nation’s leaders.
  
To become a member of this community, you must own a Lobby3 token. Our token will be available to mint starting Tuesday, March 1st, 2022 and available on secondary markets thereafter.
  
Each token is a customizable avatar that will be placed into a mural, and you can upload any NFT you own onto your avatar. I have a vision of uniting all of the NFT communities to bring our voices to Congress, and ideally that’s what this token represents. The mural, a representation of the Washington Mall in DC, was designed by Jonathan D. Chang and contributed to by dozens of artists from the NFT community including: eBoy, Dmitry Petyakin, IlluminatiNFT, Norman Comics, Hun7ar, Fumeiji, and more. I have always been a fan of artists—I’m confident that Universal Basic Income would inspire some of the greatest art that our nation had ever seen—it’s been a privilege to work with a number of excellent creatives throughout this process.
  
As the tokens are minted, the Washington Mall on the mural will fill up with (hopefully) thousands of avatars, showing a united front to give Web3 a voice and use this newly adapted blockchain technology to help create more economic opportunities for everyone. I hope we can fill up the entire mall someday soon.
  
We set it up this way because:
  
A) It’s cool

B) We are building a community. This effort won’t work without all of us contributing to something bigger than ourselves, like thousands of people taking a stand together.
  
The Lobby3 tokens will be offered in three tiers—Member, Advocate, and Founder. We want this to be as democratized and as reflective of the people as possible, so the first tier is meant to be widely accessible. We’re also not stupid—money talks in DC, and we need the big players and big dollars to join us and help fund our efforts, while keeping our people-first mission.
  
Each token is one vote, and you can buy multiple if you’d like your voice to be louder (just know we reserve the right to balance the scales a bit if we see voting isn’t reflecting the masses!). Tier II will have the ability to determine which policy ideas and strategic directions get put to a vote by the community, plus a number of other exciting perks. 
  
Tier III will be an exclusive group of the biggest Web3 influencers in the world. I plan to spend my time with all three membership tiers, but likely a substantial amount with this top tier. Lobbying is an expensive business, and if we need to throw massive resources behind important anti-poverty initiatives in DC—more than we can quickly crowd-fund—I want to make sure we’ve assembled the right players who are aligned with the Lobby3 community and can put resources to work quickly.
  
For more details on how the NFT minting works, <a href="/">check the breakdown here</a>.
  
_______________
  
I cannot begin to express how excited I am to embark on this journey. The Web3 community has been a friend to me since I entered politics, and I hope to grow and continue that relationship with the building of Lobby3.
  
And frankly, we do not have a lot of time. As it stands today, Web3 faces existential threats. DC has been behind the curve for decades—but I’ve seen the effectiveness of bi-partisan lobbying efforts on new issues. If we act now, we have an amazing opportunity to chart the course for the future of Web3 in the United States, and I believe the rest of the world will follow our lead.
  
My promise to you is to commit to building the infrastructure our movement needs—not the flashy projects getting major headlines, but the boring behind the scenes work of pounding the pavement, building relationships with legislators, and building a case about how Web3 is a key part of building a better future. That said, this is a promise I cannot keep all by myself. This effort needs us to all come together, and line up behind our shared vision. This will not be easy, but if our goal is to eradicate poverty and build a better future for my kids and yours, there is nothing more worthwhile.
  
I hope you’ll join me in this fight. 
  
Forward,
Andrew Yang
  `;

  return (
    <div className={css(styles.story)}>
      <div className={css(styles.andrew)}>
        <img src={teamOne} className={css(styles.andrewPhoto)}></img>
      </div>
      <div dangerouslySetInnerHTML={{ __html: html }}></div>
    </div>
  );
};

const styles = StyleSheet.create({
  story: {
    whiteSpace: "pre-wrap",
    padding: 50,
    fontFamily: "Poppins",
    fontSize: 20,
    background: "#fff",

    "@media only screen and (max-width: 767px)": {
      padding: 25,
    },
  },
  andrew: {
    textAlign: "center",
    marginBottom: 16,
  },
  andrewPhoto: {
    borderRadius: "50%",
    height: 250,
    width: 250,
    objectFit: "cover",
    border: "2px solid #111",
    "@media only screen and (max-width: 767px)": {
      height: 200,
      width: 200,
    },
  },
});

export default OurStoryScreen;
