// Npm
import { useState, Fragment } from "react";
import { StyleSheet, css } from "aphrodite";
import ReactTooltip from "react-tooltip";

// Component
import Text from "./Text";
import Button from "./Button";
import Dropdown from "./Dropdown";
import MembershipThumbnail from "./MembershipThumbnail";
import EthIcon from "../assets/eth.svg";

import { Colors } from "../utils/colors";

const MembershipCard = ({
  membership,
  index,
  callout,
  selectedTier,
  setSelectedTier,
  confirmTier,
  mintFlow,
  showButton = true,
}) => {
  const {
    assets, // assets for membership thumbanil
    icon,
    label, // ex: "Level 1"
    tier, // ex: "Standard"
    description, // ex: "Member, Founder"
    text, // summary of level
    price, // ex: 40 Eth
    perks, // list of perks
    perkIcon, // icon accompanying perks
    level,
  } = membership;

  const [showPerks, setShowPerks] = useState(false);
  const isActive = selectedTier === tier;

  function _formatCardStyle() {
    const classNames = [styles.membershipCard];
    switch (tier) {
      case "Standard":
        classNames.push(styles.standardHover);
        classNames.push(isActive ? styles.standardActive : null);
        break;
      case "Gold":
        classNames.push(styles.goldHover);
        classNames.push(isActive ? styles.goldActive : null);
        break;
      case "Diamond":
        classNames.push(styles.diamondHover);
        classNames.push(isActive ? styles.diamondActive : null);
        break;
      default:
        break;
    }
    return classNames;
  }

  function renderPerkIcons() {
    const icons = new Array(
      tier === "Standard" ? 1 : tier === "Gold" ? 2 : tier === "Diamond" && 3
    ).fill(0);

    return (
      <div className={css(styles.perkIcon)}>
        {icons.map((_, i) => (
          <img
            key={`perk-icon-tier-${i}`}
            src={perkIcon}
            className={css(showPerks ? styles.chevronUp : styles.chevronDown)}
            draggable={false}
            alt={showPerks ? "chevron-up" : "chevron-down"}
          />
        ))}
      </div>
    );
  }

  function _formatLabelStyles() {
    const classNames = [styles.membershipText];
    switch (tier) {
      case "Standard":
        classNames.push(styles.standardText);
        break;
      case "Gold":
        classNames.push(styles.goldText);
        break;
      case "Diamond":
        classNames.push(styles.diamondText);
        break;
      default:
        break;
    }

    return classNames;
  }

  function _handleClick() {
    setSelectedTier(tier);
  }

  function _formatTooltip() {
    return showPerks ? "Hide Benefits" : "Show Benefits";
  }

  return (
    <div className={css(styles.membership)} onClick={_handleClick}>
      <div className={css(_formatCardStyle())}>
        <div className={css(styles.headerContainer)}>
          <div className={css(styles.header)}>
            <div className={css(styles.left)}>
              <MembershipThumbnail membership={membership} />
              <Text
                type={"h3"}
                label={description}
                style={_formatLabelStyles()}
              />
            </div>
            <div
              className={css(styles.right)}
              data-for={`eth-price-${tier}`}
              data-tip
              data-iscapture
            >
              <img src={EthIcon} className={css(styles.ethIcon)} />
              <Text type={"h3"} style={styles.price}>
                {price}
                <span className={css(styles.each)}>{"each"}</span>
              </Text>
            </div>
          </div>
          {callout && <div className={css(styles.callout)}>{callout}</div>}
        </div>
        <Text style={styles.memberDescription} label={text} />
        <div
          className={css(
            styles.perkTitleRow,
            showPerks && styles.activePerkTitleRow,
            mintFlow && styles.marginTopAuto
          )}
          onClick={() => setShowPerks(!showPerks)}
          data-for={`membership-${tier}`}
          data-tip
          data-iscapture
        >
          <Text type={"h3"} label={"Benefits"} />
          {renderPerkIcons()}
        </div>
        <Dropdown
          showDropdown={showPerks}
          children={
            <div className={css(styles.perks)}>
              {perks.map((perk, i) => {
                const isLast = i === perks.length - 1;
                return (
                  <Fragment>
                    <div
                      key={`membership-${level}-perk-${i}`}
                      className={css(
                        styles.perk,
                        isLast && styles.removeBorderBottom
                      )}
                    >
                      <div className={css(styles.grayShade)} />
                      <Text style={styles.perkLabel} label={perk[0]} />
                    </div>
                    <div className={css(styles.inner)}>
                      <Text
                        style={[styles.perkLabel, styles.innerPerkLabel]}
                        label={perk[1]}
                      />
                    </div>
                  </Fragment>
                );
              })}
            </div>
          }
        />
        {showButton && (
          <Button
            label={
              tier === "Diamond"
                ? "Request an Invite"
                : `Mint ${description} Tokens`
            }
            noArrow={true}
            style={styles.buttonContainer}
            buttonStyles={styles.button}
            disabled={tier !== "Diamond"}
            onClick={() => {
              if (tier === "Diamond") {
                return;
              }
              return confirmTier(membership);
            }}
            href={
              tier === "Diamond" ? "https://forms.gle/TffE9WY5sTnXhTs96" : ""
            }
          />
        )}
        <ReactTooltip id={`membership-${tier}`} getContent={_formatTooltip} />
        <ReactTooltip
          id={`eth-price-${tier}`}
          getContent={() => `${price} ETH each`}
        />
      </div>
    </div>
  );
};

const styles = StyleSheet.create({
  membershipText: {
    "@media only screen and (max-width: 415px)": {
      fontSize: 24,
    },

    "@media only screen and (max-width: 1200px) and (min-width: 1024px)": {
      fontSize: 24,
    },
  },
  callout: {
    fontSize: 18,
    marginTop: 8,
    textAlign: "right",
    // fontWeight: "bold",
    fontStyle: "italic",
  },
  standardText: {
    color: Colors.charcoal(),
    textShadow: `2px 2px ${Colors.lime()}`,
    paddingLeft: 15,
  },
  goldText: {
    color: Colors.charcoal(),
    textShadow: `2px 2px ${Colors.ethYellow()}`,
    paddingLeft: 15,
  },
  diamondText: {
    color: Colors.blue(),
    textShadow: `2px 2px ${Colors.purple()}`,
    paddingLeft: 15,
  },
  //card styles
  standardHover: {
    ":hover": {
      boxShadow: "0 0 10px rgba(0, 0, 0, 0.3)",
      background: "#FAFAFA",
    },
  },
  standardActive: {
    background: "#FAFAFA",
    boxShadow: `0 0 20px ${Colors.lime()}`,
    borderColor: Colors.charcoal(0.7),
    ":hover": {
      background: "#FAFAFA",
      boxShadow: `0 0 20px ${Colors.lime()}`,
      borderColor: Colors.charcoal(0.7),
    },
  },
  each: {
    fontWeight: 400,
    paddingLeft: 5,

    "@media only screen and (min-width: 1200px)": {
      paddingBottom: 3,
      fontSize: 18,
    },
  },
  goldHover: {
    ":hover": {
      boxShadow: "0 0 10px rgba(0, 0, 0, 0.3)",
      background: "#FAFAFA",
    },
  },
  goldActive: {
    background: "#FAFAFA",
    boxShadow: `0 0 20px ${Colors.ethYellow()}`,
    borderColor: Colors.charcoal(0.7),
    ":hover": {
      background: "#FAFAFA",
      boxShadow: `0 0 20px ${Colors.ethYellow()}`,
      borderColor: Colors.charcoal(0.7),
    },
  },
  diamondHover: {
    ":hover": {
      boxShadow: "0 0 10px rgba(0, 0, 0, 0.3)",
      background: "#FAFAFA",
    },
  },
  diamondActive: {
    background: "#FAFAFA",
    boxShadow: `0 0 20px ${Colors.blue()}`,
    borderColor: Colors.purple(),
    ":hover": {
      background: "#FAFAFA",
      boxShadow: `0 0 20px ${Colors.blue()}`,
      borderColor: Colors.purple(),
    },
  },
  marginTopAuto: {
    marginTop: "auto",
  },
  headerContainer: {
    borderBottom: `1px solid ${Colors.charcoal()}`,
    padding: "35px 0",
    paddingBottom: 16,
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "@media only screen and (max-width: 1350px)": {
      width: "100%",
      flexDirection: "column",
    },
    "@media only screen and (max-width: 1350px)": {
      paddingBottom: 20,
    },
  },
  left: {
    display: "flex",
    alignItems: "center",
    "@media only screen and (max-width: 1350px)": {
      width: "100%",
      justifyContent: "flex-start",
    },
  },
  right: {
    display: "flex",
    alignItems: "center",
    marginTop: 12,
    marginBottom: 12,
  },
  price: {
    display: "flex",
    alignItems: "flex-end",
    "@media only screen and (max-width: 1350px)": {
      display: "flex",
      width: "100%",
      justifyContent: "flex-end",
    },
    "@media only screen and (max-width: 767px)": {
      fontSize: 22,
    },

    "@media only screen and (max-width: 1200px) and (min-width: 1024px)": {
      fontSize: 17,
    },
  },
  tiers: {
    overflow: "auto",
    whiteSpace: "nowrap",
    maxWidth: 1750,
    margin: "0 auto",
    padding: "0px 40px 10px 40px",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "flex-start",

    "@media only screen and (max-width: 767px)": {
      width: "100%",
    },
  },
  square: {
    height: 50,
    marginRight: 10,
    "@media only screen and (max-width: 767px)": {
      height: 30,
    },
  },
  perkTitleRow: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "15px 0",
    padding: "15px 0",
    width: "100%",
    boxSizing: "border-box",
    ":hover": {
      color: Colors.blue(),
    },
    "@media only screen and (max-width: 415px)": {
      fontSize: 24,
    },
  },
  activePerkTitleRow: {
    color: Colors.blue(),
    borderBottom: `1px solid ${Colors.charcoal()}`,
    textShadow: `2px 2px ${Colors.gray()}`,
  },
  membership: {
    display: "inline-block",
    position: "relative",
    width: "100%",
    cursor: "pointer",
    "@media only screen and (max-width: 767px)": {
      width: "unset",
      minWidth: "90%",
      padding: 10,
    },
  },
  membershipCard: {
    borderRadius: 5,
    border: `1px solid ${Colors.charcoal()}`,
    display: "flex",
    flexDirection: "column",
    padding: "0 20px",
    boxSizing: "border-box",
    width: "100%",
    background: "#fff",
    height: "100%",
    "@media only screen and (max-width: 767px)": {
      padding: "0 16px",
    },
    "@media only screen and (min-width: 1700px)": {
      minHeight: "unset",
    },
    ":hover": {
      background: "#FAFAFA",
    },
  },

  perk: {
    display: "flex",
    justifyContent: "space-between",
    aligItems: "center",
    padding: "15px 0",
    position: "relative",
    width: "100%",
    overflow: "hidden",
  },
  grayShade: {
    height: 20,
    width: 35,
    background: Colors.gray(),
    position: "absolute",
    left: 0,
    top: 0,
  },

  buttonContainer: {
    paddingTop: 20,
    marginBottom: 20,
    width: "100%",
  },
  button: {
    width: "100%",
    padding: "15px 0",
    textAlign: "center",
  },
  memberDescription: {
    width: "100%",
    overflowWrap: "anywhere",
    hyphens: "auto",
    whiteSpace: "pre-wrap",
    boxSizing: "border-box",
    padding: "25px 0 0",
    fontFamily: "GT Flexa Trial",
    color: Colors.charcoal(0.5),
  },
  perkLabel: {
    width: "100%",
    overflowWrap: "anywhere",
    hyphens: "auto",
    whiteSpace: "pre-wrap",
    boxSizing: "border-box",
    paddingLeft: 15,
    marginTop: -10,
    zIndex: 2,
  },
  innerPerkLabel: {
    fontSize: 15,
    opacity: 0.8,
  },
  removeBorderBottom: {
    borderBottom: "unset",
    // paddingBottom: 40,
  },
  checkCircle: {
    height: 25,
    width: 25,
    minWidth: 25,
    maxWidth: 25,
  },
  membershipTitle: {
    display: "flex",
    alignItems: "center",
  },

  title: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    whiteSpace: "pre-wrap",
    padding: "20px 0",
    paddingLeft: 160,
    "@media only screen and (max-width: 767px)": {
      paddingLeft: 40,
    },
  },
  subtitle: {
    marginBottom: 20,
    paddingLeft: 160,
    "@media only screen and (max-width: 767px)": {
      paddingLeft: 40,
    },
  },
  membershipContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    padding: 50,
    boxSizing: "border-box",
    border: "1px solid black",
    marginTop: 25,
    cursor: "pointer",
    ":hover": {
      background: Colors.blue(0.1),
    },
  },
  ethIcon: {
    height: 30,
    marginRight: 10,
  },
  icon: {
    width: 25,
    cursor: "pointer",
  },
  label: {
    fontSize: 20,
    fontWeight: "bold",
    padding: "0 15px 0 20px",
    borderRight: "0.5px solid rgba(0, 0, 0, 0.1)",
  },
  description: {
    paddingLeft: 15,
  },
  summary: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    boxSizing: "border-box",
  },
  summaryLeft: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },

  arrowUp: {
    transform: "rotate(180deg)",
  },
  inner: {
    marginLeft: 16,
    marginBottom: 8,
  },
  perkIcon: {
    userSelect: "none",
  },
  chevronUp: {
    height: 25,
    cursor: "pointer",
  },

  chevronDown: {
    height: 25,
    cursor: "pointer",
    transform: "rotate(180deg)",
  },
});

export default MembershipCard;
