// Npm
import { StyleSheet, css } from "aphrodite";
import { Colors } from "../../utils/colors";
import { useState } from "react";

// Component
import Text from "../Text";
import Wrapper from "../Wrapper";

const SECTIONS = [
  {
    question: "Why Andrew Yang?",
    text: `When Andrew Yang began his run for President in 2017, the national approval rating for universal basic income (UBI) was 27%. Today, it’s 65%. And yet, Yang’s defining impact isn’t changing hearts and minds. It’s changing policy.
    \nWhen Yang suspended his presidential campaign in early 2020, he immediately went to work setting up an advocacy organization that could further the mission of cash relief for all. In just two years, it has become one of the most impactful advocacy organizations in the country - playing a defining role in getting stimulus checks passed during the pandemic and passing the child tax credit, essentially the largest Basic Income trial in history.
    \nAs a tech-forward and solutions-oriented advocate, we believe Andrew Yang’s voice and lobbying experience can help unite the Web3 community around meaningful legislative efforts to benefit the people.
    `,
  },
  {
    question: "How does the DAO work?",
    text: `We want to build not only a world-class lobbying operation, but also create an infrastructure for the web3 community to share ideas and concerns to our leaders in DC.
    \nThus, the Lobby3 DAO is designed for any community member to contribute policy ideas, vote on where the community stands, and voice their opinion on where a portion of funds raised should go. 
    \nOur governance model is simple:
    \n- One token = one vote.\n- All community members are able to draft policy proposals. Advocate level token holders are responsible for determining which proposals move to a community vote.
    \nPrimary areas of focus for the DAO:
    \n1. Policy Suggestions
    \nThe DAO will be regularly polled about policy priorities and concerns and the outcomes of these polls will help set the lobbying agenda. Members are also welcome to introduce proposals for action around particular issue areas.
    \nOf course, given the sensitive nature of lobbying (and due to certain legal restrictions), it is impossible to have community input on every legislative decision. It is important that the lobbying experts/infrastructure we build have the latitude needed to make decisions based on private information.
    \nThus, policy proposals approved by the DAO will be used as priority guidance for any lobbying efforts to decentralize economic opportunity for the people and advance Web3 on Capitol Hill. In situations where the infrastructure itself cannot adequately take on a particular issue voted on by the DAO, other avenues for supporting an issue will be explored.
    \n2. Speaker Requests / Events
    \nLobby3 DAO members can propose and vote on guests they’d like to invite to community events and Andrew Yang and his team will do their best to gain access to these individuals.
    \nTown hall meetings with teams from the House, Senate, and elsewhere in government are expected.
    \n3. Treasury
    \nOnce Lobby3 Tier I and II token members have raised enough funding to set up the initial national advocacy infrastructure (approximately $3 million), the remaining funds raised for lobbying efforts will be put into the DAO treasury, which will be overseen by token holders. Funds will be spent according to how the community votes—IRL meet-ups and events, support for other lobbying efforts, or other relevant, proposed DAO activities.
    `,
  },
  {
    question: "What is expected of me as a member?",
    text: `We want your participation, thoughts, and ideas so the voice of the Web3 community can be heard on Capitol Hill. All event attendance, contributions, voting, etc. is optional. This movement is stronger when our token owners are actively involved.`,
  },
  {
    question: "Where does the money go?",
    text: `Lobbying is expensive, as is managing and growing a strong community. Our promise to you is to be as transparent as possible in our shared mission of building a future with decentralized economic opportunity for all.
    \nHere is our financial breakdown:
    \n70% Direct Advocacy Efforts & Infrastructure Building
    \nThrough partnerships with select issue advocacy organizations such as Humanity Forward, we will deliver bipartisan, efficient, and effective progress for the people and demonstrate Web3’s potential to enable positive economic outcomes. Humanity Forward will also be working with other potential partners, including the Aspen Institute Financial Security Program, Sustainable Partners, and others they will add over time.
    \nThe initial funding will be used to set up the necessary national advocacy infrastructure. The rest of this funding block will be managed by the DAO. For additional details, please see the Roadmap above.
    \n20% Community Engagement, Operations, and Artists
    \nA portion of funds will be used to build a strong community, where the voices of the Lobby3 DAO are heard. These expenses will be managed through Humanity Forward Productions, and be spent on member events, perks, and engagement, as well as various operational expenses (legal, accounting, travel, management, administration, artist contributions etc).
    \n10% Technical Work
    \nThrough a partnership with Block::Block, a portion of funds will be used to build the website, NFTs, DAO, etc.
    `,
  },
];

const FAQ = () => {
  return (
    <Wrapper background={Colors.charcoal()}>
      <div className={css(styles.container)} id={"faq"}>
        <Text type={"h1"} style={styles.title} label={"FAQ"} />
        {SECTIONS.map((section, i) => {
          return <FAQSection key={`faq-${i}`} section={section} idx={i} />;
        })}
      </div>
    </Wrapper>
  );
};

const FAQSection = ({ section, idx }) => {
  const [isOpen, setIsOpen] = useState(idx === 0);

  function toggleAnswer() {
    setIsOpen(!isOpen);
  }

  const { question, text } = section;

  return (
    <div className={css(styles.content)}>
      <div
        className={css(styles.row, isOpen && styles.active)}
        onClick={toggleAnswer}
      >
        <Text type={"bold"} label={question} style={styles.question} />
        <i
          className={
            css(styles.plusIcon) +
            ` ${isOpen ? "fal fa-minus" : "fal fa-plus"} `
          }
        />
      </div>
      <div className={css(styles.dropdown, isOpen && styles.reveal)}>
        <Text>{text}</Text>
      </div>
    </div>
  );
};

const styles = StyleSheet.create({
  container: {
    padding: "80px 160px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    minHeight: 500,
    backgroundColor: Colors.charcoal(),
    width: "100%",
    boxSizing: "border-box",
    position: "relative",
    maxWidth: 1750,
    "@media only screen and (max-width: 767px)": {
      padding: 40,
    },
  },
  row: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
    width: "100%",
    cursor: "pointer",
    color: Colors.gray(),
    ":hover": {
      color: Colors.lime(),
    },
  },
  active: {
    color: Colors.lime(),
  },
  title: {
    width: "100%",
    padding: "20px 0",
    borderBottom: `3px solid ${Colors.gray()}`,
    color: Colors.gray(),
  },
  question: {
    fontSize: 20,
    fontWeight: "bold",
  },
  body: {
    color: Colors.charcoal(),
    padding: "15px 0",
    width: "47%",
  },
  content: {
    width: "100%",
    padding: "30px 0",
    borderBottom: `1px solid ${Colors.gray()}`,
    whiteSpace: "pre-wrap",
    color: Colors.gray(),
  },
  dropdown: {
    height: 0,
    opacity: 0,
    width: "100%",
    boxSizing: "border-box",
    borderTop: "none",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    zIndex: -1,
    pointerEvents: "none",
  },
  reveal: {
    height: "unset",
    opacity: 1,
    zIndex: 1,
    pointerEvents: "auto",
    transition: "opacity ease-in-out 0.4s",
    marginTop: 35,
  },
});

export default FAQ;
