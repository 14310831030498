import { useEffect, useState } from "react";
import { StyleSheet, css } from "aphrodite";
import ReactTooltip from "react-tooltip";

// Components
import MembershipCard from "../../MembershipCard";
import Wrapper from "../../Wrapper";
import Text from "../../Text";
import Input from "../../Input";
import Button from "../../Button";
import HyperLink from "../../HyperLink";
import MintProgress from "./MintProgress";
import backIcon from "../../../assets/arrow-right-dark.png";
import SpinLoader from "../../SpinLoader";

// Config
import { Colors } from "../../../utils/colors";
import { connectWallet, postTokenTransaction } from "../../../config/api";
import { mintTokens } from "../../../utils/web3Interact";
import { ETH_GAS_INFO_LINK } from "../../../utils/constants";

const MintMembership = ({
  userMembershipTier: membership,
  setPage,
  userTokens,
  isFetchingUserTokens,
}) => {
  const [isMinting, setIsMinting] = useState(false);
  const [numOfTokens, setNumOfTokens] = useState(1);
  const [transactionSummary, setTransactionSummary] = useState(null);
  const [txLink, setTxLink] = useState("");
  const [errorMessage, setErrorMessage] = useState(null);

  function navigateToMembershipOptions(e) {
    e && e.stopPropagation();
    if (isMinting) return;
    setPage(0);
  }

  function handleNumInput(e) {
    const _numOfTokens = e.target.value;
    const int = Number(_numOfTokens);
    setNumOfTokens(int);
  }

  async function handleMint(e) {
    e && e.preventDefault();
    setTransactionSummary(null);
    setErrorMessage(null);
    try {
      const { address } = await connectWallet();
      if (!address)
        throw new Error(
          "Wallet is not connected. Please connect and try again."
        );
      if (numOfTokens <= 0) throw new Error("Please mint at least 1 token!");
      if (numOfTokens > 10)
        throw new Error(
          "Please mint only 10 at a time, this protects you from losing gas fees."
        );
      setIsMinting(true);
      const transaction = await mintTokens(address, numOfTokens, membership);
      const { success, txHash, status } = transaction;
      if (!success) throw new Error(status);
      // api call
      const res = await postTokenTransaction(
        txHash,
        numOfTokens,
        membership,
        address
      );
      setTransactionSummary(transaction);
    } catch (err) {
      console.log(err);
      setErrorMessage(err.message);
      setIsMinting(false);
    }
  }

  const renderMintSummary = () => {
    return (
      <div style={{ maxWidth: 900, margin: "0 auto" }}>
        <Text type={"h2"} style={styles.text}>
          Avatar Creation
        </Text>
        <Text type={"p"} style={styles.text}>
          Congratulations, you have successfully minted your Lobby3 Membership
          Token! To finish your Membership Token process, create your unique
          avatar.
        </Text>
        <Text type={"p"} style={styles.text}>
          This avatar will be added to our mural on the homepage and will be
          your unique representation attached to your Membership Token.
        </Text>
        <Button
          label={`Create Your Avatar`}
          onClick={() => setPage(2)}
          showBlackArrow={true}
          style={styles.button}
        />
        <Text type={"h2"} style={[styles.text, styles.titleText]}>
          View Transaction on Etherscan
        </Text>
        <Text type={"p"} style={styles.text}>
          <a
            href={txLink}
            className={css(styles.link)}
            target="_blank"
            rel="noreferrer"
          >
            {txLink}
          </a>
        </Text>
      </div>
    );
  };

  const renderContent = () => {
    return (
      <div className={css(styles.flexRow)}>
        <div className={css(styles.left)}>
          <MembershipCard
            key={`membership-${membership.tier}`}
            membership={membership}
            showButton={false}
          />
        </div>

        <div className={css(styles.right)}>
          <form onSubmit={handleMint}>
            <Input
              value={numOfTokens}
              type={"number"}
              max={10}
              label={
                <>
                  Select Number of Tokens
                  <p
                    style={{
                      fontWeight: "normal",
                      fontSize: 16,
                      marginTop: 8,
                      marginBottom: 0,
                      textTransform: "none",
                    }}
                  >
                    Contribute more by minting more tokens
                  </p>
                </>
              }
              inputStyle={styles.input}
              onChange={handleNumInput}
              required={true}
            />
            <div className={css(styles.priceSummary)}>
              <Row
                label={"Total Cost:"}
                value={`${
                  membership.tier.toLocaleLowerCase() === "standard"
                    ? total.toFixed(2)
                    : total
                } ETH*`}
              />
              <HyperLink href={ETH_GAS_INFO_LINK}>
                <Text style={styles.gasFeeContainer}>
                  <span
                    className={css(styles.gasFee)}
                    data-for={"gas-fees"}
                    data-tip
                    data-iscapture
                  >
                    {"* Price does not include gas fees"}
                    <i
                      className={css(styles.infoIcon) + " fad fa-info-circle"}
                    />
                  </span>
                </Text>
              </HyperLink>
            </div>
            <Button
              label={"Mint"}
              style={styles.buttonContainer}
              buttonStyles={styles.button}
              isLoading={isMinting}
              disabled={isMinting}
              type={"submit"}
            />
            <ReactTooltip
              id={"gas-fees"}
              getContent={() => "What are gas fees?"}
            />
          </form>
        </div>
      </div>
    );
  };

  const renderErrorMessage = () => {
    return (
      <div className={css(styles.error, errorMessage && styles.errorShow)}>
        <i className="fas fa-exclamation-triangle" />
        <Text style={styles.errorText} label={errorMessage} />
      </div>
    );
  };

  const { priceInt: price } = membership;
  const total = price * numOfTokens;

  const mintProgressProps = {
    membership,
    numOfTokens,
    setPage,
    transaction: transactionSummary,
  };

  return (
    <Wrapper background={Colors.charcoal()}>
      <div className={css(styles.root)}>
        <div className={css(styles.headerContainer)}>
          <div className={css(styles.header)}>
            <div className={css(styles.leftColumn)}>
              <Text
                type={"h2"}
                style={styles.title}
                label={"Mint Your Tokens"}
              />
            </div>
            {isFetchingUserTokens ? (
              <SpinLoader
                isLoading={true}
                height={35}
                width={35}
                color={Colors.lime()}
              />
            ) : !!userTokens.length ? (
              <div className={css(styles.rightColumn)}>
                <Button
                  label={"View Your Tokens"}
                  onClick={() => setPage(2)}
                  style={styles.buttonWrapper}
                  buttonStyles={styles.button2}
                  noArrow={true}
                />
              </div>
            ) : null}
          </div>
        </div>
        <Wrapper background={Colors.gray()}>
          <div className={css(styles.content)}>
            {!txLink && (
              <div
                className={css(
                  styles.backButtonContainer,
                  isMinting && styles.disabled
                )}
                onClick={navigateToMembershipOptions}
              >
                <img
                  src={backIcon}
                  className={css(styles.backIcon) + " arrowIcon"}
                />
                {"Back"}
              </div>
            )}
            {errorMessage && renderErrorMessage()}
            {txLink ? renderMintSummary() : renderContent()}
            {isMinting && <MintProgress {...mintProgressProps} />}
          </div>
        </Wrapper>
      </div>
    </Wrapper>
  );
};

const Row = ({ label, value }) => {
  const containerStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "space-between",
    padding: "5px 0",
    width: "100%",
    minWidth: "100%",
  };

  return (
    <div style={containerStyle}>
      <Text label={label} style={styles.label} />
      <Text label={value} style={styles.value} />
    </div>
  );
};

const styles = StyleSheet.create({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
    background: Colors.charcoal(),
    width: "100%",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
    padding: "20px 0 40px",
    position: "relative",
    "@media only screen and (max-width: 767px)": {
      padding: 0,
    },
  },
  titleText: {
    marginTop: 20,
  },
  title: {
    fontFamily: "GT Flexa Trial",
    color: Colors.gray(),
    padding: "30px 0 0",
  },
  subtitle: {
    fontFamily: "GT Flexa Trial",
    whiteSpace: "pre-wrap",
    color: Colors.gray(),
  },
  flexRow: {
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-start",
    background: Colors.gray(),
    maxWidth: 1200,
    width: "100%",
    margin: "0 auto",
    padding: "0 30px 30px 30px",
    boxSizing: "border-box",
    "@media only screen and (max-width: 767px)": {
      flexDirection: "column",
      alignItems: "center",
    },
  },
  left: {
    width: "55%",
    marginRight: 15,
    boxSizing: "border-box",
    "@media only screen and (max-width: 767px)": {
      width: "100%",
      margin: 0,
      marginBottom: 10,
    },
  },
  right: {
    width: "45%",
    marginLeft: 15,
    height: "100%",
    boxSizing: "border-box",
    "@media only screen and (max-width: 767px)": {
      width: "100%",
      margin: 0,
      marginTop: 10,
      padding: 10,
    },
  },
  ethIcon: {
    height: 20,
    marginRight: 4,
  },
  priceSummary: {
    padding: "15px 0",
  },
  label: {
    fontWeight: 600,
    textTransform: "uppercase",
    minWidth: "100%",
    width: "100%",
    fontFamily: "Poppins",
  },
  value: {
    display: "flex",
    justifyContent: "flex-end",
    minWidth: "100%",
    width: "100%",
    fontFamily: "Poppins",
    fontSize: 20,
  },
  buttonContainer: {
    boxSizing: "border-box",
    minWidth: "100%",
    width: "100%",
    height: 60,
    boxShadow: `0 0 20px ${Colors.lightCharcoal(0.1)}`,
  },
  gasFeeContainer: {
    textAlign: "right",
  },
  button: {
    minWidth: "100%",
    width: "100%",
    padding: "15px 0",
    height: 60,
  },
  input: {
    display: "flex",
    justifyContent: "flex-end",
    boxSizing: "border-box",
  },
  backButtonContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    cursor: "pointer",
    fontSize: 25,
    padding: "10px 0 20px 30px",
    marginLeft: 5,
    zIndex: 1,
    ":hover": {
      color: Colors.blue(),
    },
    ":hover .arrowIcon": {
      opacity: 0.6,
    },
    "@media only screen and (max-width: 767px)": {
      paddingTop: 40,
      paddingLeft: 40,
    },
  },
  backIcon: {
    transform: "rotate(180deg)",
    marginRight: 10,
    width: 30,
  },
  disabled: {
    opacity: 0.7,
    pointerEvents: "none",
    cursor: "none",
  },
  error: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: Colors.red(),
    color: "white",
    boxSizing: "border-box",
    padding: "15px 30px",
    width: "calc(100% - 60px)",
    margin: "0 auto",
    marginBottom: 30,
    height: 0,
    opacity: 0,
    maxWidth: 1140,
    transition: "opacity ease-in-out 0.4s",
  },
  errorText: {
    marginLeft: 15,
    fontWeight: "bold",
  },
  errorShow: {
    height: "auto",
    opacity: 1,
  },
  infoIcon: {
    marginLeft: 10,
  },
  gasFee: {
    color: Colors.charcoal(),
    textAlign: "right",
    ":hover": {
      color: Colors.blue(),
    },
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-end",
    width: "100%",
    padding: "10px 40px 30px 40px",
    maxWidth: 1750,
    margin: "0 auto",
    boxSizing: "border-box",
    "@media only screen and (max-width: 767px)": {
      flexDirection: "column",
    },
  },
  headerContainer: {
    background: Colors.charcoal(),
    width: "100%",
  },
  leftColumn: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
    "@media only screen and (max-width: 767px)": {
      width: "100%",
      minWidth: "100%",
      paddingBottom: 15,
    },
  },
  rightColumn: {
    display: "flex",
    justifyContent: "flex-end",
    "@media only screen and (max-width: 767px)": {
      width: "100%",
      minWidth: "100%",
    },
  },
  // BUTTONS
  buttonWrapper: {
    minWidth: 300,
    height: 60,
    "@media only screen and (max-width: 767px)": {
      width: "100%",
      minWidth: "100%",
      // margin: "10px 0"
    },
  },
  button2: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontFamily: "GT Flexa Trial",
    fontWeight: "bold",
    padding: "20px 30px",
    boxSizing: "border-box",
    borderRadius: 3,
    minWidth: 300,
    height: 50,
    boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",

    "@media only screen and (max-width: 767px)": {
      width: "100%",
      minWidth: "100%",
    },
  },
  heart: {
    color: "purple",
  },
});

export default MintMembership;
