import { StyleSheet, css } from "aphrodite";
import { useEffect, useState } from "react";

import checkCircle from "../../assets/icons/checkcircle-white.svg";
import checkCircleActive from "../../assets/icons/checkcircle-lime.svg";

import { Colors } from "../../utils/colors";

// overlay allowing users to select orientation

const OptionCardOverlay = ({
  option,
  orientation,
  isActive,
  onClick,
  faces,
}) => {
  function isFaceActive(index) {
    if (orientation === "front" || index === 1) {
      return faces[index]?.id === option?.id;
    }

    if (index === 0) {
      return faces[2]?.id === option?.id;
    }

    if (index === 2) {
      return faces[0]?.id === option?.id;
    }
  }

  function handleFaceOrientation(index) {
    if (orientation === "front" || index === 1) {
      return onClick(option, index);
    }

    return onClick(option, index === 0 ? 2 : 0);
  }

  const buttons = faces.map((face, i) => {
    const classNames = [styles.icon];
    if (i === 0) classNames.push(styles.left);
    if (i === 2) classNames.push(styles.right);

    return (
      <img
        key={`overlaybutton-${option}-${i}`}
        className={css([classNames])}
        src={isFaceActive(i) ? checkCircle : checkCircleActive}
        draggable={false}
        onClick={(e) => {
          e && e.preventDefault();
          e && e.stopPropagation();
          handleFaceOrientation(i);
        }}
      />
    );
  });

  return (
    <div
      className={
        css(styles.overlay, isActive && styles.active) + " face-overlay"
      }
    >
      {buttons}
    </div>
  );
};

const styles = StyleSheet.create({
  overlay: {
    position: "absolute",
    bottom: 0,
    right: 0,
    display: "none",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    maxWidth: "100%",
    background: Colors.charcoal(0.5),
    overlay: "hidden",
  },
  active: {
    display: "flex",
  },
  icon: {
    height: 30,
    padding: 5,
    cursor: "pointer",
    userSelect: "none",
    ":hover": {
      // boxShadow: '0 0 4px rgba(305, 255, 255, 0.3)',
    },
  },
  left: {
    transform: "rotate(-90deg)",
  },
  center: {},
  right: {
    transform: "rotate(90deg)",
  },
});

export default OptionCardOverlay;
