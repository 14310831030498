import { StyleSheet, css } from "aphrodite";
import { useState } from "react";
import ReactTooltip from "react-tooltip";

// Components
import Wrapper from "../../Wrapper";
import Text from "../../Text";
import Loader from "../../SpinLoader";
import MembershipThumbnail from "../../MembershipThumbnail";
import Button from "../../Button";
import EthLoader from "../../EthLoader";
import HyperLink from "../../HyperLink";
import arrowRightDark from "../../../assets/arrow-right-dark.png";

// import

// Config
import { Colors } from "../../../utils/colors";
import { Fragment, useEffect } from "react";

const MintSuccess = () => {
  return <div className={css(styles.root)}></div>;
};

const MintProgress = ({ numOfTokens, membership, transaction, setPage }) => {
  const TOKENS = new Array(Number(numOfTokens)).fill(0);

  const status = getStatus();

  function getStatus() {
    if (!transaction) return "pending";
    if (transaction?.success) return "success";
    if (!transaction?.success) return "failed";
  }

  function formatTitle() {
    const token = `Token${numOfTokens > 1 ? "s" : ""}`;

    switch (status) {
      case "pending":
        return (
          <Text
            type={"h4"}
            style={styles.title}
            label={`Processing ${numOfTokens} ${token}`}
          />
        );
      case "success":
        return (
          <div className={css(styles.header)}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
                width: "100%",
                marginBottom: 20,
              }}
            >
              <MembershipThumbnail membership={membership} />
              <Text
                type={"h2"}
                style={styles.successTitle}
                label={"Congratulations!"}
              />
            </div>
            <Text
              label={`Congratulations, you have successfully minted your Lobby3 Token, and are officially a member of the Lobby3 community!`}
            />
            <Text style={styles.subtitle}>
              To receive your unique token, and join the collaborative mural on
              the homepage, <b>click below to create your unique avatar</b>.
            </Text>
            <Button
              label={"Create Avatar"}
              onClick={() => setPage(2)}
              style={styles.buttonWrapper}
              buttonStyles={styles.button}
              noArrow={true}
            />
            <HyperLink href={transaction?.txLink} style={styles.hyperlink}>
              <Text
                type={"h3"}
                style={styles.subheader}
                label={"View transaction on Etherscan"}
              />
              <img
                id={"arrow"}
                src={arrowRightDark}
                alt={"arrow-right"}
                className={css(styles.arrowIcon)}
              />
            </HyperLink>
          </div>
        );
      case "failed":
        return "Please try again";
      default:
        return null;
    }
  }

  function formatSubtitle() {
    switch (status) {
      case "pending":
        return "Please confirm transaction via Metamask";
      case "success":
        return null;
      case "failed":
        return "Please try again";
      default:
        return null;
    }
  }

  return (
    <div
      className={css(styles.root)}
      style={{ background: Colors.gray(status === "success" ? 1 : 0.95) }}
    >
      <Text
        type={"h4"}
        style={status === "success" ? styles.successTitle : styles.title}
        label={formatTitle()}
      />
      {status !== "success" && (
        <Text label={formatSubtitle()} style={styles.subtitle} />
      )}
      {status === "pending" && (
        <div className={css(styles.row)}>
          {TOKENS.map((_, i) => {
            return (
              <ProgressCard
                key={`mint-progress-card-${i}`}
                index={i}
                numOfTokens={numOfTokens}
                membership={membership}
                transaction={transaction}
              />
            );
          })}
        </div>
      )}
    </div>
  );
};

export default MintProgress;

const ProgressCard = ({ membership, index, transaction }) => {
  const status = getStatus();

  function getStatus() {
    if (!transaction) return "pending";
    if (transaction?.success) return "success";
    if (!transaction?.success) return "failed";
  }

  function handleOnClick() {
    if (status === "success") {
      window.open(transaction?.txLink, "_blank").focus();
    }
  }

  function renderOverlay() {
    switch (status) {
      case "pending":
        return (
          <Loader
            isLoading={true}
            color={Colors.gray()}
            height={40}
            width={40}
          />
        );
      case "success":
        return <i className={"fas fa-check"} />;
      case "failed":
        return <i className={"fas fa-times"} />;
      default:
        return null;
    }
  }

  function formatStyle() {
    const classNames = [styles.card];

    switch (status) {
      case "pending":
        classNames.push(styles.pending);
        break;
      case "success":
        classNames.push(styles.success);
        break;
      case "failed":
        classNames.push(styles.failed);
        break;
    }

    return classNames;
  }

  function formatTooltip() {
    switch (status) {
      case "pending":
        return "Pending: complete transaction via Metamask prompt.";
      case "success":
        return "Click to open transaction summary";
      case "failed":
        return "Transaction failed";
    }
  }

  return (
    <Fragment>
      <div
        className={css(formatStyle())}
        onClick={handleOnClick}
        data-for={`minting-${index}`}
        data-tip
        data-iscapture
      >
        <MembershipThumbnail membership={membership} />
        <div className={css(styles.status)}>{renderOverlay()}</div>
      </div>
      <ReactTooltip id={`minting-${index}`} getContent={formatTooltip} />
    </Fragment>
  );
};

const styles = StyleSheet.create({
  root: {
    position: "absolute",
    top: 0,
    left: 0,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%",
    boxSizing: "border-box",
    zIndex: 4,
    "@media only screen and (max-width: 767px)": {
      justifyContent: "flex-start",
      paddingTop: 30,
    },
  },
  header: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
    width: "100%",
    minWidth: "100%",
    fontFamily: "Poppins",
  },
  hyperlink: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  row: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    alignItems: "center",
    width: "100%",
    height: "auto",
    margin: "0 auto",
    paddingTop: 10,
    maxWidth: 500,
  },
  subheader: {
    fontFamily: "Poppins",
    whiteSpace: "pre-wrap",
    maxWidth: 600,
    padding: "15px 0",
    fontSize: 18,
  },
  successTitle: {
    fontFamily: "Poppins",
    whiteSpace: "pre-wrap",
    maxWidth: 600,
    boxSizing: "border-box",
    padding: "0 20px",
    // marginLeft: 15
  },
  title: {
    textTransform: "uppercase",
    paddingBottom: 15,
  },
  subtitle: {
    paddingTop: 15,
    // paddingBottom: 15,
  },
  card: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
    boxSizing: "border-box",
    margin: 15,
    ":hover": {
      background: Colors.gray(0.5),
      boxShadow: "0 0 10px rgba(255, 255, 255, 0.7)",
    },
  },
  status: {
    position: "absolute",
    marginLeft: "auto",
    marginRight: "auto",
    left: 0,
    right: 0,
    textAlign: "center",
  },
  pending: {
    color: Colors.charcoal(),
    background: "#FFF",
    opacity: 0.5,
  },
  success: {
    color: Colors.lime(),
    opacity: 1,
  },
  failed: {
    color: Colors.charcoal(),
    opacity: 0.5,
  },
  // BUTTONS
  buttonWrapper: {
    width: "100%",
    minWidth: "100%",
    height: 60,
    marginTop: 30,
    boxSizing: "border-box",
  },
  button: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontFamily: "GT Flexa Trial",
    fontWeight: "bold",
    padding: "20px 30px",
    boxSizing: "border-box",
    borderRadius: 3,
    border: `1px solid ${Colors.charcoal()}`,
    height: 60,
    boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
    width: "100%",
    minWidth: "100%",
  },
  arrowIcon: {
    width: 40,
    marginLeft: 20,
    cursor: "pointer",
    "@media only screen and (max-width: 767px)": {
      display: "none",
    },
  },
});
