// Npm
import { StyleSheet, css } from "aphrodite";
import { Colors } from "../../utils/colors";

// Component
import teamOne from "../../assets/team/team-one.png";
import zach from "../../assets/team/zachg.webp";
import carly from "../../assets/team/carly.jpeg";
import blockblock from "../../assets/team/blockblock.jpeg";
import patrick from "../../assets/team/white-q5.png";
import jonathan from "../../assets/team/jonathan.jpeg";

import Text from "../Text";
import Wrapper from "../Wrapper";

const CORE_FOUNDERS = [
  {
    name: "Andrew Yang",
    image: teamOne,
    role: "investor",
  },
  {
    name: "Zach Graumann",
    image: zach,
    role: "investor",
  },
  {
    name: "Jonathan D Chang",
    image: jonathan,
    role: "investor",
  },
  {
    name: "Q5",
    image: patrick,
    role: "investor",
    href: "https://www.quantfive.org",
  },
  {
    name: "Carly Reilly",
    image: carly,
    role: "investor",
  },
  {
    name: "Block::Block",
    image: blockblock,
    role: "investor",
  },
];

const additional_team = [
  {
    name: "Joshua Lee",
    href: "https://joshleebuilds.xyz",
  },
];

const artists = [
  {
    name: "eBoy",
    href: "http://c6y.github.io/eboylinks/",
  },
  {
    name: "NFT Gerry",
    href: "https://twitter.com/nftgerry",
  },
  {
    name: "Isaak Lien @isaaklien",
    href: "https://twitter.com/isaaklien",
  },
  {
    name: "Alt James",
    href: "https://twitter.com/AltJamesA",
  },
  {
    name: "Hun7ar",
    href: "http://twitter.com/hun7ar",
  },
  {
    name: "Phillip Lietz",
    href: "http://twitter.com/plietzing",
  },
  {
    name: "@NORMANCOMICS aka AARON GOMBAR",
    href: "https://linktr.ee/NORMANCOMICS",
  },
  {
    name: "Dmitry Petyakin (El-Metallico)",
    href: "https://twitter.com/ElMetallico1",
  },
  {
    name: "Fumeiji",
    href: "https://twitter.com/fumeiji",
  },
  {
    name: "lluminatiNFT designed by Process Grey",
    href: "https://twitter.com/truth",
  },
  {
    name: "Walter Ostlie",
    href: "https://twitter.com/WalterOstlie",
  },
  {
    name: "ROBNESS V2",
    href: "https://twitter.com/robnessofficial",
  },
  {
    name: "Aletsy Torres",
    href: "https://aletsytorres.wixsite.com/website/rendering-lighting",
  },
  {
    name: "Colton Orr",
  },
  {
    name: "Steve Tenebrini",
    href: "https://twitter.com/tenebrini",
  },
];

const Artist = ({ href, name }) => {
  return (
    <a
      className={css(styles.artist)}
      href={href}
      target="_blank"
      rel="noreferrer"
    >
      {name}
    </a>
  );
};

const Team = () => {
  return (
    <>
      <Wrapper style={styles.backgroundWhite}>
        <div className={css(styles.container)} id={"team"}>
          <Text type={"h1"} style={styles.title} label={"Core Founders"} />
          <div className={css(styles.teamContainer)}>
            {CORE_FOUNDERS.map((member, i) => {
              if (member.href) {
                return (
                  <a href={member.href} target="_blank" rel="noreferrer">
                    <TeamMember member={member} />
                  </a>
                );
              }
              return <TeamMember member={member} />;
            })}
          </div>
        </div>
      </Wrapper>
      <div className={css(styles.artistParentContainer)}>
        <Text type={"h1"} style={styles.title} label={"Contributing Artists"} />
        <div className={css(styles.artistsContainer)}>
          {artists.map((artist, i) => {
            return <Artist name={artist.name} href={artist.href} />;
          })}
        </div>
      </div>
      <div className={css(styles.artistParentContainer)}>
        <Text
          type={"h1"}
          style={styles.title}
          label={"Additional Team Members"}
        />
        <div className={css(styles.artistsContainer)}>
          {additional_team.map((artist, i) => {
            return <Artist name={artist.name} href={artist.href} />;
          })}
        </div>
      </div>
    </>
  );
};

const TeamMember = ({ member }) => {
  const { name, image, role, href } = member;

  return (
    <div className={css(styles.teamMember)}>
      <img
        src={image}
        className={css(styles.image)}
        alt={name}
        draggable={false}
      />
      <Text type={"h3"} style={styles.name}>
        {name}
      </Text>
      <Text style={styles.role}></Text>
    </div>
  );
};

const styles = StyleSheet.create({
  teamContainer: {
    background: "#fff",
    whiteSpace: "nowrap",
    margin: "25px 0",
    display: "flex",
    flexWrap: "wrap",
    marginLeft: 145,

    "@media only screen and (max-width: 767px)": {
      marginLeft: 10,
    },
  },
  artistParentContainer: {
    maxWidth: 1750,
    margin: "0 auto",
    background: "#fff",
  },
  artistsContainer: {
    background: "#fff",
    display: "grid",
    "grid-template-columns": "auto auto",
    "grid-row": "auto auto",
    "grid-row-gap": 8,
    padding: "25px 0",
    flexWrap: "wrap",
    paddingLeft: 165,
    "@media only screen and (max-width: 767px)": {
      paddingLeft: 10,
    },
  },
  teamMember: {
    display: "inline-block",
    background: Colors.charcoal(),
    width: 400,
    border: `1px solid ${Colors.gray()}`,
    margin: "0 15px 30px",
    "@media only screen and (max-width: 415px)": {
      width: "90%",
    },
  },
  image: {
    height: 470,
    minHeight: 470,
    maxHeight: 470,
    width: "100%",
    objectPosition: "top",
    objectFit: "cover",
    borderBottom: `0.5px solid ${Colors.gray()}`,
    filter: "grayscale(100%)",
    "@media only screen and (max-width: 415px)": {
      height: 425,
      minHeight: 425,
      maxHeight: 425,
    },
  },
  artist: {
    color: "#111",
  },
  backgroundWhite: {
    background: "#fff",
  },
  name: {
    paddingTop: 10,
    paddingLeft: 20,
    color: Colors.gray(),
    fontFamily: "GT Flexa Trial",
    fontSize: 25,
  },
  role: {
    padding: "5px 0 20px 20px",
    color: Colors.gray(),
    textTransform: "capitalize",
    fontWeight: "lighter",
  },
  container: {
    padding: "80px 0",
    minHeight: 500,
    width: "100%",
    boxSizing: "border-box",
    position: "relative",
    maxWidth: 1750,
    "@media only screen and (max-width: 767px)": {
      paddingLeft: 0,
    },
  },
  title: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    whiteSpace: "pre-wrap",
    color: Colors.charcoal(),
    padding: "20px 0 0 160px",
    "@media only screen and (max-width: 767px)": {
      paddingLeft: 40,
    },
  },
});

export default Team;
