// Npm
import { StyleSheet, css } from "aphrodite";
import { useEffect, useState } from "react";

// Component
import Text from "../../Text";
import AvatarGenerator from "../../avatar/AvatarGenerator";
import AvatarSelectors from "../../avatar/AvatarSelectors";
import AvatarRotationButton from "./AvatarRotationButton";

// Utils
import {
  DEFAULT_SHIRT,
  DEFAULT_PANTS,
  DEFAULT_HANDS,
  DEFAULT_COLOR,
  DEFAULT_FACES,
} from "../../../utils/constants";
import { Colors } from "../../../utils/colors";

// Avatar Creation Screen
const AvatarCreator = ({
  userNfts,
  userAvatar,
  isFetchingUserNfts,
  setUserAvatar,
  pageKey,
  _getUserNfts,
  totalNFTCount,
}) => {
  const [activeSelector, setActiveSelector] = useState(0); // index of active selector

  const [orientation, setOrientation] = useState("front");
  const [shirt, setShirt] = useState(userAvatar?.shirt || DEFAULT_SHIRT);
  const [pants, setPants] = useState(userAvatar?.pants || DEFAULT_PANTS);
  const [hands, setHands] = useState(userAvatar?.hands || DEFAULT_HANDS);
  const [faces, setFaces] = useState(userAvatar?.faces || formatDefaultFaces());
  const [color, setColor] = useState(userAvatar?.color || DEFAULT_COLOR);

  useEffect(() => {
    // pass useravatar to parent on state change
    const avatarAttributes = {
      shirt,
      pants,
      hands,
      faces,
      color,
    };
    setUserAvatar(avatarAttributes);
  }, [shirt, pants, hands, faces, color, setUserAvatar]);

  function formatDefaultFaces() {
    return DEFAULT_FACES().map((face, i) => userNfts[i] || face);
  }

  const avatarProps = {
    shirt,
    pants,
    hands,
    faces,
    orientation,
    color, // add
    setShirt,
    setPants,
    setHands,
    setFaces,
  };

  const selectorProps = {
    activeSelector,
    setActiveSelector,
    avatarProps,
    userNfts,
    color,
    setColor,
    pageKey,
    _getUserNfts,
    isFetchingUserNfts,
    totalNFTCount,
  };

  return (
    <div className={css(styles.root)}>
      <div className={css(styles.container)}>
        <div className={css(styles.titleContainer)}>
          <Text
            type={"h2"}
            style={styles.title}
            label={"Avatar Character Creator"}
          />
          <Text
            style={styles.subtitle}
            label={
              "You can customize your avatar using up to three (3) NFTs you own, and/or use our ready-made presets to create your unique avatar. Click through each customization area (NFTs, Face, Shirt, etc) to finalize your avatar."
            }
          />
        </div>

        <div className={css(styles.creatorContainer)}>
          <AvatarSelectors {...selectorProps} />
          <div className={css(styles.avatarPreview)}>
            <AvatarGenerator {...avatarProps} />
          </div>
          <AvatarRotationButton
            orientation={orientation}
            onClick={() =>
              setOrientation(orientation === "front" ? "back" : "front")
            }
          />
        </div>
      </div>
    </div>
  );
};

const styles = StyleSheet.create({
  root: {
    background: Colors.charcoal(),
  },
  container: {
    padding: "25px 50px",
    width: "100%",
    boxSizing: "border-box",
    "@media only screen and (max-width: 767px)": {
      padding: 20,
    },
  },
  titleContainer: {
    width: "100%",
    maxWidth: 1200,
    margin: "0 auto",
  },
  creatorContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    position: "relative",
    width: "100%",
    maxWidth: 1200,
    background: Colors.medCharcoal(),
    padding: 50,
    boxSizing: "border-box",
    borderRadius: 5,
    border: "1px solid #FAFAFA",
    margin: "0 auto",
    "@media only screen and (max-width: 767px)": {
      flexDirection: "column-reverse",
      padding: 10,
      border: "none",
      background: Colors.charcoal(),
    },
  },
  title: {
    // padding: "20px 0",
    color: Colors.gray(),
    paddingBottom: 20,
  },
  subtitle: {
    whiteSpace: "pre-wrap",
    color: Colors.gray(),
    paddingBottom: 30,
  },
  avatarPreview: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "50px 0 0 50px",
    boxSizing: "border-box",
    width: "50%",
    maxWidth: "50%",
    "@media only screen and (max-width: 767px)": {
      width: "100%",
      maxWidth: "100%",
      padding: "100px 0 0",
    },
  },
});

export default AvatarCreator;
