// Npm
import { StyleSheet, css } from "aphrodite";

import userPlaceholder from "../assets/placeholder.png";
import { Colors } from "../utils/colors";

import CascadingImage from "./CascadingImage";

const MOBILE_SIZE = 50;

const MembershipThumbnail = ({ membership, size = 60 }) => {
  const dimensions = {
    height: size,
    minHeight: size,
    maxHeight: size,
    width: size,
    minWidth: size,
    maxWidth: size,
  };

  if (!membership) return <ThumbnailPlaceholder style={dimensions} />;

  const {
    assets: { background, outline, frame },
    tier,
  } = membership;

  return (
    <div
      className={css(styles.thumbnailContainer) + " shiny-thumbnail"}
      style={dimensions}
    >
      <img
        src={background}
        className={css(styles.thumbnail)}
        style={dimensions}
        draggable={false}
      />
      <img
        src={frame}
        className={css(styles.thumbnail, styles.frame)}
        style={dimensions}
        draggable={false}
      />
      <img
        src={outline}
        className={css(styles.thumbnail, styles.outline)}
        style={dimensions}
        draggable={false}
      />
    </div>
  );
};

const ThumbnailPlaceholder = ({ style }) => {
  return (
    <img
      src={userPlaceholder}
      alt={"placeholder thumbnail"}
      className={css(styles.placeholderThumbnail) + " shiny-thumbnail"}
      style={style}
      draggable={false}
    />
  );
};

const smallDesktopSize = MOBILE_SIZE - 10;

const styles = StyleSheet.create({
  thumbnailContainer: {
    borderRadius: 10,
    border: `1px solid ${Colors.charcoal()}`,
    boxShadow: `0 0 5px ${Colors.lightCharcoal()}`,
    overflow: "hidden",
    position: "relative",
    userSelect: "none",
    "@media only screen and (max-width: 767px)": {
      height: MOBILE_SIZE,
      minHeight: MOBILE_SIZE,
      maxHeight: MOBILE_SIZE,
      width: MOBILE_SIZE,
      minWidth: MOBILE_SIZE,
      maxWidth: MOBILE_SIZE,
    },
    "@media only screen and (max-width: 1200px)": {
      height: smallDesktopSize,
      minHeight: smallDesktopSize,
      maxHeight: smallDesktopSize,
      width: smallDesktopSize,
      minWidth: smallDesktopSize,
      maxWidth: smallDesktopSize,
    },
  },
  placeholderThumbnail: {
    objectFit: "contain",
    borderRadius: 10,
    border: `1px solid ${Colors.charcoal()}`,
    boxShadow: `0 0 5px ${Colors.lightCharcoal()}`,
    "@media only screen and (max-width: 767px)": {
      height: MOBILE_SIZE,
      minHeight: MOBILE_SIZE,
      maxHeight: MOBILE_SIZE,
      width: MOBILE_SIZE,
      minWidth: MOBILE_SIZE,
      maxWidth: MOBILE_SIZE,
    },
  },
  thumbnail: {
    position: "absolute",
    top: 0,
    right: 0,
    borderRadius: 10,
    "@media only screen and (max-width: 767px)": {
      height: MOBILE_SIZE,
      minHeight: MOBILE_SIZE,
      maxHeight: MOBILE_SIZE,
      width: MOBILE_SIZE,
      minWidth: MOBILE_SIZE,
      maxWidth: MOBILE_SIZE,
    },
    "@media only screen and (max-width: 1200px)": {
      height: smallDesktopSize,
      minHeight: smallDesktopSize,
      maxHeight: smallDesktopSize,
      width: smallDesktopSize,
      minWidth: smallDesktopSize,
      maxWidth: smallDesktopSize,
    },
  },
  frame: {
    zIndex: 2,
  },
  outline: {
    zIndex: 3,
  },
});

export default MembershipThumbnail;
