// Npm
import { StyleSheet, css } from "aphrodite";
import { Colors } from "../../utils/colors";

// Component
import Text from "../Text";
import Wrapper from "../Wrapper";
import CountDown from "../CountDown";
import Button from "../Button";
import ComingSoonModal from "../Modals/ComingSoonModal";
import { useState } from "react";
import Link from "../Link";
import MuralCanvas from "./Canvas";
import { OPENING_DATE } from "../../utils/constants";

const Puzzle = ({ isMobile, windowProps }) => {
  const [modalOpen, setModalOpen] = useState(false);
  return (
    <Wrapper>
      <div className={css(styles.container)}>
        {/* <img
          className={css(styles.puzzle)}
          src={mural}
          alt="Lobby3 Mural"
          draggable={false}
        /> */}
        <MuralCanvas {...windowProps} />
        <div className={css(styles.content)}>
          <div className={css(styles.innerContainer)}>
            <div className={css(styles.heroTitle)}>
              <Text type={"h1"} style={styles.title} label={"Join"} />
            </div>
            <div className={css(styles.heroTitle)}>
              <Text type={"h1"} style={styles.title} label={"the"} />
            </div>
            <div className={css(styles.heroTitle)}>
              <Text type={"h1"} style={styles.title} label={"Movement"} />
            </div>
            <div className={css(styles.givePointerEvents)}>
              {/* <Link to="/mint/membership"> */}
              <Button
                label={`Mint Your Membership Token`}
                showBlackArrow={true}
                noArrow={isMobile}
                buttonStyles={styles.button}
                labelStyles={styles.buttonText}
                to={
                  OPENING_DATE < new Date().getTime() ? "/mint/membership" : ""
                }
                onClick={() => {
                  OPENING_DATE > new Date().getTime() && setModalOpen(true);
                }}
              />
              {/* </Link> */}
            </div>
          </div>
        </div>
        {/* <CountDown /> */}
      </div>
      <ComingSoonModal
        isOpen={modalOpen}
        shouldCloseOnOverlayClick={true}
        closeModal={() => setModalOpen(false)}
      />
    </Wrapper>
  );
};

const styles = StyleSheet.create({
  container: {
    position: "relative",
    width: "100%",
    boxSizing: "border-box",
    objectFit: "contain",
    background: "rgb(23, 22, 25)",
    // maxWidth: 1750,
    "@media only screen and (max-width: 767px)": {
      minHeight: 400,
    },
  },
  mint: {
    marginTop: 24,
    padding: "16px 80px",
  },
  label: {
    fontSize: 30,
    fontWeight: 600,
  },
  innerContainer: {
    height: "100%",
    marginLeft: "5%",
    marginTop: "5%",

    "@media only screen and (max-width: 767px)": {
      marginLeft: 0,
      marginTop: 0,
    },
  },
  puzzle: {
    width: "100%",
    maxHeight: "calc(100vh)",
    objectFit: "contain",
    borderRadius: 4,

    "@media only screen and (max-width: 767px)": {
      width: "unset",
    },
  },
  button: {
    "@media only screen and (max-width: 767px)": {
      width: "unset",
    },
  },
  body: {
    fontWeight: 400,
    fontSize: 20,
  },
  puzzlePiece: {
    width: 200,
    marginLeft: 50,
    "@media only screen and (max-width: 955px)": {
      width: 100,
    },
    "@media only screen and (max-width: 767px)": {
      width: 50,
    },
  },
  givePointerEvents: {
    pointerEvents: "all",
  },
  buttonText: {
    fontWeight: "unset",
    fontSize: 20,
    padding: "10px 30px",
    fontFamily: "GT Flexa Expanded",
  },
  content: {
    position: "absolute",
    "pointer-events": "none",
    maxWidth: 1750,
    height: "100%",
    top: 0,
    left: "50%",
    transform: "translateX(-50%)",
    padding: "80px 160px",
    minHeight: 500,
    width: "100%",
    boxSizing: "border-box",
    color: Colors.charcoal(),
    display: "flex",
    alignItems: "flex-start",
    "@media only screen and (max-width: 767px)": {
      padding: 16,
    },
  },
  scrollContainer: {
    marginTop: 50,
  },
  scrollDown: {
    marginTop: "auto",
    width: 166,
    height: "auto",

    "@media only screen and (max-width: 767px)": {
      display: "none",
    },
  },
  title: {
    // margin: "20px 0 15px",
    background: "#111",
    color: "#fff",
    marginBottom: 16,
    display: "inline-flex",
    fontFamily: "GT Flexa Expanded",
  },
});

export default Puzzle;
