// Npm
import { StyleSheet, css } from "aphrodite";

// Components
import Text from "../../Text";
import Wrapper from "../../Wrapper";
import Link from "../../Link";
import UserName from "../../UserName";
import Logo from "../../Logo";

// Utils
import { Colors } from "../../../utils/colors";

const MintHeader = (walletProps) => {
  const {
    userIsConnected,
    isFetchingUserEns,
    networkErrorMessage,
    connectMetamask,
  } = walletProps;

  async function _handleClick(e) {
    if (userIsConnected) return;
    connectMetamask && connectMetamask();
  }

  return (
    <Wrapper background={Colors.charcoal()}>
      <div className={css(styles.container)}>
        <div className={css(styles.row)}>
          <Link to="/">
            <div className={css(styles.logoContainer)}>
              <Logo style={styles.logo} />
              <Text type={"h3"} style={styles.title} label={"Lobby3"} />
            </div>
          </Link>

          <div className={css(styles.right)} onClick={_handleClick}>
            {!userIsConnected && isFetchingUserEns ? (
              <Text label={"Connecting..."} style={styles.text} />
            ) : userIsConnected && !isFetchingUserEns ? (
              <UserName {...walletProps} slimView={true} />
            ) : networkErrorMessage ? (
              <Text label={networkErrorMessage} style={styles.text} />
            ) : null}
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

const styles = StyleSheet.create({
  container: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    width: "100%",
    background: Colors.charcoal(),
    color: Colors.gray(),
    padding: "15px 30px",
    boxSizing: "border-box",
    maxWidth: 1750,
  },
  logoContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    paddingBottom: 15,
  },
  logo: {
    height: 40,
    marginRight: 15,
  },
  button: {
    padding: "8px 60px",
  },
  labelStyles: {
    fontSize: 25,
  },
  row: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
    width: "100%",
  },
  center: {
    width: "max-content",
  },
  right: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "flex-start",
    width: "max-content",
    marginRight: -5,
  },
  hide: {
    display: "none",
  },
  title: {
    fontWeight: "bold",
  },
  text: {
    color: Colors.gray(),
  },
  label: {
    paddingBottom: 15,
    paddingRight: 30,
    fontFamily: "GT Flexa Trial",
  },
});

export default MintHeader;
