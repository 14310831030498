// Npm
import { StyleSheet, css } from "aphrodite";

const Wrapper = ({ children, background, style }) => {
  return (
    <div
      className={css(styles.container, style && style)}
      style={{ background: background }}
    >
      {children}
    </div>
  );
};

const styles = StyleSheet.create({
  container: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
});

export default Wrapper;
