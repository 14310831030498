// Npm
import { StyleSheet, css } from "aphrodite";

// Components
import EthIcon from "../assets/eth.png"

const EthLoader = ({ isLoading = true , size = 25, style }) => {
  return (
    <img 
      src={EthIcon} 
      className={css(styles.ethIcon, style && style, !isLoading && styles.hidden)}
      style={{ width: size }} 
      draggable={false} 
    />
  )
}

const styles = StyleSheet.create({
  ethIcon: {
    userSelect: "none",
    animation: "spin 2s infinite, bounce 2s infinite"
  },
  hidden: {
    display: "none"
  }
});

export default EthLoader;