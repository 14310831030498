export const Colors = {
  blue: (opacity = 1) => `rgba(70, 101, 225, ${opacity})`,
  gray: (opacity = 1) => `rgba(235, 235, 235, ${opacity})`,
  orange: (opacity = 1) => `rgba(249, 189, 54, ${opacity})`,
  charcoal: (opacity = 1) => `rgba(23, 22, 25, ${opacity})`,
  lime: (opacity = 1) => `rgba(217, 236, 107, ${opacity})`,
  purple: (opacity = 1) => `rgba(204, 175, 213, ${opacity})`,
  darkGray: (opacity = 1) => `rgba(82, 84, 91, ${opacity})`,
  medCharcoal: (opacity = 1) => `rgba(28, 27, 31, ${opacity})`,
  lightCharcoal: (opacity = 1) => `rgba(47, 46, 52, ${opacity})`,
  ethYellow: (opacity = 1) => `rgba(248, 180, 47, ${opacity})`,
  red: (opacity = 1) => `rgba(248, 131, 138, ${opacity})`
}

const paletteDict = {
  "#00b9f2": [
    { r: 0, g: 185, b: 242 },
    { r: 0, g: 167, b: 80, a: 255 },
    { r: 31, g: 133, b: 199, a: 255 },
    { r: 3, g: 167, b: 81, a: 255 },
    { r: 155, g: 166, b: 94, a: 255 },
    { r: 71, g: 141, b: 168, a: 255 },
    { r: 72, g: 141, b: 168, a: 255 },
    { r: 96, g: 154, b: 189, a: 255 },
    { r: 132, g: 53, b: 95, a: 255 },
    { r: 39, g: 153, b: 128, a: 255 },
    { r: 165, g: 176, b: 179, a: 255 },
    { r: 168, g: 12, b: 60, a: 255 },
    { r: 168, g: 0, b: 51, a: 255 },
    { r: 255, g: 195, b: 6, a: 255 },
  ],
  "#00b26b": [
    { r: 0, g: 178, b: 107 },
    { r: 78, g: 68, b: 66, a: 255 },
    { r: 138, g: 134, b: 58, a: 255 },
    { r: 0, g: 115, b: 189, a: 255 },
    { r: 3, g: 115, b: 188, a: 255 },
    { r: 21, g: 98, b: 141, a: 255 },
    { r: 39, g: 83, b: 101, a: 255 },
    { r: 41, g: 84, b: 101, a: 255 },
    { r: 134, g: 97, b: 98, a: 255 },
    { r: 174, g: 190, b: 197, a: 255 },
    { r: 194, g: 129, b: 133, a: 255 },
    { r: 251, g: 246, b: 246, a: 255 },
    { r: 255, g: 195, b: 6, a: 255 },
    { r: 255, g: 255, b: 255, a: 255 },
  ],
  "#bca69d": [
    { r: 188, g: 166, b: 157 },
    { r: 140, g: 151, b: 155, a: 255 },
    { r: 154, g: 159, b: 160, a: 255 },
    { r: 69, g: 79, b: 81, a: 255 },
    { r: 149, g: 147, b: 109, a: 255 },
    { r: 70, g: 80, b: 82, a: 255 },
    { r: 117, g: 99, b: 87, a: 255 },
    { r: 119, g: 121, b: 98, a: 255 },
    { r: 148, g: 147, b: 112, a: 255 },
    { r: 173, g: 123, b: 96, a: 255 },
    { r: 174, g: 123, b: 95, a: 255 },
    { r: 177, g: 172, b: 156, a: 255 },
    { r: 212, g: 191, b: 156, a: 255 },
    { r: 255, g: 255, b: 255, a: 255 },
  ],
  "#ff00de": [
    { r: 200, g: 93, b: 68 },
    { r: 46, g: 41, b: 75, a: 255 },
    { r: 50, g: 42, b: 73, a: 255 },
    { r: 65, g: 60, b: 111, a: 255 },
    { r: 97, g: 91, b: 168, a: 255 },
    { r: 97, g: 92, b: 170, a: 255 },
    { r: 98, g: 61, b: 40, a: 255 },
    { r: 133, g: 98, b: 67, a: 255 },
    { r: 167, g: 133, b: 92, a: 255 },
    { r: 165, g: 115, b: 131, a: 255 },
    { r: 167, g: 130, b: 135, a: 255 },
    { r: 249, g: 174, b: 93, a: 255 },
    { r: 246, g: 143, b: 85, a: 255 },
    { r: 251, g: 176, b: 94, a: 255 },
  ],
  "#d50000": [
    { r: 241, g: 91, b: 64 },
    { r: 126, g: 75, b: 100, a: 255 },
    { r: 164, g: 100, b: 79, a: 255 },
    { r: 203, g: 131, b: 91, a: 255 },
    { r: 221, g: 73, b: 59, a: 255 },
    { r: 234, g: 15, b: 91, a: 255 },
    { r: 237, g: 12, b: 91, a: 255 },
    { r: 237, g: 19, b: 46, a: 255 },
    { r: 237, g: 22, b: 31, a: 255 },
    { r: 238, g: 42, b: 30, a: 255 },
    { r: 242, g: 101, b: 28, a: 255 },
  ],
  "#eb215c": [
    { r: 241, g: 91, b: 64 },
    { r: 126, g: 75, b: 100, a: 255 },
    { r: 164, g: 100, b: 79, a: 255 },
    { r: 203, g: 131, b: 91, a: 255 },
    { r: 221, g: 73, b: 59, a: 255 },
    { r: 234, g: 15, b: 91, a: 255 },
    { r: 237, g: 12, b: 91, a: 255 },
    { r: 237, g: 19, b: 46, a: 255 },
    { r: 237, g: 22, b: 31, a: 255 },
    { r: 238, g: 42, b: 30, a: 255 },
    { r: 242, g: 101, b: 28, a: 255 },
  ],
  "#f26522": [{ r: 242, g: 101, b: 34 }],
  "#fafa64": [
    { r: 252, g: 200, b: 172 },
    { r: 154, g: 102, b: 75, a: 255 },
    { r: 98, g: 61, b: 40, a: 255 },
    { r: 163, g: 99, b: 83, a: 255 },
    { r: 164, g: 100, b: 84, a: 255 },
    { r: 201, g: 142, b: 115, a: 255 },
    { r: 206, g: 141, b: 107, a: 255 },
    { r: 231, g: 175, b: 140, a: 255 },
    { r: 233, g: 177, b: 142, a: 255 },
    { r: 241, g: 190, b: 170, a: 255 },
    { r: 243, g: 198, b: 142, a: 255 },
    { r: 246, g: 197, b: 147, a: 255 },
    { r: 251, g: 205, b: 203, a: 255 },
    { r: 254, g: 209, b: 150, a: 255 },
  ],
  "#f2817f": [{ r: 242, g: 130, b: 128, a: 255 }],
  "#ed6675": [
    { r: 252, g: 200, b: 172 },
    { r: 154, g: 102, b: 75, a: 255 },
    { r: 98, g: 61, b: 40, a: 255 },
    { r: 163, g: 99, b: 83, a: 255 },
    { r: 164, g: 100, b: 84, a: 255 },
    { r: 201, g: 142, b: 115, a: 255 },
    { r: 206, g: 141, b: 107, a: 255 },
    { r: 231, g: 175, b: 140, a: 255 },
    { r: 233, g: 177, b: 142, a: 255 },
    { r: 241, g: 190, b: 170, a: 255 },
    { r: 243, g: 198, b: 142, a: 255 },
    { r: 246, g: 197, b: 147, a: 255 },
    { r: 251, g: 205, b: 203, a: 255 },
    { r: 254, g: 209, b: 150, a: 255 },
  ],
};

export function hexColor(p) {
  return `#${p.r.toString(16).padStart(2, "0")}${p.g
    .toString(16)
    .padStart(2, "0")}${p.b.toString(16).padStart(2, "0")}`;
};

export function randomizeColors() {
  const colorMap = Object.keys(paletteDict).reduce((prev, curr) => {
    let list = paletteDict[curr];

    let rand_i = parseInt(Math.random() * list.length);
    let choice = list[rand_i];

    prev[curr] = choice;

    return prev;
  }, {});

  let hairColor = colorMap["#ff00de"];

  colorMap["#ffaa66"] = {
    r: hairColor.r * 1.5 < 255 ? parseInt(hairColor.r * 1.5) : 255,
    g: hairColor.g * 1.5 < 255 ? parseInt(hairColor.g * 1.5) : 255,
    b: hairColor.b * 1.5 < 255 ? parseInt(hairColor.b * 1.5) : 255,
  };

  // dynamic blush highlight color
  let skinColor = colorMap["#fafa64"];
  colorMap["#f2817f"] = {
    r: parseInt(skinColor.r * 0.5 + 242 * 0.5),
    g: parseInt(skinColor.g * 0.5 + 130 * 0.5),
    b: parseInt(skinColor.b * 0.5 + 128 * 0.5),
  };

  // dynamic trouser color shade
  let trouserColor = colorMap["#ed6675"];
  colorMap["#ed6675"] = {
    r: parseInt(trouserColor.r * 0.5),
    g: parseInt(trouserColor.g * 0.5),
    b: parseInt(trouserColor.b * 0.5),
  };

  return colorMap;
}