import { StyleSheet, css } from "aphrodite";
import logo from "../assets/L3_Logo_Col.webp";


const Logo = ({ style }) => {
  return (
    <img
      src={logo} 
      className={css(styles.logo, style && style)} 
      draggable={false} 
      alt={'lobby3 logo'}
    />
  )
};

const styles = StyleSheet.create({
  logo: {
    height: 100,
  },
});

export default Logo;