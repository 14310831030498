// Npm
import { StyleSheet, css } from "aphrodite";
import { Colors } from "../utils/colors";
import { useEffect, useState } from "react";
import { OPENING_DATE } from "../utils/constants";

// Component
import Text from "./Text";

const CountDown = () => {
  const [countdownTime, setCountdownTime] = useState(updateCountdown());

  useEffect(() => {
    const timer = setTimeout(() => {
      setCountdownTime(updateCountdown());
    }, 1000);

    return () => clearTimeout(timer);
  });

  function updateCountdown() {
    const now = new Date().getTime();
    const timeleft = OPENING_DATE - now;

    const time = {
      days: Math.floor(timeleft / (1000 * 60 * 60 * 24)),
      hours: Math.floor((timeleft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
      minutes: Math.floor((timeleft % (1000 * 60 * 60)) / (1000 * 60)),
      seconds: Math.floor((timeleft % (1000 * 60)) / 1000),
    };

    time.days = ("0" + time.days).slice(-2);
    time.hours = ("0" + time.hours).slice(-2);
    time.minutes = ("0" + time.minutes).slice(-2);
    time.seconds = ("0" + time.seconds).slice(-2);

    return time;
  }

  return (
    <div className={css(styles.root)}>
      <div className={css(styles.container)}>
        <div className={css(styles.time)}>
          <Text type={"h2"} style={styles.count}>
            {countdownTime.days}
          </Text>
          <Text style={styles.label}>{"Days"}</Text>
        </div>
        <div className={css(styles.time)}>
          <Text type={"h2"} style={styles.count}>
            {countdownTime.hours}
          </Text>
          <Text style={styles.label}>{"Hours"}</Text>
        </div>
        <div className={css(styles.time)}>
          <Text type={"h2"} style={styles.count}>
            {countdownTime.minutes}
          </Text>
          <Text style={styles.label}>{"Minutes"}</Text>
        </div>
        <div className={css(styles.time)}>
          <Text type={"h2"} style={styles.count}>
            {countdownTime.seconds}
          </Text>
          <Text style={styles.label}>{"Seconds"}</Text>
        </div>
      </div>
    </div>
  );
};

const styles = StyleSheet.create({
  root: {
    textAlign: "center",
    position: "absolute",
    right: 0,
    top: 0,
    padding: "0 10px",
    background: Colors.gray(),
    zIndex: 3,
    "@media only screen and (max-width: 767px)": {
      display: "flex",
      bottom: 0,
      top: "unset",
      width: "100%",
    },
  },
  container: {
    "@media only screen and (max-width: 767px)": {
      display: "flex",
      marginLeft: "unset",
      width: "100%",
    },
  },
  time: {
    "@media only screen and (max-width: 767px)": {
      width: "25%",
    },
  },
  count: {
    color: Colors.charcoal(),
    paddingTop: 20,
    fontWeight: "bold",
  },
  label: {
    paddingBottom: 20,
    textTransform: "uppercase",
    color: Colors.charcoal(),
    fontWeight: "bold",
  },
});

export default CountDown;
