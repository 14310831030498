// Npm
import { StyleSheet, css } from "aphrodite";

// Components
import AvatarCard from "./AvatarCard";
import Text from "../../Text";
import EthIcon from "../../../assets/eth.png";

// Utils
import { Colors } from "../../../utils/colors";

const AvatarGrid = ({
  userTokens,
  activeFilterOption,
  isFetching,
  onTokenClick,
  chosenTokensHash,
}) => {
  const _userTokens = userTokens.filter((userToken) => {
    if (activeFilterOption === "all") return userToken;
    return userToken.name === activeFilterOption;
  });

  function renderContent() {
    if (!userTokens.length && isFetching) return null;
    return _userTokens.map((userToken, i) => {
      return (
        <AvatarCard
          key={`grid-${userToken.id.tokenId}-${i}`}
          userToken={userToken}
          onClick={onTokenClick}
          isActive={chosenTokensHash[userToken.id]}
        />
      );
    });
  }

  if (!userTokens.length) {
    return (
      <div className={css(styles.container)}>
        {isFetching ? (
          <div className={css(styles.loadingContainer)}>
            <Text type={"h3"} label={"Fetching Your Avatars.."} />
            <img src={EthIcon} className={css(styles.ethIcon)} />
          </div>
        ) : (
          <div className={css(styles.loadingContainer)}>
            <Text type={"h3"} label={"You have no avatars in your wallet."} />
          </div>
        )}
      </div>
    );
  }

  return <div className={css(styles.grid)}>{renderContent()}</div>;
};

const styles = StyleSheet.create({
  grid: {
    overflowY: "auto",
    display: "grid",
    gridGap: 15,
    padding: "0px 5px 15px",
    boxSizing: "border-box",
    gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr",
    minHeight: 300,
    maxHeight: 450,
    width: "100%",
    "@media only screen and (max-width: 1180px)": {
      gridTemplateColumns: "1fr 1fr 1fr 1fr",
      margin: "0 auto",
    },
    "@media only screen and (max-width: 980px)": {
      gridTemplateColumns: "1fr 1fr 1fr",
      margin: "0 auto",
    },
    "@media only screen and (max-width: 767px)": {
      gridTemplateColumns: "1fr 1fr",
      padding: 0,
      gridGap: 10,
    },
  },
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    width: "100%",
    background: Colors.medCharcoal(),
    minHeight: 300,
    maxHeight: 500,
    padding: "0px 5px 15px",
    boxSizing: "border-box",
  },
  loadingContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    width: "100%",
  },
  ethIcon: {
    marginTop: 20,
    width: 50,
    animation: "spin 2s infinite, bounce 2s infinite",
  },
});

export default AvatarGrid;
