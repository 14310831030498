// Npm
import { StyleSheet, css } from "aphrodite";
import { Colors } from "../utils/colors";
import {
  FORMATTED_CLOSING_DATE,
  FORMATTED_OPENING_DATE,
  OPENING_DATE,
} from "../utils/constants";

// Components
import Text from "./Text";
import Wrapper from "./Wrapper";

const Banner = () => {
  return (
    <Wrapper background={Colors.lime()}>
      <div className={css(styles.container)}>
        {OPENING_DATE < new Date().getTime() ? (
          <Text label={`Minting has ended! Stay tuned for Updates!`} />
        ) : (
          <Text label={`Minting Starts ${FORMATTED_OPENING_DATE}`} />
        )}
      </div>
    </Wrapper>
  );
};

const styles = StyleSheet.create({
  container: {
    maxWidth: 1750,
    width: "100%",
    background: Colors.lime(),
    height: 50,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "@media only screen and (max-width: 767px)": {
      justifyContent: "flex-start",
      paddingLeft: 30,
      paddingRight: 60,
      textAlign: "center",
      height: 60,
    },
  },
});

export default Banner;
