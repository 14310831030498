import { useState, Fragment } from "react";
import { css, StyleSheet } from "aphrodite";
import ReactTooltip from "react-tooltip";

// Components
import Text from "./Text";
import Loader from "./SpinLoader";


// Utils
import { Colors } from "../utils/colors";

const UserName = ({ userEns = "", userAddress = "", slimView = false }) => {
  const [didCopy, setDidCopy] = useState(false);

  const isLoading = !userEns && !userAddress;
  
  if (isLoading) {
    return <Loader isLoading={true} height={20} width={20} />
  }

  const name = userEns || userAddress;
  const prefix = (userAddress || "").slice(0, -4);
  const suffix = (userAddress || "").slice(-4);

  function _onClick() {
    setDidCopy(true);
    navigator.clipboard.writeText(userAddress);
    setTimeout(() => setDidCopy(false), 2500);
  };

  function _formatTooltip() {
    return didCopy ? "Copied" : "Copy to Clipboard"
  } 


  if (slimView) {
    // return either ens or eth_address (ex navbar)
    return userEns
      ? (
        <div className={css(styles.ensContainer)}>
          <Text style={styles.slimEns} label={name} />
        </div>
      ) : (
        <div 
          className={css(styles.ethAddress, styles.slimEthAddress)}
          onClick={_onClick}
          data-for={"slim-username-c&p"}
          data-tip
          data-iscapture
        >
          <div className={css(styles.prefix)}>
            {prefix}
          </div>
          <div className={css(styles.suffix)}>
            {suffix}
          </div>
          <ReactTooltip
            id={"slim-username-c&p"}
            getContent={_formatTooltip}
          />
        </div>
      )
  }

  return (
    <div className={css(styles.container)}>
      {userEns && <Text style={styles.ens} label={name} />}
      <div 
        className={css(styles.ethAddress)}
        onClick={_onClick}
        data-for={"username-c&p"}
        data-tip
        style={{ color: userEns ? Colors.purple() : Colors.gray()}}
      >
        <div className={css(styles.prefix)}>
          {prefix}
        </div>
        <div className={css(styles.suffix)}>
          {suffix}
        </div>
        <ReactTooltip
          id={"username-c&p"}
          getContent={_formatTooltip}
        />
     </div>
    </div>
  )
};

const styles = StyleSheet.create({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    width: "100%"
  },
  ethAddress: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    boxSizing: "border-box",
    width: "min-content",
    cursor: "default",
    "@media only screen and (max-width: 767px)": {
      fontSize: 20
    },
    "@media only screen and (max-width: 415px)": {
      fontSize: 16
    },
  },
  slimEthAddress: {
    width: 150,
    "@media only screen and (max-width: 415px)": {
      width: 100,
    },
  },
  prefix: {
    fontSize: 20,
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    "@media only screen and (max-width: 767px)": {
      width: 250,
    },
    "@media only screen and (max-width: 540px)": {
      width: 150,
    },
    "@media only screen and (max-width: 415px)": {
      width: 100,
    },
  },
  suffix: {
    paddingRight: 15,
    fontSize: 20,
  },
  ensContainer: {
    display: "inline-block",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    "@media only screen and (max-width: 540px)": {
      maxWidth: 150,
    },
    "@media only screen and (max-width: 415px)": {
      maxWidth: 50,
    },
  },
  ens: {
    fontSize: 22,
    fontFamily: "GT Flexa Trial",
    color: Colors.gray(),
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    paddingBottom: 5,
    "@media only screen and (max-width: 767px)": {
      fontSize: 20
    },
    "@media only screen and (max-width: 415px)": {
      fontSize: 18
    },
  },
  slimEns: {
    fontFamily: "GT Flexa Trial",
    color: Colors.purple(),
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    width: "min-content",
    paddingBottom: 10,
    "@media only screen and (max-width: 767px)": {
      fontSize: 18
    },
    "@media only screen and (max-width: 415px)": {
      fontSize: 16
    },
  }
});

export default UserName;