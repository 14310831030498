import { css, StyleSheet } from "aphrodite";

// Components
import Button from "../../Button";
import Wrapper from "../../Wrapper";

// Utils
import { Colors } from "../../../utils/colors";
import { toast } from "react-toastify";

const FooterRow = ({ page, slug, setPage, chosenTokensHash }) => {
  switch (slug) {
    case "collection":
      return (
        <Wrapper background={Colors.charcoal()}>
          <div
            className={css(styles.container)}
            style={{ background: Colors.charcoal() }}
          >
            <div
              className={css(styles.row)}
              style={{ background: Colors.charcoal() }}
            >
              <Button
                label={"Mint More"}
                onClick={() => setPage(0)}
                style={styles.buttonWrapper}
                buttonStyles={styles.button}
                noArrow={true}
              />
              <Button
                label={"Customize Avatar"}
                onClick={() => {
                  if (Object.keys(chosenTokensHash).length) {
                    setPage(3);
                  } else {
                    toast.error(
                      "Please select one of your avatars to customize",
                      {
                        toastId: "customize-avatar",
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                      }
                    );
                  }
                }}
                style={styles.buttonWrapper}
                buttonStyles={styles.button}
                noArrow={true}
              />
            </div>
          </div>
        </Wrapper>
      );
    case "avatar":
      return (
        <Wrapper background={Colors.charcoal()}>
          <div
            className={css(styles.container)}
            style={{ background: Colors.charcoal() }}
          >
            <div
              className={css(styles.row)}
              style={{ background: Colors.charcoal() }}
            >
              <Button
                label={"Back"}
                onClick={() => setPage(2)}
                style={styles.buttonWrapper}
                buttonStyles={styles.button}
                noArrow={true}
              />
              <Button
                label={"Next"}
                onClick={() => setPage(4)}
                style={styles.buttonWrapper}
                buttonStyles={styles.button}
                noArrow={true}
              />
            </div>
          </div>
        </Wrapper>
      );

    default:
      return null;
  }
};

const styles = StyleSheet.create({
  container: {
    width: "100%",
    padding: "10px 30px 30px 30px",
  },
  row: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
    width: "100%",
    margin: "0 auto",
    maxWidth: 1200,
    boxSizing: "border-box",
    "@media only screen and (max-width: 767px)": {
      flexDirection: "column-reverse",
      width: "100%",
      alignItems: "center",
    },
  },
  wideRow: {
    maxWidth: 1750,
    background: Colors.gray(),
  },
  text: {
    color: Colors.gray(),
    maxWidth: "60%",
    "@media only screen and (max-width: 767px)": {
      width: "100%",
      maxWidth: "unset",
      marginBottom: 20,
    },
  },
  label: {
    textTransform: "uppercase",
    color: Colors.gray(),
    fontFamily: "GT Flexa Trial",
  },
  buttonWrapper: {
    minWidth: 300,
    height: 50,
    "@media only screen and (max-width: 767px)": {
      width: "100%",
      minWidth: "100%",
      margin: "10px 0",
    },
  },
  button: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontFamily: "GT Flexa Trial",
    fontWeight: "bold",
    padding: "20px 30px",
    boxSizing: "border-box",
    borderRadius: 3,
    minWidth: 300,
    height: 50,
    boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",

    "@media only screen and (max-width: 767px)": {
      width: "100%",
      minWidth: "100%",
    },
  },
  link: {
    textDecoration: "none",
  },
});

export default FooterRow;
