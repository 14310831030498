// Npm
import { StyleSheet, css } from "aphrodite";
import React, { useState, useEffect } from "react";
import EthLoader from "../EthLoader";
import { getImageFromProxy } from "../../config/api";

const AvatarHeadStatic = ({ faces, color, onLoadingStart, onLoadingEnd }) => {
  const [blobs, setBlobs] = useState([]);
  const [_isLoading, _setIsLoading] = useState(true);

  useEffect(() => {
    _formatImagesToBlobs(faces);
  }, [faces]);

  async function _formatImagesToBlobs(faces) {
    onLoadingStart && onLoadingStart();
    !_isLoading && _setIsLoading(true);

    const imageUrls = await Promise.all(
      faces.map((face) => {
        if (!face) return null;
        const imageUrl = face?.src;
        if (!imageUrl.includes("https")) return imageUrl;
        return getImageFromProxy(imageUrl);
      })
    );

    setBlobs(imageUrls);
    _setIsLoading(false);
    onLoadingEnd && onLoadingEnd();
  }

  const style = {
    backgroundSize: "cover",
    backgroundColor: color?.hex,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    margin: 0,
    overflow: "hidden",
  };

  return (
    <div className={css(styles.root)}>
      <div className="cube" style={{ marginRight: 7, height: 156.5 }}>
        <figure
          className="side top"
          style={{ backgroundImage: `url(${blobs[1]})`, ...style }}
        >
          {_isLoading && (
            <EthLoader isLoading={_isLoading} style={styles.ethIcon} />
          )}
        </figure>
        <figure
          className="side left"
          style={{ backgroundImage: `url(${blobs[0]})`, ...style }}
        >
          {_isLoading && (
            <EthLoader isLoading={_isLoading} style={styles.ethIcon} />
          )}
        </figure>
        <figure
          className="side right"
          style={{ backgroundImage: `url(${blobs[2]})`, ...style }}
        >
          {_isLoading && (
            <EthLoader isLoading={_isLoading} style={styles.ethIcon} />
          )}
        </figure>
      </div>
    </div>
  );
};

const styles = StyleSheet.create({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    opacity: 1,
    //remove extra white space from transform properties

    marginTop: 5,
  },
  loading: {
    opacity: 0.6,
  },
  ethIcon: {
    marginTop: 20,
    width: 25,
  },
});

export default AvatarHeadStatic;
