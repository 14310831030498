import { StyleSheet, css } from "aphrodite";
import { Colors } from "../utils/colors";


const HyperLink = ({ href, style, children }) => {

  return (
    <a href={href} rel="noopener noreferrer" target="_blank" className={css(styles.hyperLink, style && style)}>
      {children}
    </a>
  )
};

const styles = StyleSheet.create({
  hyperLink: {
    color: Colors.blue(),
    textDecoration: "unset",
    cursor: "pointer",
    ":hover": {
      cursor: "pointer",
      textDecoration: "underline"
    }
  }
});

export default HyperLink;