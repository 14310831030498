import { StyleSheet, css } from "aphrodite";
import { Colors } from "../utils/colors";
import { Link } from "react-router-dom";

import Text from "./Text";
import Loader from "./SpinLoader";

import arrowRightWhite from "../assets/arrow-right-white.png";
import arrowRightDark from "../assets/arrow-right-dark.png";

const Button = ({
  label,
  style,
  onClick,
  showBlackArrow,
  buttonStyles,
  noArrow,
  labelStyles,
  href,
  forceHref,
  to,
  type = "button",
  isLoading = false,
  disabled = false,
}) => {
  const isDisabled = disabled || isLoading;

  function handleOnClick(e) {
    e && e.stopPropagation();
    if (isDisabled) return;
    onClick && onClick();
  }

  const buttonLabel = isDisabled ? (
    <Loader isLoading={true} height={30} width={30} color={Colors.charcoal()} />
  ) : (
    <Text label={label} style={(styles.label, labelStyles)} />
  );

  return (
    <div
      className={css(
        styles.buttonContainer,
        isDisabled && styles.disabled,
        style && style
      )}
      onClick={handleOnClick}
    >
      {to ? (
        <Link
          to={to}
          className={css(
            styles.button,
            styles.linkStyles,
            buttonStyles,
            disabled && styles.disabled
          )}
        >
          {buttonLabel}
        </Link>
      ) : href || forceHref ? (
        <a
          href={href}
          target="_blank"
          rel="noreferrer"
          className={css(
            styles.button,
            styles.linkStyles,
            buttonStyles,
            disabled && styles.disabled
          )}
        >
          {buttonLabel}
        </a>
      ) : (
        <button
          disabled={disabled}
          className={css(styles.button, buttonStyles)}
          id={"button"}
          type={type}
        >
          <Text label={label} style={(styles.label, labelStyles)} />
        </button>
      )}
      {!noArrow && (
        <img
          id={"arrow"}
          src={showBlackArrow ? arrowRightDark : arrowRightWhite}
          alt={"arrow-right"}
          className={css(styles.arrowIcon)}
        />
      )}
    </div>
  );
};

const styles = StyleSheet.create({
  buttonContainer: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    ":hover #arrow": {
      opacity: 0.7,
    },
  },
  linkStyles: {
    textDecoration: "none",
    color: "#111",
    padding: "8px 16px",
  },
  button: {
    background: Colors.lime(),
    textTransform: "uppercase",
    width: "max-content",
    borderWidth: 1,
    borderColor: Colors.lime(),
    // borderStyle: "outset",
    borderStyle: "solid",
    // borderTop: "none",
    // borderLeft: "none",
    transition: "all ease-out 0.1s",
    zIndex: 2,
    fontSize: 20,
    fontWeight: 700,
    letterSpacing: 1,
    cursor: "pointer",
    ":hover": {
      borderColor: Colors.charcoal(),
      background: Colors.lime(0.8),
    },
  },
  label: {
    color: Colors.charcoal(),
    fontWeight: "bold",
    fontFamily: "GT Flexa Trial",
    "@media only screen and (max-width: 767px)": {
      fontSize: 14,
    },
  },
  arrowIcon: {
    width: "min-content",
    marginLeft: 20,
    cursor: "pointer",
    "@media only screen and (max-width: 767px)": {
      marginLeft: 10,
      width: 40,
    },
  },
  disabled: {
    opacity: 0.5,
    pointerEvents: "none",
    cursor: "not-allowed",
  },
});

export default Button;
