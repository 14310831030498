import dayjs from "dayjs";

// Assets
import StandardFrame from "../assets/tiers/standard/frame.png";
import StandardOutline from "../assets/tiers/standard/outline.png";
import StandardBackground from "../assets/tiers/standard/background.png";

import GoldFrame from "../assets/tiers/gold/frame.png";
import GoldOutline from "../assets/tiers/gold/outline.png";
import GoldBackground from "../assets/tiers/gold/background.png";

import DiamondFrame from "../assets/tiers/diamond/frame.png";
import DiamondOutline from "../assets/tiers/diamond/outline.png";
import DiamondBackground from "../assets/tiers/diamond/background.png";

import squareOne from "../assets/square-one.png";
import squareTwo from "../assets/square-two.png";
import squareThree from "../assets/square-three.png";

import pyramidOne from "../assets/pyramid-one.png";

import hand from "../assets/icons/hand-black.png";
import pants from "../assets/icons/pants-black.png";
import shirt from "../assets/icons/shirt-black.png";
import smile from "../assets/icons/smile-black.png";
import ethIcon from "../assets/eth-gray.svg";

export const OPENING_DATE = new Date("March 1, 2022 12:00:00-08:00").getTime();
export const CLOSING_DATE = new Date("March 10 2022 21:00:00-08:00").getTime();
const zone = new Date()
  .toLocaleTimeString("en-us", { timeZoneName: "short" })
  .split(" ")[2];
export const FORMATTED_OPENING_DATE =
  dayjs(OPENING_DATE).format("dddd, MMMM D, h:00a") + ` ${zone}`;
export const FORMATTED_CLOSING_DATE =
  dayjs(CLOSING_DATE).format("dddd, MMMM D, h:00a") + ` ${zone}`;
export const ENS_CONTRACT = "0x57f1887a8bf19b14fc0df6fd9b2acc9af147ea85";

const ATTRIBUTE_DICT = {
  "shirt-1": "yellow",
  "shirt-2": "amber",
  "shirt-3": "orange",
  "shirt-4": "red",
  "shirt-5": "dark-pink",
  "shirt-6": "magenta",
  "shirt-7": "violet",
  "shirt-8": "blue",
  "shirt-9": "charcoal",
  "shirt-10": "white",
  "hands-1": "teal",
  "hands-2": "turquoise",
  "hands-3": "green",
  "hands-4": "lime",
  "hands-5": "yellow",
  "hands-6": "orange",
  "hands-7": "dark-pink",
  "hands-8": "purple",
  "hands-9": "charcoal",
  "hands-10": "white",
  "pants-1": "navy",
  "pants-2": "turquoise",
  "pants-3": "green",
  "pants-4": "lime",
  "pants-5": "orange",
  "pants-6": "red",
  "pants-7": "dark-pink",
  "pants-8": "purple",
  "pants-9": "charcoal",
  "pants-10": "white",
  "face-1": "angry",
  "face-2": "handsome",
  "face-3": "devious",
  "face-4": "happy",
  "face-5": "mysterious",
  "face-6": "sad",
  "face-7": "content",
  "face-8": "cool",
  "face-9": "x",
};

export const DEFAULT_SHIRT = {
  id: "shirt-1",
  front: "/avatar/front/shirts/Front_Shirt1.png",
  back: "/avatar/back/shirts/Back_Shirt1.png",
  value: ATTRIBUTE_DICT["shirt-1"],
  type: "shirt",
};
export const DEFAULT_PANTS = {
  id: "pants-1",
  front: "/avatar/front/pants/Front_Pants1.png",
  back: "/avatar/back/pants/Back_Pants1.png",
  value: ATTRIBUTE_DICT["pants-1"],
  type: "pants",
};
export const DEFAULT_HANDS = {
  id: "hands-1",
  front: "/avatar/front/hands/Front_Hand1.png",
  back: "/avatar/back/hands/Back_Hand1.png",
  value: ATTRIBUTE_DICT["hands-1"],
  type: "hands",
};
export const DEFAULT_COLOR = {
  hex: "#ffffff",
  hsv: { h: 0, s: 0.010279605263157894, v: 100, a: undefined },
  rgb: { r: 255, g: 255, b: 255, a: undefined },
};
export function DEFAULT_FACES() {
  return AVATAR_FACES().slice(3, 6);
}

export function AVATAR_SHIRTS() {
  return new Array(10).fill(0).map((_, i) => ({
    id: `shirt-${i + 1}`,
    front: `/avatar/front/shirts/Front_Shirt${i + 1}.png`,
    back: `/avatar/back/shirts/Back_Shirt${i + 1}.png`,
    value: ATTRIBUTE_DICT[`shirt-${i + 1}`],
    type: "shirt",
  }));
}

export function AVATAR_PANTS() {
  return new Array(10).fill(0).map((_, i) => ({
    id: `pants-${i + 1}`,
    front: `/avatar/front/pants/Front_Pants${i + 1}.png`,
    back: `/avatar/back/pants/Back_Pants${i + 1}.png`,
    value: ATTRIBUTE_DICT[`pants-${i + 1}`],
    type: "pants",
  }));
}

export function AVATAR_HANDS() {
  return new Array(10).fill(0).map((_, i) => ({
    id: `hands-${i + 1}`,
    front: `/avatar/front/hands/Front_Hand${i + 1}.png`,
    back: `/avatar/back/hands/Back_Hand${i + 1}.png`,
    value: ATTRIBUTE_DICT[`hands-${i + 1}`],
    type: "hands",
  }));
}

export function AVATAR_FACES() {
  return new Array(9).fill(0).map((_, i) => ({
    id: `face-${i + 1}`,
    src: `/avatar/faces/face${i + 1}.png`,
    value: ATTRIBUTE_DICT[`face-${i + 1}`],
    type: "face",
  }));
}

export const SELECTORS = [
  {
    icon: ethIcon,
    label: "My NFTs",
    type: "nfts",
    style: { height: 28 },
  },
  {
    icon: smile,
    label: "Faces",
    type: "faces",
    style: { width: 23, height: 23 },
  },
  {
    icon: shirt,
    label: "Shirts",
    type: "shirts",
    style: { width: 24, height: 21 },
  },
  {
    icon: hand,
    label: "Hands",
    type: "hands",
    style: { width: 18, height: 21 },
  },
  {
    icon: pants,
    label: "Pants",
    type: "pants",
    style: { width: 13, height: 30 },
  },
];

export const MEMBERSHIP_LEVELS = [
  {
    id: "member",
    assets: {
      background: StandardBackground,
      outline: StandardOutline,
      frame: StandardFrame,
    },
    callout: "Unlimited tokens avail. until 3/10/22",
    icon: squareOne,
    perkIcon: pyramidOne,
    label: "Level 1",
    tier: "Standard",
    description: "Member",
    text: "Members in the Lobby3 DAO have a voice in all organized lobbying and advocacy efforts on Capitol Hill. They can vote on policy recommendations and DAO treasury spending.",
    price: "0.07",
    priceInt: 0.07,
    perks: [
      [
        "Discord Access",
        "Access to the member discord, where we will gather like-minded innovators and leaders in the Web3 space",
      ],
      [
        "Voting Rights",
        "One token = one vote on community issues, including policy priorities, speakers and legislators to target, and resource allocation",
      ],
      [
        "Status Updates",
        "Updates on lobbying efforts from Andrew Yang directly, as well as lobbying and policy experts in the trenches. Targeting 1-2 per month.",
      ],
      [
        "AMAs w/ Andrew Yang",
        "Frequent Ask-Me-Anything or various Q&A events with Andrew and others leading the community",
      ],
      [
        "Policy Proposals",
        "Members will have the opportunity to propose policy suggestions to DAO leadership and lobbying experts, prioritizing the topics of conversation within this community",
      ],
      [
        "Events & Merch",
        "Early access and discounted rates on community events, merchandise, and other perks",
      ],
    ],
  },
  {
    id: "advocate",
    assets: {
      background: GoldBackground,
      outline: GoldOutline,
      frame: GoldFrame,
    },
    icon: squareTwo,
    perkIcon: pyramidOne,
    label: "Level 2",
    callout: "Only 2000 available",
    tier: "Gold",
    description: "Advocate",
    text: "Advocates in the Lobby3 DAO will be considered some of the leaders of the community, and will have the ability to drive the direction, policy priorities, and voice of our Web3 lobbying community.",
    price: "1",
    priceInt: 1,
    perks: [
      ["All Level 1 Perks"],
      [
        "Exclusive Leadership Access",
        "Advocates will be invited to monthly virtual (and when possible, in-person) meetings with elected officials, lobbying experts, and Web3 leaders to ask questions and help shape the policy priorities of the community",
      ],
      [
        "Policy Proposal Approval",
        "Advocates will be able to review all policy proposals from the Lobby3 DAO and determine which should be brought to a vote among the broader DAO",
      ],
      [
        "VIP Event Access",
        "Advocate token holders will have free access to exclusive in-person events the Lobby3 DAO will host, including an invitation to the first Andrew Yang Web3 VIP event at Bitcoin 2022 in April",
      ],
      [
        "Exclusive Merch & NFTs",
        "All Advocates will receive an exclusive pin upon purchase (provided you are willing to provide us a mailing address), in addition to future AirDrops from various community members",
      ],
      [
        "Website Recognition",
        "Advocate token holders will have their names (or aliases) on the Lobby3 website as founding members",
      ],
    ],
  },
  {
    id: "founder",
    assets: {
      background: DiamondBackground,
      outline: DiamondOutline,
      frame: DiamondFrame,
    },
    icon: squareThree,
    perkIcon: pyramidOne,
    label: "Level 3",
    tier: "Diamond",
    callout: "Only 100 available",
    description: "Founder",
    text: "Founders put major resources to work and have eyes on the day-to-day decision making and operations of the lobbying efforts.\n\n",
    price: "40",
    priceInt: 40,
    perks: [
      ["All Level 1 & 2 Perks"],
      [
        "One-on-one access to Andrew Yang and His Team",
        "Founders will become part of a group of 100 leaders in the Web3 space, interacting directly with Andrew Yang and the lobbying team",
      ],
      [
        "Andrew Yang Event Attedance / Participation",
        "The group will vote to bring Andrew Yang as a keynote speaker and/or attendee to five events of their choice per year (subject to scheduling and minimal exceptions). These can include casual meals, small roundtables, or formal keynote speeches",
      ],
      [
        "VIP Intimate Access Group Meetings",
        "Off-the-record and intimate group meetings with influential law makers and decision makers, with your voice and participation directly shaping and informing Web3 legislative efforts",
      ],
      [
        "Exclusive Network",
        "Join a network of 100 other Web3 influencers and players in the space with an aligned mission",
      ],
    ],
  },
];

export const ETH_GAS_INFO_LINK =
  "https://ethereum.org/en/developers/docs/gas/#what-is-gas";
