// Npm
import { StyleSheet, css } from "aphrodite";
import { Colors } from "../../utils/colors";

// Component
import pixelDesignTwo from "../../assets/pixel-design-two.png";
import Text from "../Text";
import Button from "../Button";
import Wrapper from "../Wrapper";
import ComingSoonModal from "../Modals/ComingSoonModal";
import { useState } from "react";

const Invitation = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Wrapper>
      <div className={css(styles.container)}>
        <div className={css(styles.content)}>
          <Text type={"h1"} style={styles.title}>
            {
              "Members of the Lobby3 community will quite literally shape the future… Together."
            }
          </Text>
          <Button
            label={"Become a Member"}
            showBlackArrow={true}
            to={"/mint/membership"}
            // onClick={() => setIsOpen(true)}
          />
          <ComingSoonModal
            isOpen={isOpen}
            closeModal={() => {
              setIsOpen(false);
            }}
          />
          <Text type={"p"} style={styles.text}>
            For press inquiries, contact us at press@lobby3.io
          </Text>
        </div>

        <img
          className={css(styles.pixelDesign)}
          src={pixelDesignTwo}
          alt="pixel-design"
          draggable={false}
        />
      </div>
    </Wrapper>
  );
};

const styles = StyleSheet.create({
  container: {
    background: "#fff",
    display: "flex",
    justifyContent: "space-between",
    alignItem: "flex-start",
    padding: "80px 160px",
    minHeight: 500,
    width: "100%",
    boxSizing: "border-box",
    position: "relative",
    maxWidth: 1750,
    "@media only screen and (max-width: 767px)": {
      padding: 40,
    },
  },
  content: {
    maxWidth: 800,
    zIndex: 2,
    "@media only screen and (max-width: 767px)": {
      width: "100%",
    },
  },
  text: {
    marginTop: 16,
  },
  contentRight: {},
  title: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    whiteSpace: "pre-wrap",
    color: Colors.charcoal(),
    padding: "20px 0",
  },
  body: {
    color: Colors.charcoal(),
    padding: "15px 0",
    width: 700,
    "@media only screen and (max-width: 767px)": {
      width: "100%",
    },
  },
  pixelDesign: {
    position: "absolute",
    bottom: 0,
    right: 0,
    width: 400,
    "@media only screen and (max-width: 767px)": {
      width: 200,
    },
  },
});

export default Invitation;
