// Npm
import { StyleSheet, css } from "aphrodite";
import { useNavigate } from "react-router";

// Components
import Banner from "./Banner";
import Header from "./Header";
import PoapModal from "./Modals/PoapModal";
import NavMenuMobile from "./NavMenuMobile";
import About from "./sections/About";
import FAQ from "./sections/FAQ";
import HowItWorks from "./sections/HowItWorks";
import Invitation from "./sections/Invitation";
import Membership from "./sections/Membership";
import Puzzle from "./sections/Puzzle";
import Roadmap from "./sections/Roadmap";
import Team from "./sections/Team";

const PoapScreen = ({ isMobileDimension }) => {
  const navigate = useNavigate();

  return (
    <>
      <PoapModal
        isOpen={true}
        closeModal={() => {
          navigate("/");
        }}
      />
      {isMobileDimension && <NavMenuMobile />}
      <Banner />
      <Header isMobile={isMobileDimension} />
      <Puzzle />
      <About />
      <HowItWorks />
      <Membership />
      <Roadmap />
      <FAQ />
      <Team />
      <Invitation />
    </>
  );
};

const styles = StyleSheet.create({
  container: {},
});

export default PoapScreen;
