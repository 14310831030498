// Npm
import { Fragment } from "react";
import { StyleSheet, css } from "aphrodite";
import { Colors } from "../utils/colors";

// Component

import Text from "./Text";
import Button from "./Button";

const MembershipLevel = ({
  level,
  index,
  isMobileDimension,
  onClick,
  href,
  to,
}) => {
  const { description, price, tier, text, perks, icon } = level;

  return (
    <div
      className={css(styles.membership, index === 2 && styles.noPaddingRight)}
    >
      <div className={css(styles.membershipTitle)}>
        <img src={icon} className={css(styles.square)} alt={"square"} />
        <Text type={"h2"} label={tier} />
      </div>
      <div className={css(styles.membershipCard)}>
        <div className={css(styles.header)}>
          <Text type={"h3"} label={description} />
          <Text type={"h3"} label={price} />
        </div>
        <Text style={styles.memberDescription} label={text} />
        {perks.map((perk, i) => {
          const isLast = i === perks.length - 1;
          return (
            <Fragment key={`membership-${level}-perk-${i}`}>
              <div
                className={css(
                  styles.perk,
                  isLast && styles.removeBorderBottom
                )}
              >
                <div className={css(styles.grayShade)} />
                <Text style={styles.perkLabel} label={perk[0]} />
              </div>
              <div className={css(styles.inner)}>
                <Text
                  style={[styles.perkLabel, styles.innerPerkLabel]}
                  label={perk[1]}
                />
              </div>
            </Fragment>
          );
        })}
        <Button
          label={
            index === 2
              ? "Request an invite"
              : `Become ${index === 1 ? "an" : "a"} ${description}`
          }
          showBlackArrow={true}
          style={styles.button}
          noArrow={isMobileDimension}
          onClick={onClick}
          href={href}
          to={to}
        />
      </div>
    </div>
  );
};

const styles = StyleSheet.create({
  container: {
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    padding: "80px 160px 0 0",
    maxWidth: 1750,
    "@media only screen and (max-width: 767px)": {
      padding: 40,
      paddingLeft: 0,
    },
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    aligItems: "center",
    padding: "35px 0",

    "@media only screen and (max-width: 767px)": {
      flexDirection: "column",
    },
  },
  tiers: {
    overflow: "auto",
    whiteSpace: "nowrap",
    maxWidth: 1750,
    margin: "0 auto",
    padding: "0px 40px 10px 40px",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "flex-start",

    "@media only screen and (max-width: 767px)": {
      width: "100%",
    },
  },
  square: {
    height: 50,
    marginRight: 10,
    "@media only screen and (max-width: 767px)": {
      height: 30,
    },
  },
  row: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 16,
  },
  membership: {
    display: "inline-block",
    position: "relative",
    paddingRight: 30,
    minWidth: 500,
    maxWidth: 800,
    width: "calc(100% / 3)",
    "@media only screen and (max-width: 767px)": {
      width: "unset",
      minWidth: "90%",
      padding: 10,
    },
  },
  membershipCard: {
    border: "1px solid black",
    display: "flex",
    flexDirection: "column",
    padding: "0 40px",
    boxSizing: "border-box",
    width: "100%",
    background: "#fff",
    minHeight: 1050,
    "@media only screen and (max-width: 767px)": {
      minHeight: 680,
      padding: "0 16px",
    },
    "@media only screen and (min-width: 1700px)": {
      height: 950,
      minHeight: "unset",
    },
  },
  perk: {
    display: "flex",
    justifyContent: "space-between",
    aligItems: "center",
    padding: "15px 0",
    position: "relative",
    width: "100%",
    overflow: "hidden",
  },
  grayShade: {
    height: 20,
    width: 35,
    background: Colors.gray(),
    position: "absolute",
    left: 0,
    top: 0,
  },
  button: {
    // position: "absolute",
    paddingTop: 20,
    marginTop: "auto",
    marginBottom: 20,
    left: 40,
    bottom: 20,
    "@media only screen and (max-width: 767px)": {
      left: 60,
      bottom: 40,
    },
  },
  memberDescription: {
    width: "100%",
    overflowWrap: "anywhere",
    hyphens: "auto",
    whiteSpace: "pre-wrap",
    boxSizing: "border-box",
    paddingBottom: 25,
    fontFamily: "GT Flexa Trial",
  },
  perkLabel: {
    width: "100%",
    overflowWrap: "anywhere",
    hyphens: "auto",
    whiteSpace: "pre-wrap",
    boxSizing: "border-box",
    paddingLeft: 15,
    marginTop: -10,
    zIndex: 2,
  },
  innerPerkLabel: {
    fontSize: 15,
    opacity: 0.8,
  },
  removeBorderBottom: {
    borderBottom: "unset",
    // paddingBottom: 40,
  },
  checkCircle: {
    height: 25,
    width: 25,
    minWidth: 25,
    maxWidth: 25,
  },
  membershipTitle: {
    display: "flex",
    alignItems: "center",
  },
  title: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    whiteSpace: "pre-wrap",
    padding: "20px 0",
    paddingLeft: 160,
    "@media only screen and (max-width: 767px)": {
      paddingLeft: 40,
    },
  },
  subtitle: {
    marginBottom: 20,
    paddingLeft: 160,
    "@media only screen and (max-width: 767px)": {
      paddingLeft: 40,
    },
  },
  membershipContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    padding: 50,
    boxSizing: "border-box",
    border: "1px solid black",
    marginTop: 25,
    cursor: "pointer",
    ":hover": {
      background: Colors.blue(0.1),
    },
  },
  icon: {
    width: 25,
    cursor: "pointer",
  },
  label: {
    fontSize: 20,
    fontWeight: "bold",
    padding: "0 15px 0 20px",
    borderRight: "0.5px solid rgba(0, 0, 0, 0.1)",
  },
  description: {
    paddingLeft: 15,
  },
  price: {
    fontSize: 20,
    fontWeight: "bold",
    color: Colors.blue(),
  },
  summary: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    boxSizing: "border-box",
  },
  summaryLeft: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },

  arrowUp: {
    transform: "rotate(180deg)",
  },
  inner: {
    marginLeft: 16,
    marginBottom: 8,
  },
  arrowDown: {},
});

export default MembershipLevel;
