import { StyleSheet, css } from "aphrodite";
import ReactTooltip from "react-tooltip";

import { Colors } from "../../utils/colors";


const Selector = ({ button, index, activeIndex, onSelect }) => {
  const {icon, style, type, label } = button;

  const isActive = index == activeIndex;

  function handleSelect(e) {
    e && e.preventDefault();
    e && e.stopPropagation();
    // pass up the active Index
    onSelect && onSelect(index);
  }

  return (
    <div 
      className={css(styles.container, isActive && styles.active)} 
      onClick={handleSelect}
      data-for={`avatar-${type}-selector`}
      data-tip
      data-iscapture
    >
      <img src={icon} className={css(styles.icon)} style={style} />
      <ReactTooltip
        id={`avatar-${type}-selector`}
        getContent={() => label}
      />
    </div>
  )
}


const styles = StyleSheet.create({
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "100%",
    height: 50,
    width: 50,
    background: Colors.gray(),
    cursor: "pointer",
    marginBottom: 20,
    transition: 'all ease-out 0.1s',
    ":hover": {
      background: Colors.lime(),
      boxShadow: '0 0 10px rgba(255, 255, 255, 0.7)',
    }
  },
  active: {
    background: Colors.lime(),
    boxShadow: '0 0 10px rgba(255, 255, 255, 0.7)',
  },
  icon: {
    // objectFit
  }
});

export default Selector;