// Npm
import { StyleSheet, css } from "aphrodite";
import { Colors } from "../utils/colors";

// Components
import Text from "./Text";
import Wrapper from "./Wrapper";
import NavMenu from "./NavMenu";

import logo from "../assets/L3_Logo_Col.webp";
import { Link } from "react-router-dom";

const Header = ({ isMobile }) => {
  return (
    <Wrapper background={Colors.charcoal()}>
      <div className={css(styles.container)}>
        <div className={css(styles.left)}>
          <Link to="/">
            <img src={logo} className={css(styles.logo)} alt="Lobby3" />
          </Link>
        </div>
        <div className={css(styles.row)}>
          <div className={css(styles.center)}>
            <Text
              type={"h1"}
              style={styles.title}
              label={"Welcome to Lobby3"}
            />
            <Text
              style={styles.label}
              label={"Bringing the voices of Web3 to Washington DC."}
            />
          </div>
          <div className={css(styles.right, isMobile && styles.hide)}>
            <NavMenu />
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

const styles = StyleSheet.create({
  container: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    width: "100%",
    background: Colors.charcoal(),
    color: Colors.gray(),
    padding: "20px 30px",
    boxSizing: "border-box",
    maxWidth: 1750,
  },
  logo: {
    height: 100,
  },
  left: {
    textAlign: "left",
    width: 130,
    paddingTop: 10,
    "@media only screen and (max-width: 767px)": {
      display: "none",
    },
  },
  button: {
    padding: "8px 60px",
  },
  labelStyles: {
    fontSize: 25,
  },
  row: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
    width: "calc(100% - 130px)",
    "@media only screen and (max-width: 767px)": {
      width: "100%",
    },
  },
  center: {
    width: "max-content",
  },
  right: {
    width: "max-content",
  },
  hide: {
    display: "none",
  },
  title: {
    fontWeight: "bold",
    paddingBottom: 15,
    fontFamily: "GT Flexa Expanded",
  },
  label: {
    paddingBottom: 15,
    fontFamily: "GT Flexa Lazer",
    fontSize: 30,
    color: "#EBEBEB",
  },
});

export default Header;
