import { css, StyleSheet } from "aphrodite";
import { Fragment } from "react";
import { Oval } from "react-loader-spinner";

import { Colors } from "../utils/colors";

const SpinLoader = ({ 
  isLoading = true, 
  height = 80, 
  width = 80, 
  inline = true, 
  color = Colors.purple(),
  timeout
}) => {
  return (
    <Fragment>
      {isLoading && (
        <span className={css(styles.container, inline && styles.inline)}>
          <Oval
            color={color} 
            height={height} 
            width={width}
            visible={isLoading}
            // timeout={3000} //3 secs
          />
        </span>
      )}
    </Fragment>
  );
};

const styles = StyleSheet.create({
  container: {
    display: "flex",
    padding: 3
  },
  inline: {
    display: "inline-block"
  },
  loaderContainer: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    height: "100%",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    zIndex: 2
  }
})

export default SpinLoader;