// Npm
import { StyleSheet, css } from "aphrodite";
import { useEffect, useState } from "react";

import ReactSelect from "react-select";

// Components
import Text from "./Text";

// Utils
import { Colors } from "../utils/colors";

const Select = ({ values, onChange, menuStyle = {}, controlStyle = {} }) => {
  const [_options, _setOptions] = useState([]);

  useEffect(() => {
    _generateOptions();
  }, [values]);

  function _generateOptions() {
    const options = values.map((value, id) => {
      let label = id;
      if (value) {
        label = value[0].toUpperCase() + value.slice(1);
      }
      return { value, label, id };
    });

    _setOptions(options);
  }

  function _onChange(selectedOption) {
    onChange && onChange(selectedOption);
  }

  const menu = {
    background: Colors.gray(),
    color: Colors.charcoal(),
    fontSize: 18,
    fontFamily: "Poppins",
    fontWeight: 500,
    "@media only screen and (max-width: 767px)": {
      fontSize: 16,
    },
    ...menuStyle,
  };

  const control = {
    display: "flex",
    width: "100%",
    background: Colors.gray(),
    cursor: "pointer",
    minWidth: 300,
    fontSize: 18,
    fontFamily: "Poppins",
    fontWeight: 500,
    ...controlStyle,
  };

  const customStyles = {
    menu: (provided, state) => ({
      ...provided,
      ...menu,
    }),

    control: (_, { selectProps: { width } }) => ({
      ...control,
    }),

    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = "opacity 300ms";

      return { ...provided, opacity, transition };
    },
  };

  return (
    <ReactSelect
      onChange={_onChange}
      defaultValue={_options[0]}
      options={_options}
      styles={customStyles}
    />
  );
};

export default Select;
