import { StyleSheet, css } from "aphrodite";
import Stepper from "react-stepper-horizontal";

// Components
import Wrapper from "../../Wrapper";

// Config
import { Colors } from "../../../utils/colors";

const MintFlowTimeline = ({ page, setPage, isMobileDimension }) => {
  const STEPS = [
    {
      title: "Select Your Membership",
      onClick: (e) => handleStepClick(e, 0),
    },
    {
      title: "Mint Your Token",
      onClick: (e) => handleStepClick(e, 0), // take user to tier selection
    },
    {
      title: "Membership Summary",
      onClick: (e) => handleStepClick(e, 2),
    },
    {
      title: "Customize Your Avatar",
      onClick: (e) => handleStepClick(e, 3),
    },
    {
      title: "Save Your Avatar",
      onClick: (e) => handleStepClick(e, 4),
    },
  ];

  function handleStepClick(e, index) {
    setPage(index);
  }

  return (
    <Wrapper background={Colors.charcoal()}>
      <div className={css(styles.timeline)}>
        <Stepper
          activeStep={page}
          steps={STEPS}
          // circle bg color
          defaultColor={Colors.gray()}
          activeColor={Colors.lime()}
          completeColor={Colors.lime()}
          // border
          defaultBorderColor={Colors.lightCharcoal()}
          // bar
          completeBarColor={Colors.lime()}
          // title Color
          defaultTitleColor={Colors.gray()}
          activeTitleColor={Colors.lime()}
          completeTitleColor={Colors.lime()}
          circleFontColor={Colors.charcoal()}
          titleFontSize={isMobileDimension ? 10 : 14}
        />
      </div>
    </Wrapper>
  );
};

const styles = StyleSheet.create({
  timeline: {
    width: "100%",
    maxWidth: 2060,
    margin: "0 auto",
    padding: 0,
    marginTop: -10,
    background: Colors.charcoal(),
    color: Colors.gray(),
    cursor: "pointer",
    userSelect: "none",
  },
});

export default MintFlowTimeline;
