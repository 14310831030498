// Npm
import { useState, useEffect, Fragment } from "react";
import { StyleSheet, css } from "aphrodite";

// Components
import Text from "../../Text";
import Wrapper from "../../Wrapper";
import Select from "../../Select";
import AvatarGrid from "./AvatarGrid";
import Loader from "../../SpinLoader";

// Utils
import { Colors } from "../../../utils/colors";

const UserSummary = ({
  userTokens,
  isFetchingUserTokens,
  chosenTokensHash,
  onTokenClick,
}) => {
  const [filterOptions, setFilterOptions] = useState(["all"]);
  const [activeFilterOption, setActiveFilterOption] = useState("all");

  useEffect(() => {
    _formatFilterOptions(userTokens);
  }, [userTokens]);

  function _formatFilterOptions(userTokens) {
    const options = userTokens.map((userToken) => userToken.name);
    setFilterOptions(["all", ...options]);
  }

  function _handleFilterChange(filter) {
    setActiveFilterOption(filter?.value);
  }

  function _renderTitle() {
    return (
      <Fragment>
        {"My Lobby3 Avatars ("}
        {isFetchingUserTokens ? (
          <Loader
            color={"#FFF"}
            isLoading={true}
            height={15}
            width={15}
            inline={true}
          />
        ) : (
          userTokens.length
        )}
        {")"}
      </Fragment>
    );
  }

  return (
    <Wrapper background={Colors.charcoal()} style={styles.wrapper}>
      <div className={css(styles.root)}>
        <div className={css(styles.header)}>
          <Text type={"h3"} style={styles.title} label={_renderTitle()} />
        </div>
        <Text style={styles.text}>
          Select the avatar you’d like to customize by clicking on your avatar
          below. You can select multiple at a time (a box will check in the top
          right corner), but know that they will all get the same customization
          you choose in the next screen. It may take a few minutes to fully load
          your avatar.
        </Text>
        {/* <div style={{ marginLeft: "auto", maxWidth: 400, marginBottom: 16 }}>
          <Select values={filterOptions} onChange={_handleFilterChange} />
        </div> */}
        <div className={css(styles.container)}>
          <AvatarGrid
            userTokens={userTokens}
            chosenTokensHash={chosenTokensHash}
            isFetching={isFetchingUserTokens}
            activeFilterOption={activeFilterOption}
            onTokenClick={onTokenClick}
          />
        </div>
      </div>
    </Wrapper>
  );
};

const styles = StyleSheet.create({
  wrapper: {},
  root: {
    width: "100%",
    maxWidth: 1200,
    padding: 30,
    paddingBottom: 25,
    "@media only screen and (max-width: 767px)": {
      paddingBottom: 0,
    },
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "space-between",
    width: "100%",
    fontFamily: "Poppins",
    marginBottom: 15,
    "@media only screen and (max-width: 767px)": {
      flexDirection: "column",
    },
  },
  container: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    width: "100%",
    background: Colors.medCharcoal(),
    color: Colors.gray(),
    padding: "20px 30px",
    boxSizing: "border-box",
    border: `1px solid ${Colors.gray()}`,
    "@media only screen and (max-width: 767px)": {
      padding: 10,
    },
  },

  button: {
    padding: "8px 60px",
  },
  labelStyles: {
    fontSize: 25,
  },
  row: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
    width: "100%",
    margin: "20px 0",
  },
  center: {
    width: "max-content",
  },
  right: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "flex-start",
    width: "max-content",
  },
  hide: {
    display: "none",
  },
  title: {
    display: "inline-block",
    clear: "both",
    overflow: "hidden",
    whiteSpace: "nowrap",
    fontWeight: "bold",
    paddingBottom: 15,
    color: Colors.gray(),
    fontFamily: "GT Flexa Trial",
  },
  text: {
    color: Colors.gray(),
    // width: "70%",
    lineHeight: 1.4,
    paddingBottom: 20,
    "@media only screen and (max-width: 767px)": {
      width: "100%",
    },
  },
  separator: {
    marginBottom: 12,
  },
  label: {
    textTransform: "uppercase",
    color: Colors.gray(),
    fontFamily: "GT Flexa Trial",
  },
  button: {
    padding: "20px 10px",
    boxSizing: "border-box",
  },
});

export default UserSummary;
