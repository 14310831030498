// NPM Modules
import { StyleSheet, css } from "aphrodite";
import Modal from "react-modal";

import "./Stylesheets/Modal.module.css";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    padding: 0,
  },
  overlay: {
    background: "rgba(0,0,0, .7)",
    zIndex: 100000000000,
  },
};

Modal.setAppElement("#root");

function BaseModal({
  closeModal,
  isOpen,
  shouldCloseOnOverlayClick,
  modalStyle,
  children,
  whiteCloseIcon,
}) {
  /**
   * closes the modal on button click
   */
  const closeModalInternal = () => {
    closeModal && closeModal();
  };

  return (
    <Modal
      isOpen={isOpen}
      closeModal={closeModalInternal}
      onRequestClose={closeModalInternal}
      className={css(styles.modal, modalStyle)}
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
      ariaHideApp={false}
      style={customStyles}
    >
      <div className={css(styles.modalContent)}>
        <div
          className={css(styles.closeButtonWrapper)}
          onClick={closeModalInternal}
        >
          <i
            className={
              css(styles.closeIcon, whiteCloseIcon && styles.whiteCloseIcon) +
              " fa-solid fa-xmark fa-2x"
            }
          ></i>
        </div>
        {children}
      </div>
    </Modal>
  );
}

const styles = StyleSheet.create({
  modal: {
    background: "#fff",
    outline: "none",
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
    display: "flex",
    flexDirection: "column",
    borderRadius: 5,
    boxSizing: "border-box",
    boxShadow: " 0px 2px 4px rgba(185, 185, 185, 0.25)",
    "@media only screen and (max-width: 665px)": {
      width: "90%",
    },
    "@media only screen and (max-width: 436px)": {
      width: "100%",
      height: "100%",
      top: 0,
      left: 0,
      transform: "unset",
    },
  },
  modalContent: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    position: "relative",
    backgroundColor: "#fff",
    // padding: 50,
    borderRadius: 5,
    transition: "all ease-in-out 0.4s",
    boxSizing: "border-box",
  },
  removeDefault: {
    padding: 0,
    "@media only screen and (max-width: 767px)": {
      padding: 0,
    },
    "@media only screen and (max-width: 415px)": {
      padding: 0,
    },
  },
  backgroundImage: {
    position: "absolute",
    top: 0,
    height: "100%",
    width: "100%",
    objectFit: "contain",
    userSelect: "none",
  },
  reveal: {
    opacity: 1,
  },
  closeButtonWrapper: {
    position: "absolute",
    cursor: "pointer",
    top: 8,
    right: 8,
    zIndex: 2,
  },
  titleContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    textAlign: "center",
    boxSizing: "border-box",
    width: "100%",
  },
  left: {
    textAlign: "left",
    alignItems: "left",
    width: "100%",
  },
  title: {
    fontWeight: "500",
    height: 30,
    width: "100%",
    fontSize: 26,
    color: "#232038",
    "@media only screen and (max-width: 725px)": {
      width: 450,
    },
    "@media only screen and (max-width: 557px)": {
      width: 380,
      fontSize: 24,
    },
    "@media only screen and (max-width: 415px)": {
      width: 300,
      fontSize: 22,
    },
    "@media only screen and (max-width: 321px)": {
      width: 280,
    },
  },
  closeIcon: {
    "@media only screen and (min-width: 768px)": {
      color: "#fff",
    },
  },
  whiteCloseIcon: {
    color: "#fff",
  },
  subtitle: {
    marginTop: 15,
    fontSize: 16,
    minHeight: 22,
    width: "100%",
    fontWeight: "400",
    color: "#4f4d5f",
    boxSizing: "border-box",
    whiteSpace: "pre-wrap",
    "@media only screen and (max-width: 557px)": {
      fontSize: 14,
      width: 300,
    },
  },
  zIndex: {
    zIndex: 12,
  },
});

export default BaseModal;
