import { useState } from "react";
import { StyleSheet, css } from "aphrodite";

// Components
import MembershipCard from "../../MembershipCard";

import Wrapper from "../../Wrapper";
import Text from "../../Text";
import Button from "../../Button";
import SpinLoader from "../../SpinLoader";

// Config
import { MEMBERSHIP_LEVELS } from "../../../utils/constants";
import { Colors } from "../../../utils/colors";

const SelectMembership = ({
  userTokens,
  setPage,
  isFetchingUserTokens,
  confirmMembershipTier,
}) => {
  const [selectedTier, setSelectedTier] = useState(null);

  const stateProps = {
    selectedTier,
    setSelectedTier,
    confirmTier,
  };

  function confirmTier(membership) {
    confirmMembershipTier(membership);
  }

  return (
    <Wrapper>
      <div className={css(styles.root)}>
        <div className={css(styles.headerContainer)}>
          <div className={css(styles.header)}>
            <div className={css(styles.leftColumn)}>
              <Text
                type={"h2"}
                style={styles.title}
                label={"Select Your Membership Tier"}
              />
              <p
                style={{
                  fontWeight: "normal",
                  fontSize: 24,
                  marginTop: 16,
                  marginBottom: 0,
                  textTransform: "none",
                  color: "#fff",
                }}
              >
                Mint multiple tokens to contribute more to our fight
              </p>
            </div>
            {isFetchingUserTokens ? (
              <SpinLoader
                isLoading={true}
                height={35}
                width={35}
                color={Colors.lime()}
              />
            ) : !!userTokens?.length ? (
              <div className={css(styles.rightColumn)}>
                <Button
                  label={"View Your Tokens"}
                  onClick={() => setPage(2)}
                  style={styles.buttonWrapper}
                  buttonStyles={styles.button}
                  noArrow={true}
                />
              </div>
            ) : null}
          </div>
        </div>

        <Wrapper background={Colors.gray()}>
          <div className={css(styles.memberships)}>
            {MEMBERSHIP_LEVELS.map((membership, i) => {
              return (
                <MembershipCard
                  key={`membership-${membership.tier}`}
                  membership={membership}
                  index={i}
                  mintFlow={true}
                  callout={membership.callout}
                  {...stateProps}
                />
              );
            })}
          </div>
        </Wrapper>
      </div>
    </Wrapper>
  );
};

const styles = StyleSheet.create({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
    width: "100%",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-end",
    width: "100%",
    padding: "10px 40px 30px 40px",
    maxWidth: 1750,
    margin: "0 auto",
    boxSizing: "border-box",
    "@media only screen and (max-width: 767px)": {
      flexDirection: "column",
    },
  },
  headerContainer: {
    width: "100%",
    background: Colors.charcoal(),
  },
  leftColumn: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
    paddingRight: 45,
    "@media only screen and (max-width: 767px)": {
      width: "100%",
      minWidth: "100%",
      paddingBottom: 15,
      paddingRight: 0,
    },
  },
  rightColumn: {
    display: "flex",
    justifyContent: "flex-end",
    "@media only screen and (max-width: 767px)": {
      width: "100%",
      minWidth: "100%",
    },
  },
  title: {
    fontFamily: "GT Flexa Trial",
    color: Colors.gray(),
    padding: "30px 0 0",
  },
  subtitle: {
    fontFamily: "GT Flexa Trial",
    whiteSpace: "pre-wrap",
    color: Colors.gray(),
    paddingBottom: 30,
  },
  memberships: {
    overflowY: "auto",
    display: "grid",
    gridGap: 30,
    padding: "60px 30px",
    boxSizing: "border-box",
    gridTemplateColumns: "1fr 1fr 1fr",
    maxWidth: 1750,
    width: "100vw",
    margin: "0 auto",
    boxSizing: "border-box",
    background: Colors.gray(),
    // "@media only screen and (max-width: 1023px)": {
    //   gridTemplateColumns: "1fr",
    //   justifyContent: "space-evenly",
    //   justifyItems: "flex-start",
    //   alignContent: "space-evenly",
    //   alignItems: "flex-start",
    // },
    "@media only screen and (max-width: 1023px)": {
      gridTemplateColumns: "1fr",
      padding: "15px 10px",
      gridGap: 15,
    },
  },
  flexRow: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
  },
  footer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    boxSizing: "border-box",
    padding: "20px 30px",
    maxWidth: 1750,
    margin: "0 auto",
  },
  // BUTTONS
  buttonWrapper: {
    minWidth: 300,
    height: 60,
    "@media only screen and (max-width: 767px)": {
      width: "100%",
      minWidth: "100%",
      // margin: "10px 0"
    },
  },
  button: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontFamily: "GT Flexa Trial",
    fontWeight: "bold",
    padding: "20px 30px",
    boxSizing: "border-box",
    borderRadius: 3,
    minWidth: 300,
    height: 50,
    boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",

    "@media only screen and (max-width: 767px)": {
      width: "100%",
      minWidth: "100%",
    },
  },
});

export default SelectMembership;
