// Npm
import { StyleSheet, css } from "aphrodite";
import React, { useRef, useState, useEffect, Fragment } from "react";

import avatarSquare from "../../assets/icons/avatar-square.svg";
import avatarSquares from "../../assets/icons/avatar-squares.svg";

// Components
import AvatarHead from "./AvatarHead";
import AvatarHeadStatic from "./AvatarHeadStatic";

const AvatarGenerator = React.forwardRef(
  (
    {
      faces,
      pants,
      shirt,
      hands,
      orientation,
      showFloor = true,
      staticHead = false,
      onDrawingStart,
      onDrawingEnd,
      onFaceLoadingStart,
      onFaceLoadingEnd,
      color,
      height = 200,
      width = 200,
    },
    ref
  ) => {
    const canvas = useRef();

    const [isDrawing, setIsDrawing] = useState(false);
    const [canvasWidth, setCanvasWidth] = useState(1080);
    const [canvasHeight, setCanvasHeight] = useState(1080);
    const [isDrawingHands, setIsDrawingHands] = useState(false);
    const [isDrawingPants, setIsDrawingPants] = useState(false);
    const [isDrawingShirt, setIsDrawingShirt] = useState(false);

    useEffect(() => {
      loadAvatarBody();
    }, [shirt, pants, hands, orientation, canvasWidth]);

    function loadAvatarBody() {
      onDrawingStart && onDrawingStart();

      const c = canvas.current.getContext("2d");

      const _pants = new Image();
      if (pants) {
        _pants.src = pants[orientation];
      }

      const _hands = new Image();

      if (hands) {
        _hands.src = hands[orientation];
      }

      const _shirt = new Image();

      if (shirt) {
        _shirt.src = shirt[orientation];
      }

      const offsetX = 5;
      const offsetY =
        orientation === "front" ? -(canvasWidth * 0.1) : -(canvasWidth * 0.06);

      // order matters!
      _pants.onload = () => {
        setIsDrawingPants(false);
      };
      _hands.onload = () => {
        setIsDrawingHands(false);
      };
      _shirt.onload = () => {
        setTimeout(() => {
          c.imageSmoothingQuality = "high";
          c.clearRect(0, 0, canvasWidth, canvasHeight);
          c.drawImage(
            _pants,
            offsetX - 3,
            offsetY + 3,
            canvasWidth,
            canvasHeight
          );
          c.drawImage(_hands, offsetX - 3, offsetY, canvasWidth, canvasHeight);
          c.drawImage(_shirt, offsetX, offsetY, canvasWidth, canvasHeight);
          // callback when drawing complete
          onDrawingEnd && onDrawingEnd(orientation);
        }, 200);
      };
    }

    const avatarHeadProps = {
      faces,
      color,
      orientation,
      onLoadingStart: onFaceLoadingStart,
      onLoadingEnd: onFaceLoadingEnd,
    };

    return (
      <div className={css(styles.container, isDrawing && styles.opaque)}>
        <div
          className={css(styles.avatar)}
          ref={ref && ref}
          id={`generator-${orientation}`}
        >
          {staticHead ? (
            <AvatarHeadStatic {...avatarHeadProps} />
          ) : (
            <AvatarHead {...avatarHeadProps} />
          )}
          <canvas
            ref={canvas}
            id={`canvas-${orientation}`}
            width={canvasWidth}
            height={canvasHeight}
            className={css(styles.canvas)}
            style={{ height: 200, maxHeight: 200, width: 200, maxWidth: 200 }}
          />
        </div>
        {showFloor && (
          <Fragment>
            <img className={css(styles.squaresFloor)} src={avatarSquares} />
            <img className={css(styles.squareFloor)} src={avatarSquare} />
          </Fragment>
        )}
      </div>
    );
  }
);

const styles = StyleSheet.create({
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    transition: "all ease-in-out 0.1s",
    height: "100%",
    position: "relative",
    paddingBottom: 50,
    boxSizing: "border-box",
  },
  avatar: {
    backgroundColor: "transparent",
    zIndex: 1,
    width: 200,
    minWidth: 200,
    maxWidth: 200,
    height: 280,
    // minHeight: 328.5,
    // maxHeight: 328.5,
  },
  opaque: {
    filter: "blur(1.5rem)",
  },
  canvas: {
    marginTop: -45,
    userSelect: "none",
  },
  squaresFloor: {
    position: "absolute",
    bottom: 0,
    userSelect: "none",
    "@media only screen and (max-width: 1000px)": {
      display: "none",
    },
  },
  squareFloor: {
    position: "absolute",
    bottom: 60,
    width: 200,
    userSelect: "none",
    display: "none",
    "@media only screen and (max-width: 1000px)": {
      display: "flex",
    },
  },
});

export default AvatarGenerator;
