import { useEffect, useState, Fragment } from 'react';
import { css, StyleSheet } from "aphrodite";

// Components
import Text from "../../Text";
import { Colors } from '../../../utils/colors';
import { getColorNameFromHex } from '../../../config/api';


const AvatarSummary = ({ userAvatar }) => {

  const [colorName, setColorName] = useState("loading...");
  const [isFetchingColorName, setIsFetchingColorName] = useState(false);

  useEffect(() => {
    _getColorNameFromHex()
  }, [userAvatar]);

  async function _getColorNameFromHex() {
    const option = userAvatar.color;
    setIsFetchingColorName(true);
    const colorData = await getColorNameFromHex(option);
    const _colorName = colorData?.name?.value;
    setColorName((!colorData || !_colorName) ? userAvatar.color.hex : _colorName);
    setIsFetchingColorName(false)
  }

  function renderAvatarAttributes() {
    const attributes = Object.entries(userAvatar);
    return attributes.map(([type, option], i) => {
      return _renderAttributeRow(type, option)
    })
  }

  function _renderAttributeRow(type, option) {
    switch(type) {
      case "faces":
        return renderFaceAttributes(option)
        break;
      case "color":
        return (
          <div key={`color`} className={css(styles.row)}>
            <Text style={styles.label} type={"h4"} label={`Cube ${type}`} />
            <Text style={styles.value} label={colorName} />
          </div>
        );
      case "hands":
      case "shirt":
      case "pants":
        const { 
          id,    // id of apparel
          value, // color of apparel
        } = option;
        return (
          <div key={id} className={css(styles.row)}>
            <Text style={styles.label} type={"h4"} label={type} />
            <Text style={styles.value} label={value} />
          </div>
        )
      default:
        return null;
    }
  };

  function renderFaceAttributes(faces){
    return faces.map((face, i) => { 
      
      if (face?.type == "nft") {
        return (
          <div key={`nft-attribute-${face.id}-${i}`} className={css(styles.row)}>
            <Text style={styles.label} type={"h4"} label={`Face ${i+1}`} />
            <Text style={styles.value} label={face?.title} />
          </div>
        )
      } else {
        return (
          <div key={`face-attribute-${face.id}-${i}`} className={css(styles.row)}>
            <Text style={styles.label} type={"h4"} label={`Face ${i+1}`} />
            <Text style={styles.value} label={face ? face?.value : "None"} />
          </div>
        )
      }
    })
  }

  return (
    <div className={css(styles.container)}>
      {renderAvatarAttributes()}
    </div>
  )
};

const styles = StyleSheet.create({
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    width: "100%"
  },
  row: {
    display: "flex",
    justifyContent: 'space-between',
    alignItems: "center",
    width: "100%",
    padding: "10px 0"
  },
  label: {
    color: Colors.gray(),
    textTransform: "uppercase",
    "@media only screen and (max-width: 767px)": {
      fontSize: 18
    }
  },
  value: {
    color: Colors.gray(),
    textTransform: "uppercase",
    fontFamily: "Poppins"
  }
});

export default AvatarSummary;