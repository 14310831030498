import { useState, useRef } from "react";
import { css, StyleSheet } from "aphrodite";
import ReactTooltip from "react-tooltip";
import { toPng } from "html-to-image";
import * as Sentry from "@sentry/browser";

// Components
import Text from "../../Text";
import Button from "../../Button";
import EthLoader from "../../EthLoader";
import Wrapper from "../../Wrapper";
import AvatarGenerator from "../../avatar/AvatarGenerator";
import AvatarSummary from "./AvatarSummary";
import AvatarRotationButton from "./AvatarRotationButton";
import { Colors } from "../../../utils/colors";
import CascadingImage from "../../CascadingImage";
import { getMetaMaskSignature } from "../../../utils/web3Interact";
import { patchAvatar } from "../../../config/api";
import { toast } from "react-toastify";

const AvatarConfirmation = ({
  setPage,
  page,
  userAvatar,
  chosenTokensHash,
  userMembershipTier: {
    assets: { background },
  },
}) => {
  const frontAvatarRef = useRef();
  const backAvatarRef = useRef();

  const [orientation, setOrientation] = useState("front");

  const [isSaving, setIsSaving] = useState(false);
  const [isAvatarFaceLoading, setisAvatarFaceLoading] = useState(false);
  const [isAvatarBodyLoading, setisAvatarBodyLoading] = useState(false);

  const isLoading = isAvatarFaceLoading || isAvatarBodyLoading || isSaving;

  function dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }

  async function saveAvatar() {
    if (isLoading) return;
    const metadata = _formatAvatarMetadata();
    let signature;
    try {
      signature = await getMetaMaskSignature();
    } catch (e) {
      Sentry.captureException(e);
      toast.error(
        "Please make sure you're connected to metamask and try again.",
        {
          toastId: "customize-avatar",
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );

      return null;
    }

    try {
      setIsSaving(true);
      const formData = new FormData();
      const frontImg = await _generateAvatarSnapshot(frontAvatarRef);
      const backImg = await _generateAvatarSnapshot(backAvatarRef);
      formData.append("front_image", dataURLtoFile(frontImg, "front.png"));
      formData.append("back_image", dataURLtoFile(backImg, "back.png"));
      formData.append("signature", signature);
      formData.append("metadata", JSON.stringify(metadata));
      formData.append("nft_set", false);

      const tokenIds = Object.keys(chosenTokensHash);

      let error = false;
      for (let i = 0; i < tokenIds.length; i++) {
        const res = await patchAvatar(formData, tokenIds[i]);
        if (res.error) {
          error = true;
        }
      }
      setIsSaving(false);

      if (!error) {
        setPage(5);
      }
    } catch (err) {
      // handle error
      Sentry.setContext("mintData", {
        metadata,
        signature,
        chosenTokensHash,
      });
      Sentry.captureException(err);
      console.log(err);
      setIsSaving(false);
      frontAvatarRef.current.style.transform = "scale(1, 1)";
      toast.error(
        "Sorry, something went wrong during the token generation process. Please try again later.",
        {
          toastId: "customize-avatar",
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
    }
  }

  function _formatAvatarMetadata() {
    return userAvatar?.id ? userAvatar.metadata : userAvatar;
  }

  function makeid(length) {
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  async function _generateAvatarSnapshot(avatarRef) {
    avatarRef.current.style.transform =
      "scale(2.29, 2.29) translateX(56px) translateY(82px)"; // scale up image for better quality
    const fontEmbedCss = {};
    const options = {
      scale: 1,
      cacheBust: false,
      backgroundColor: null,
      width: 458,
      height: 653,
      pixelRatio: 1,
      useCORS: true,
      fontEmbedCSS: fontEmbedCss,
    };

    return toPng(avatarRef.current, options).then((data) => {
      avatarRef.current.style.transform = "scale(1, 1)";
      return data;
    });
  }

  const buttonProps = {
    label: "Confirm",
    style: styles.buttonContainer,
    buttonStyles: styles.button,
    noArrow: true,
    disabled: isLoading,
    isLoading: isLoading,
    onClick: saveAvatar,
  };

  const avatarContainerBackgroundStyle = {
    backgroundImage: `url(${background})`,
    backgroundColor: Colors.gray(),
  };

  return (
    <Wrapper background={Colors.charcoal()}>
      <div className={css(styles.container)}>
        <div className={css(styles.titleContainer)}>
          <Text type={"h2"} style={styles.title} label={"Save Avatar"} />
          <Text
            style={styles.subtitle}
            label={
              "Once confirmed, this avatar will be placed on your minted Lobby3 token, and the avatar itself will be displayed in the mural on the Lobby3 homepage.\nYou can always make changes to your avatar even after you confirm. This process may take a few minutes to finalize."
            }
          />
        </div>
        <div className={css(styles.content)}>
          <div className={css(styles.left)}>
            <Text type={"h3"} style={styles.label} label={"Character"} />
            <div
              className={css(styles.avatarContainer)}
              style={avatarContainerBackgroundStyle}
            >
              <CascadingImage src={background} />
              {isSaving && (
                <div
                  className={css(styles.overlay)}
                  style={{ backgroundImage: `url(${background})` }}
                >
                  <Text type={"h3"} label={"Saving Avatar..."} />
                  <EthLoader isLoading={true} style={styles.ethLoader} />
                </div>
              )}
              <div
                className={css(
                  orientation === "front"
                    ? styles.frontAvatar
                    : styles.frontAvatarBehind
                )}
              >
                <AvatarGenerator
                  key={"avatar-confirmation-front"}
                  {...userAvatar}
                  ref={frontAvatarRef}
                  orientation={"front"}
                  staticHead={true} //important for screenshot
                  showFloor={false}
                  onDrawingStart={() => setisAvatarBodyLoading(true)}
                  onDrawingEnd={() => setisAvatarBodyLoading(false)}
                  onFaceLoadingStart={() => setisAvatarFaceLoading(true)}
                  onFaceLoadingEnd={() => setisAvatarFaceLoading(false)}
                />
              </div>
              <div
                className={css(
                  orientation === "front"
                    ? styles.backAvatarBehind
                    : styles.backAvatar
                )}
              >
                <AvatarGenerator
                  key={"avatar-confirmation-back"}
                  {...userAvatar}
                  ref={backAvatarRef}
                  orientation={"back"}
                  staticHead={true} //important for screenshot
                  showFloor={false}
                />
              </div>
              <div
                className={css(styles.editButton)}
                onClick={() => !isSaving && setPage(3)}
                data-for={"avatar-edit-button"}
                data-tip
                data-iscapture
              >
                {"Edit"}
              </div>
              <ReactTooltip
                id={"avatar-edit-button"}
                getContent={() => "Edit Avatar"}
              />
              <AvatarRotationButton
                orientation={orientation}
                onClick={() =>
                  setOrientation(orientation === "front" ? "back" : "front")
                }
                style={styles.rotateButton}
              />
            </div>
          </div>
          <div className={css(styles.right)}>
            <Text type={"h3"} style={styles.label} label={"Attributes"} />
            <AvatarSummary userAvatar={userAvatar} />
            <Button {...buttonProps} />
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

const styles = StyleSheet.create({
  inputContainer: {
    width: "100%",
    marginBottom: 30,
  },
  container: {
    padding: "25px 50px",
    width: "100%",
    boxSizing: "border-box",
    "@media only screen and (max-width: 767px)": {
      padding: 20,
    },
  },
  titleContainer: {
    width: "100%",
    maxWidth: 1200,
    margin: "0 auto",
  },
  title: {
    color: Colors.gray(),
    paddingBottom: 20,
  },
  subtitle: {
    whiteSpace: "pre-wrap",
    color: Colors.gray(),
    paddingBottom: 30,
  },
  label: {
    textTransform: "uppercase",
    color: Colors.gray(),
    fontSize: 28,
    marginBottom: 15,
  },
  input: {
    width: "100%",
    color: "#000",
    boxSizing: "border-box",
    background: Colors.gray(),
  },
  content: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
    background: Colors.medCharcoal(),
    width: "100%",
    margin: "0 auto",
    padding: 30,
    maxWidth: 1200,
    boxSizing: "border-box",
    borderRadius: 5,
    border: "1px solid #FAFAFA",
    position: "relative",
    "@media only screen and (max-width: 1000px)": {
      flexDirection: "column",
      alignItems: "center",
    },
    "@media only screen and (max-width: 767px)": {
      padding: "10px 0",
      border: "none",
      background: Colors.charcoal(),
    },
  },
  left: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    width: "45%",
    "@media only screen and (max-width: 1000px)": {
      width: "100%",
      boxSizing: "border-box",
    },
  },
  right: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    width: "50%",
    "@media only screen and (max-width: 1000px)": {
      width: "100%",
      marginTop: 30,
    },
  },
  avatarContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
    width: "100%",
    height: "100%",
    padding: "80px 50px 20px 50px",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    border: `1px solid ${Colors.gray()}`,
    boxSizing: "border-box",
    overflow: "hidden",
    //do not change! need for image consistency
  },
  frontAvatar: {
    zIndex: 1,
    marginRight: -200, //offset
  },
  frontAvatarBehind: {
    zIndex: -1,
    marginRight: 0,
  },
  backAvatar: {
    zIndex: 1,
    marginLeft: -200,
  },
  backAvatarBehind: {
    zIndex: -1,
    marginLeft: 0,
  },
  overlay: {
    position: "absolute",
    top: 0,
    left: 0,
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: Colors.gray(),
    pointerEvents: "none",
    zIndex: 5,
  },
  editButton: {
    position: "absolute",
    top: 15,
    right: 15,
    padding: "10px 30px",
    color: Colors.charcoal(),
    background: Colors.lime(),
    borderRadius: 3,
    cursor: "pointer",
    fontFamily: "Poppins",
    fontSize: 18,
    boxShadow: `0 0 10px ${Colors.lightCharcoal(0.5)}`,
    ":hover": {},
  },
  rotateButton: {
    top: 15,
    right: "usnet",
    left: 15,
    boxShadow: `0 0 10px ${Colors.lightCharcoal(0.5)}`,
  },
  avatarSquare: {
    position: "absolute",
    bottom: 80,
    height: 140,
  },
  buttonContainer: {
    width: "100%",
    marginTop: 73,

    "@media only screen and (max-width: 1000px)": {
      marginTop: 16,
    },
  },
  button: {
    width: "100%",
    padding: "15px 0",
    height: 70,
  },
  ethLoader: {
    width: 30,
    marginTop: 20,
  },
});

export default AvatarConfirmation;
