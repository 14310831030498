// NPM
import { StyleSheet, css } from "aphrodite";
import { Colors } from "../utils/colors";

const Text = ({ type, label, children, style, clamp }) => {
  const classNames = [];
  const text = label ? label : children;
  const typography = formatByType();

  function formatByType() {
    switch (type) {
      case "h1":
        classNames.push(styles.h1, style && style);
        return (
          <h1 className={css(classNames) + `${clamp ? " clamp2" : ""}`}>
            {text}
          </h1>
        );
      case "h2":
        classNames.push(styles.h2, style && style);
        return (
          <h2 className={css(classNames) + `${clamp ? " clamp2" : ""}`}>
            {text}
          </h2>
        );
      case "h3":
        classNames.push(styles.h3, style && style);
        return (
          <h3 className={css(classNames) + `${clamp ? " clamp2" : ""}`}>
            {text}
          </h3>
        );
      case "h4":
        classNames.push(styles.h4, style && style);
        return (
          <h4 className={css(classNames) + `${clamp ? " clamp2" : ""}`}>
            {text}
          </h4>
        );
      case "italic":
        classNames.push(styles.italic);
        return <p className={css(classNames)}>{text}</p>;
      case "bold":
        classNames.push(styles.bold);
        return <p className={css(classNames)}>{text}</p>;
      default:
        classNames.push(styles.p, style && style);
        return <p className={css(classNames)}>{text}</p>;
    }
  }

  return typography;
};

const styles = StyleSheet.create({
  h1: {
    margin: 0,
    fontWeight: "bold",
  },
  h2: {
    margin: 0,
    padding: 0,
  },
  h3: {
    fontSize: 28,
    margin: 0,
  },
  h4: {
    fontSize: 22,
    margin: 0,
  },
  p: {
    fontSize: 18,
    margin: 0,
    padding: 0,
    lineHeight: 1.6,
    fontWeight: 500,
    "@media only screen and (max-width: 767px)": {
      fontSize: 16,
    },
  },
  italic: {
    fontStyle: "italic",
  },
  bold: {
    fontWeight: "bold",
  },
});

export default Text;
