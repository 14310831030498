// Npm
import { StyleSheet, css } from "aphrodite";
import React, { useRef, useState, useEffect } from "react";

const CUBE_DIMENSION = 125; // H & W of cube

const AvatarHead = ({ faces, orientation, color }) => {
  const container = useRef();
  const cube = useRef();

  const DEFAULT_CUBE_POSITION =
    "translate3d(0, 0, 0) rotateX(-35deg) rotateY(45deg)";

  const [currentX, setCurrentX] = useState(0);
  const [currentY, setCurrentY] = useState(0);
  const [rotateX, setRotateX] = useState(0);
  const [rotateY, setRotateY] = useState(0);
  const [isMouseDown, setIsMouseDown] = useState(false);
  const [prevOrientation, setPrevOrientation] = useState(orientation);

  useEffect(() => {
    _updateOrientation(orientation);
  }, [orientation]);

  useEffect(() => {
    // needed to detect mouseup when outside boundary
    document.addEventListener("mouseup", release);

    return () => {
      document.removeEventListener("mouseup", release);
    };
  });

  function _updateOrientation(orientation) {
    if (prevOrientation === orientation) return;
    // const currPosition = cube.current.style;
    cube.current.style.transform = DEFAULT_CUBE_POSITION;

    if (orientation === "front") {
      cube.current.style.transform = DEFAULT_CUBE_POSITION;
    } else {
      const [_rotateX, _rotateY] = calcRotationXY(DEFAULT_CUBE_POSITION);
      const newRotateY = rotateY + 450;
      cube.current.style.transform = `translate3d(0, 0, 0) rotateX(${_rotateX}deg) rotateY(${newRotateY}deg)`;
    }
    setPrevOrientation(orientation);
  }

  function calcRotationXY(transform) {
    const _transform = transform ? transform : cube.current.style.transform;

    return [
      Number(_transform.match(/rotateX\((-?[0-9]+(\.[0-9])?)*deg\)/)[1]),
      Number(_transform.match(/rotateY\((-?[0-9]+(\.[0-9])?)*deg\)/)[1]),
    ];
  }

  function handleMouseDown(e) {
    !isMouseDown && setIsMouseDown(true);
    const [newRotateX, newRotateY] = calcRotationXY();

    setCurrentX(e.clientX);
    setCurrentY(e.clientY);

    setRotateX(newRotateX);
    setRotateY(newRotateY);
  }

  function drag(e) {
    if (!isMouseDown) return;

    const draggedX = e.clientX - currentX;
    const draggedY = e.clientY - currentY;

    if (e.buttons !== 1) return;
    // update cube orientation

    const bufferedRotateX = Math.min(-8.5, rotateX - draggedY / 2);
    const cubeTransformStyle = `translate3d(0, 0, 0) rotateX(${bufferedRotateX}deg) rotateY(${
      rotateY + draggedX / 2
    }deg)`;
    cube.current.style.transform = cubeTransformStyle;
    container.current.style.cursor = "move";
  }

  function release(e) {
    // update pointer position
    const [newRotateX, newRotateY] = calcRotationXY();

    setCurrentX(e.clientX);
    setCurrentY(e.clientY);
    setRotateX(newRotateX);
    setRotateY(newRotateY);

    isMouseDown && setIsMouseDown(false);
    container.current.style.cursor = "auto";
  }

  function rotateCube(type) {
    let orientationStyle;
    switch (type) {
      case "top":
        // orientationStyle = "transform: translate3d(0px, 0px, 0px) rotateX(-90deg) rotateY(0deg)"
        break;
    }

    cube.current.style = orientationStyle;
  }

  function _formatCubeStyle(type) {
    const style = {
      opacity: isMouseDown ? 0.9 : 1,
      backgroundColor: color.hex,
    };

    switch (type) {
      case "top":
        style.backgroundImage = `url(${faces[1]?.src || null})`;
        break;
      case "left":
        style.backgroundImage = `url(${faces[0]?.src || null})`;
        break;
      case "right":
        style.backgroundImage = `url(${faces[2]?.src || null})`;
        break;
      default:
        break;
    }
    return style;
  }

  return (
    <div
      className={css(styles.root)}
      ref={container}
      onMouseDown={handleMouseDown}
      onMouseMove={drag}
    >
      <div className={css(styles.cubeWrapper)}>
        <div
          className={css(styles.cube)}
          ref={cube}
          style={{ transform: DEFAULT_CUBE_POSITION }}
        >
          <div
            className={css(styles.cubeFace, styles.cubeFront)}
            style={{ ..._formatCubeStyle("right") }}
          />
          <div
            className={css(styles.cubeFace, styles.cubeBack)}
            style={{ ..._formatCubeStyle("right") }}
          />
          <div
            className={css(styles.cubeFace, styles.cubeTop)}
            style={{ ..._formatCubeStyle("top") }}
          />
          <div
            className={css(styles.cubeFace, styles.cubeBottom)}
            style={{ ..._formatCubeStyle("top") }}
          />
          <div
            className={css(styles.cubeFace, styles.cubeLeft)}
            style={{ ..._formatCubeStyle("left") }}
          />
          <div
            className={css(styles.cubeFace, styles.cubeRight)}
            style={{ ..._formatCubeStyle("left") }}
          />
        </div>
      </div>
    </div>
  );
};

const styles = StyleSheet.create({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  cubeWrapper: {
    display: "inline-block",
    textAlign: "center",
    width: CUBE_DIMENSION,
    height: CUBE_DIMENSION,
    perspective: 1000,
    cursor: "grabbing",
  },
  cube: {
    display: "inline-block",
    transition: "all 0.85s cubic-bezier(0.175,0.885,0.320,1.275)",
    textAlign: "center",
    position: "relative",
    width: "inherit",
    height: "inherit",
    transformStyle: "preserve-3d",
  },
  cubeFace: {
    overflow: "hidden",
    position: "absolute",
    border: "3px solid #000",
    boxShadow: "0 0 80px rgba(255, 255, 255, 0.1)",
    boxSizing: "border-box",
    lineHeight: CUBE_DIMENSION,
    width: "inherit",
    height: "inherit",
    transition: "all ease-in-out 0.2s",
    userSelect: "none",
    backgroundPosition: "center",
  },
  cubeFront: {
    transform: `translate3d(0, 0, ${CUBE_DIMENSION / 2}px)`,
    backgroundSize: "cover",
  },
  cubeBack: {
    transform: `rotateY(180deg) translate3d(0, 0, ${CUBE_DIMENSION / 2}px)`,
    backgroundSize: "cover",
  },
  cubeTop: {
    transform: `rotateX(90deg) translate3d(0, 0, ${CUBE_DIMENSION / 2}px)`,
    backgroundSize: "cover",
  },
  cubeBottom: {
    transform: `rotateX(-90deg) translate3d(0, 0, ${CUBE_DIMENSION / 2}px)`,
    backgroundSize: "cover",
  },
  cubeLeft: {
    transform: `rotateY(-90deg) translate3d(0, 0, ${CUBE_DIMENSION / 2}Px)`,
    backgroundSize: "cover",
  },
  cubeRight: {
    transform: `rotateY(90deg) translate3d(0, 0, ${CUBE_DIMENSION / 2}px)`,
    backgroundSize: "cover",
  },
});

export default AvatarHead;
