// NPM Modules
import { StyleSheet, css } from "aphrodite";
import { FORMATTED_OPENING_DATE } from "../../utils/constants";
import BaseModal from "./BaseModal";
import Picture from "../../assets/L3_MintableDiamond.webp";
import Button from "../Button";
import arrowRightDark from "../../assets/arrow-right-dark.png";

function ComingSoonModal({ closeModal, isOpen }) {
  return (
    <BaseModal
      closeModal={closeModal}
      isOpen={isOpen}
      shouldCloseOnOverlayClick={true}
    >
      <div className={css(styles.modalContainer)}>
        <div className={css(styles.left)}>
          <h2 className={css(styles.title)}>
            Minting Opens {FORMATTED_OPENING_DATE}
          </h2>
          <p>Don't miss out.</p>
          <p>
            Stay up to date and join in the conversation by joining our Discord.
          </p>
          <link
            href="//cdn-images.mailchimp.com/embedcode/horizontal-slim-10_7.css"
            rel="stylesheet"
            type="text/css"
          />
          <Button
            label={"Join Discord"}
            showBlackArrow={true}
            style={styles.joinDiscord}
            buttonStyles={styles.joinDiscordButton}
            href="https://discord.com/invite/lobby3"
          />
          <p className={css(styles.getNotified)}>
            Get notified when minting starts by subscribing to our email list.
          </p>
          <div
            id="mc_embed_signup"
            style={{ background: "#fff", clear: "left" }}
          >
            <form
              action="https://yangspeaks.us5.list-manage.com/subscribe/post?u=7448319cdb745ea2fd1b1b8a8&amp;id=7e5e777b72"
              method="post"
              id="mc-embedded-subscribe-form"
              name="mc-embedded-subscribe-form"
              className={css(styles.validate) + " validate"}
              target="_blank"
              noValidate
              style={{ textAlign: "unset!important" }}
            >
              <div id="mc_embed_signup_scroll">
                <input
                  type="email"
                  name="EMAIL"
                  className={css(styles.input) + " email"}
                  id="mce-EMAIL"
                  placeholder="email address"
                  required
                />
                <div
                  style={{ position: "absolute", left: "-5000px" }}
                  aria-hidden="true"
                >
                  <input
                    type="text"
                    name="b_7448319cdb745ea2fd1b1b8a8_7e5e777b72"
                    tabindex="-1"
                    value=""
                  />
                </div>
                <div className={css(styles.clear) + " clear"}>
                  <input
                    type="submit"
                    value="Subscribe"
                    name="subscribe"
                    id="mc-embedded-subscribe"
                    className={css(styles.button) + " button"}
                  />
                  <img
                    id={"arrow"}
                    src={arrowRightDark}
                    alt={"arrow-right"}
                    className={css(styles.arrowIcon)}
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
        <div className={css(styles.right)}>
          <img className={css(styles.picture)} alt="l3-pic" src={Picture}></img>
        </div>
      </div>
    </BaseModal>
  );
}

const styles = StyleSheet.create({
  modalContainer: {
    display: "flex",
    height: "80vh",
    width: "80vw",
    overflow: "auto",
    "@media only screen and (max-width: 767px)": {
      height: "100vh",
      width: "100vw",
      flexDirection: "column",
    },
    // overflow: "hidden",
  },
  joinDiscord: {
    width: "100%",
  },
  joinDiscordButton: {
    width: "100%",
    textAlign: "center",
    "@media only screen and (min-width: 1024px)": {
      maxWidth: 350,
      boxSizing: "border-box",
    },
  },
  title: {
    fontFamily: "GT Flexa Expanded",
    fontSize: 30,
    lineHeight: 1.4,
  },
  left: {
    flex: 1,
    padding: 50,
  },
  right: {
    flex: 1,
    maxWidth: "50%",
    "@media only screen and (max-width: 767px)": {
      maxWidth: "unset",
    },
  },
  arrowIcon: {
    width: "min-content",
    marginLeft: 20,
    width: 50,
    cursor: "pointer",
    "@media only screen and (max-width: 767px)": {
      marginLeft: 10,
      width: 40,
    },
  },
  getNotified: {
    marginTop: 32,
    "@media only screen and (min-width: 768px)": {
      // marginTop: 64,
    },
  },
  picture: {
    objectFit: "cover",
    height: "100%",
    width: "100%",
    "@media only screen and (max-width: 767px)": {
      height: "unset",
      width: "100%",
    },
  },
  input: {
    height: 45,
    borderColor: "rgb(17, 17, 17)",
    fontFamily: "GT Flexa Trial",
  },
  validate: {
    textAlign: "unset",
  },
  button: {
    background: "rgb(217, 236, 107)",
    color: "rgb(17, 17, 17)",
    fontSize: 18,
    height: 45,
    textTransform: "uppercase",
    lineHeight: 1.6,
    fontWeight: 500,
    fontFamily: "GT Flexa Trial",
    maxWidth: 350,
    width: "100%",
    borderRadius: 0,
  },
  clear: {
    width: "100%",
    marginTop: 16,
    display: "flex",
    alignItems: "center",
  },
  logo: {
    height: 100,
    marginTop: 50,
  },
});

export default ComingSoonModal;
