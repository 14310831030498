import { StyleSheet, css } from "aphrodite";
import React, { useRef, useState, useEffect } from "react";

// Components
import Selector from "./Selector";
import Options from "./Options";
import { SELECTORS } from "../../utils/constants";

const AvatarSelectors = ({
  activeSelector,
  setActiveSelector,
  avatarProps,
  userNfts,
  color,
  setColor,
  isFetchingUserNfts,
  _getUserNfts,
  pageKey,
  totalNFTCount,
}) => {
  const currentSelector = SELECTORS[activeSelector];

  const optionsProps = {
    color,
    setColor,
    userNfts,
    _getUserNfts,
    isFetchingUserNfts,
    pageKey,
    totalNFTCount,
  };

  return (
    <div className={css(styles.root)}>
      <div className={css(styles.buttonContainer)}>
        {SELECTORS.map((selector, i) => (
          <Selector
            key={`selector-${i}`}
            button={selector}
            onSelect={setActiveSelector}
            activeIndex={activeSelector}
            index={i}
          />
        ))}
      </div>
      <Options {...avatarProps} {...optionsProps} selector={currentSelector} />
    </div>
  );
};

const styles = StyleSheet.create({
  root: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    width: "75%",
    maxWidth: "75%",
    userSelect: "none",
    "@media only screen and (max-width: 1000px)": {
      width: "100%",
      maxWidth: "100%",
      flexDirection: "column",
    },
  },
  buttonContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    "@media only screen and (max-width: 1000px)": {
      width: "100%",
      maxWidth: "100%",
      flexDirection: "row",
      justifyContent: "space-between",
    },
  },
});

export default AvatarSelectors;
