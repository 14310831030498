const { REACT_APP_LOBBY3_TOKEN_CONTRACT, REACT_APP_ENV } = process.env;

const lobby3ContractAddress = REACT_APP_LOBBY3_TOKEN_CONTRACT;
let { web3 } = window;

async function _formatTransaction(address, numOfTokens, membership) {
  try {
    const { id: tokenTier, price } = membership;
    const nonce = await web3?.eth?.getTransactionCount(address, "latest");
    const totalPrice = parseFloat(
      (Number(price) * numOfTokens).toFixed(2)
    ).toString();
    const defaultGas = 220000;
    const totalGas = defaultGas * numOfTokens;

    //set up your Ethereum transaction
    return {
      to: lobby3ContractAddress,
      from: address, // must match user's active address.
      nonce: nonce?.toString(),
      gas: web3?.utils?.toHex(totalGas),
      value: web3?.utils?.toHex(web3?.utils?.toWei(totalPrice, "ether")),
      data: _selectContractMethod(address, numOfTokens, tokenTier),
    };
  } catch (err) {
    throw new Error(err.message);
  }
}

function _selectContractMethod(address, numOfTokens, tokenTier) {
  switch (tokenTier) {
    case "member":
      return window.lobby3ContractTest.methods
        .mintStandardETH(address, numOfTokens)
        .encodeABI();
    case "advocate":
      return window.lobby3ContractTest.methods
        .mintGoldETH(address, numOfTokens)
        .encodeABI();
    case "founder":
      return window.lobby3ContractTest.methods
        .mintDiamondETH(address)
        .encodeABI();
    default:
      throw new Error(
        "No mint method found for tier. Please check tier and try again."
      );
  }
}

export async function mintTokens(address, numOfTokens, membership) {
  const args = Array.prototype.slice.call(arguments);

  try {
    if (numOfTokens <= 0 || args.length < 3)
      throw new Error(`Something went wrong. Please try again later.`);
    await requestNetworkSwitch();
    const transactions = await makeTokenTransaction(...args);
    return transactions;
  } catch (err) {
    throw new Error(err.message);
  }
}

async function makeTokenTransaction(address, numOfTokens, membership) {
  const networkId = await window?.web3?.eth?.getChainId();
  const transactionParameters = await _formatTransaction(
    address,
    numOfTokens,
    membership
  );

  return window?.ethereum
    .request({
      method: "eth_sendTransaction",
      params: [transactionParameters],
    })
    .then((txHash) => {
      return {
        success: true,
        status: "View your transaction on Etherscan",
        txLink: `https://${
          networkId === 1 ? "" : "rinkeby."
        }etherscan.io/tx/${txHash}`,
        txHash,
      };
    })
    .catch(_handleTransactionError);
}

function _handleTransactionError(error) {
  if (error.code === 4001) {
    // user-friendly version of error msg ('MetaMask Tx Signature: User denied transaction signature.')
    error.message = "Please confirm transaction signature to continue.";
  }
  return {
    success: false,
    txLink: "",
    status: error?.message ? error.message : "Something went wrong.",
  };
}

export async function requestNetworkSwitch() {
  return REACT_APP_ENV === "production"
    ? swithNetworkMainnet()
    : switchNetworkRinkeby();
}

export async function swithNetworkMainnet() {
  if (!window || !window.ethereum) throw new Error("");

  try {
    await window.ethereum.request({
      method: "wallet_switchEthereumChain",
      params: [{ chainId: "0x1" }],
    });
  } catch (error) {
    throw new Error(
      "Mainnet Network is required to mint tokens. Please try again."
    );
  }
}

export async function switchNetworkRinkeby() {
  if (!window || !window.ethereum) throw new Error("");

  try {
    await window.ethereum.request({
      method: "wallet_switchEthereumChain",
      params: [{ chainId: "0x4" }],
    });
  } catch (error) {
    throw new Error(
      "Rinkeby Network is required to mint tokens. Please try again."
    );
  }
}

export async function getAddress() {
  return window?.web3?.eth?.getAccounts()[0];
}

export async function getMetaMaskSignature() {
  const accounts = await window.web3.eth.getAccounts();
  const address = accounts[0];
  const message =
    "Sign this to verify that you own this wallet address with a lobby3 token.";

  return window.web3.eth.personal.sign(message, address);
}
