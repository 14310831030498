// Npm
import { StyleSheet, css } from "aphrodite";
import { Colors } from "../../utils/colors";
import { Link } from "react-router-dom";

// Components
import Text from "../Text";
import Wrapper from "../Wrapper";
import NavMenu from "../NavMenu";
import logo from "../../assets/L3_Logo_Col.webp";

const Footer = () => {
  return (
    <Wrapper background={Colors.charcoal()}>
      <div className={css(styles.container)}>
        <div className={css(styles.left)}>
          <Link to="/">
            <img src={logo} className={css(styles.logo)} alt="Lobby3" />
          </Link>
        </div>
        <div className={css(styles.row)}>
          <NavMenu horizontalAlignment={true} />
        </div>
      </div>
    </Wrapper>
  );
};

const styles = StyleSheet.create({
  container: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    background: Colors.charcoal(),
    color: Colors.gray(),
    padding: "20px 30px",
    boxSizing: "border-box",
    maxWidth: 1750,
  },
  left: {
    textAlign: "left",
    width: 130,
    paddingTop: 10,
    "@media only screen and (max-width: 767px)": {
      display: "none",
    },
  },
  row: {
    display: "flex",
    alignItems: "center",
    width: "max-content",
  },
  center: {
    width: "max-content",
  },
  right: {
    width: "max-content",
  },
  title: {
    fontWeight: "bold",
    paddingBottom: 15,
  },
  label: {
    paddingBottom: 15,
  },
  logo: {
    height: 60,
  },
});

export default Footer;
