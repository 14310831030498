// Npm
import { StyleSheet, css } from "aphrodite";
import { Colors } from "../../utils/colors";

// Component
import bulletOne from "../../assets/pyramid-one.png";
import bulletTwo from "../../assets/pyramid-two.png";
import bulletThree from "../../assets/pyramid-three.png";
import bulletPoints from "../../assets/bullet-points.png";
import Text from "../Text";
import Wrapper from "../Wrapper";

const HowItWorks = () => {
  const bullets = [
    {
      icon: bulletOne,
      title: "Membership",
      text: "Purchasing a Lobby3 general membership token means you’ll be supporting proactive efforts to give the people a new voice on Capitol Hill, and you’ll be joining the group of like-minded thought-leaders, innovators, and Web3 enthusiasts who will have a front row seat to shaping a collective future that advances the positive economic impact of blockchain technology.",
    },
    {
      icon: bulletTwo,
      title: "Participation",
      text: "Through community votes, roundtable discussions with policy leaders, and exclusive events, Lobby3 token holders will participate in building a new community for the Web3 ecosystem. As a community, we will prioritize and propose new policy, and aggregate new ideas that need to be top of mind for our leaders.",
    },
    {
      icon: bulletThree,
      title: "Token",
      text: "Lobby3 will be available in three tiers. Our goal is to make this community accessible to as many people as possible, while also meaningfully moving the needle with well-resourced advocacy in Washington D.C. Each minted token adds your unique avatar to the National Mall, and as more community members join Lobby3, the National Mall will fill with our members.",
    },
  ];

  return (
    <Wrapper background={Colors.charcoal()}>
      <div className={css(styles.container)} id={"how-it-works"}>
        <Text type={"h1"} style={styles.title}>
          {"How it Works"}
        </Text>
        <div className={css(styles.content)}>
          <img className={css(styles.graphic)} src={bulletPoints} />
          <div className={css(styles.points)}>
            {bullets.map((bullet, idx) => {
              return (
                <div className={css(styles.bullet)} key={`bullet_${idx}`}>
                  <Text
                    type={"h3"}
                    style={styles.bulletHeader}
                    label={bullet.title}
                  />
                  <Text>{bullet.text}</Text>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

const styles = StyleSheet.create({
  title: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    whiteSpace: "pre-wrap",
    padding: "20px 0",
  },
  iconContainer: {
    minWidth: 135,
    width: 135,
    maxWidth: 135,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginRight: 40,
  },

  container: {
    padding: "80px 160px",
    minHeight: 500,
    width: "100%",
    boxSizing: "border-box",
    background: Colors.charcoal(),
    color: Colors.gray(),
    maxWidth: 1750,
    "@media only screen and (max-width: 767px)": {
      padding: 40,
    },
  },
  content: {
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-start",
    width: "100%",
    marginTop: 25,
    "@media only screen and (max-width: 767px)": {
      justifyContent: "flex-start",
    },
  },
  graphic: {
    marginRight: 50,
    "@media only screen and (max-width: 767px)": {
      marginLeft: -50,
      maxHeight: 600,
    },
    "@media only screen and (max-width: 415px)": {
      marginLeft: -80,
      marginRight: 20,
    },
  },
  points: {
    color: Colors.gray(),
  },
  bullet: {
    padding: 0,
    width: 700,
    "@media only screen and (max-width: 767px)": {
      width: "100%",
    },
  },
  bulletHeader: {
    margin: "28px 0",
  },
});

export default HowItWorks;
