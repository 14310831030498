import { StyleSheet, css } from "aphrodite";
import { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import "./App.css";

// Components
import Header from "./components/Header";
import NavMenuMobile from "./components/NavMenuMobile";
import Puzzle from "./components/sections/Puzzle";
import About from "./components/sections/About";
import HowItWorks from "./components/sections/HowItWorks";
import Membership from "./components/sections/Membership";
import Banner from "./components/Banner";
import Roadmap from "./components/sections/Roadmap";
import FAQ from "./components/sections/FAQ";
import Team from "./components/sections/Team";
import Invitation from "./components/sections/Invitation";
import Footer from "./components/sections/Footer";
import PoapScreen from "./components/PoapScreen";
import OurStoryScreen from "./components/OurStoryScreen";

//Mint Flow
import MintScreen from "./components/sections/Mint/MintScreen";
import { createAlchemyWeb3 } from "@alch/alchemy-web3";

const lobby3ContractABI = require("./contracts/Lobby3MembershipToken.json").abi;
const lobby3ContractAddress = process.env.REACT_APP_LOBBY3_TOKEN_CONTRACT;

if (typeof window === "object") {
  const ALCHEMY_KEY = process.env.REACT_APP_ALCHEMY_URL;

  const web3 = createAlchemyWeb3(ALCHEMY_KEY);

  window.web3 = web3;
  window.lobby3ContractTest = new web3.eth.Contract(
    lobby3ContractABI,
    lobby3ContractAddress
  );
}

function App() {
  const [isMobileDimension, setIsMobileDimension] = useState(
    determineIsMobile()
  );
  const [windowWidth, setWindowWidth] = useState(determineWindowWidth());

  useEffect(() => {
    function onResize() {
      setIsMobileDimension(determineIsMobile());
      setWindowWidth(determineWindowWidth());
    }

    window.addEventListener("resize", onResize);

    return () => window.removeEventListener("resize", onResize);
  }, []);

  function determineIsMobile() {
    return window.innerWidth <= 768;
  }

  function determineWindowWidth() {
    return window.innerWidth;
  }

  const windowProps = {
    windowWidth,
    isMobileDimension,
  };

  return (
    <div className={css(styles.app) + " App"}>
      <header className="App-header">
        <BrowserRouter>
          <div className={css(styles.body)}>
            <Banner />
            <Routes>
              <Route
                path={"/"}
                element={
                  <>
                    {isMobileDimension && <NavMenuMobile />}
                    <Header isMobile={isMobileDimension} />
                    <Puzzle
                      isMobile={isMobileDimension}
                      windowProps={windowProps}
                    />
                    <About />
                    <HowItWorks />
                    <Membership />
                    <Roadmap />
                    <FAQ />
                    <Team />
                    <Invitation />
                  </>
                }
              />
              <Route
                path={"/mint"}
                element={
                  <div className={css(styles.inner)}>
                    <MintScreen {...windowProps} />
                  </div>
                }
              />
              <Route
                path={"/mint/:slug"}
                element={
                  <div className={css(styles.inner)}>
                    <MintScreen {...windowProps} />
                  </div>
                }
              />
              <Route
                path={"/poap"}
                element={<PoapScreen isMobileDimension={isMobileDimension} />}
              />
              <Route
                path="/ourstory"
                element={
                  <>
                    {isMobileDimension && <NavMenuMobile />}
                    <Header isMobile={isMobileDimension} />
                    <OurStoryScreen />
                  </>
                }
              />
            </Routes>
            <Footer isRow={true} />
          </div>
        </BrowserRouter>
      </header>
      <ToastContainer theme={"dark"} />
    </div>
  );
}

const styles = StyleSheet.create({
  app: {},
  body: {
    position: "relative",
    margin: "0 auto",
    overflow: "hidden",
    "@media only screen and (min-width: 1500px)": {},
  },
  inner: {
    minHeight: "calc(100vh - 50px - 122px)",
    background: "rgb(235, 235, 235)",
  },
});

export default App;
