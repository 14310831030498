import { StyleSheet, css } from "aphrodite";
import { useState, useRef } from "react";
import { Colors } from "../../utils/colors";

import OptionCardOverlay from "./OptionCardOverlay";

import checkIcon from "../../assets/icons/check.svg";

const OptionCard = ({
  type,
  shirt,
  pants,
  hands,
  faces,
  orientation,
  option,
  onClick,
}) => {
  const imgRef = useRef();
  const [isHidden, setIsHidden] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const isActive = _isActive();
  const isFaceOption = type === "faces" || type === "nfts";
  const isNFTOption = type === "nfts";

  function _isActive() {
    switch (type) {
      case "hands":
        return hands.id === option.id;
      case "shirts":
        return shirt.id === option.id;
      case "pants":
        return pants.id === option.id;
      case "nfts":
      case "faces":
        return faces.filter((face) => face.src === option.src).length > 0;
      default:
        return false;
    }
  }

  function _showOptionOverlay() {
    return (
      (isHovered && isFaceOption) ||
      faces.filter((face) => face.src === option.src).length > 0
    );
  }

  function _onClick(e) {
    e && e.stopPropagation();
    onClick && onClick(option);
  }

  function _onFaceOptionClick(option, cubeSurfaceIndex) {
    onClick && onClick(option, cubeSurfaceIndex);
  }

  function _onMouseEnter() {
    !isHovered && setIsHovered(true);
  }

  function _onMouseLeave() {
    isHovered && setIsHovered(false);
  }

  function _handleImageError() {
    setIsHidden(true);
  }

  const optionOverlayProps = {
    faces,
    option,
    orientation,
    onClick: _onFaceOptionClick,
    isActive: _showOptionOverlay(),
  };

  return (
    <div
      className={css(
        styles.card,
        isActive && styles.activeCard,
        isHidden && styles.hidden
      )}
      onClick={_onClick}
      onMouseEnter={_onMouseEnter}
      onMouseLeave={_onMouseLeave}
    >
      <div
        className={
          css(styles.checkmark, isActive && styles.activeCheckmark) +
          " checkmark"
        }
      >
        {isActive && <img src={checkIcon} />}
      </div>
      <img
        ref={imgRef}
        src={isFaceOption ? option.src : option[orientation]}
        className={css(isNFTOption ? styles.fullImg : styles.img)}
        draggable={false}
        onError={_handleImageError}
      />
      {_showOptionOverlay() && <OptionCardOverlay {...optionOverlayProps} />}
    </div>
  );
};

const styles = StyleSheet.create({
  card: {
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: Colors.lightCharcoal(),
    height: 150,
    minHeight: 150,
    cursor: "pointer",
    transition: "all ease-out 0.1s",
    overflow: "hidden",
    border: `1px solid ${Colors.charcoal()}`,
    ":hover": {
      borderColor: Colors.lime(),
      boxShadow: `0 0 10px ${Colors.lime(0.7)}`,
    },
    ":hover face-overlay": {
      display: "flex",
    },
  },
  activeCard: {
    borderColor: Colors.lime(),
    boxShadow: `0 0 10px ${Colors.lime(0.7)}`,
  },
  img: {
    height: 80,
    minHeight: 80,
    maxHeight: 80,
    objectFit: "contain",
  },
  fullImg: {
    height: "100%",
    minHeight: "100%",
    maxHeight: "100%",
    objectFit: "cover",
  },
  checkmark: {
    position: "absolute",
    display: "none", // flips to flex onHover by parent
    justifyContent: "center",
    alignItems: "center",
    top: 10,
    right: 10,
    height: 20,
    minHeight: 20,
    maxHeight: 20,
    width: 20,
    minWidth: 20,
    maxWidth: 20,
    borderRadius: "100%",
    backgroundColor: Colors.lime(),
    boxShadow: "0 0 10px rgba(0, 0, 0, 0.3)",
  },
  activeCheckmark: {
    display: "flex",
  },
  hidden: {
    display: "none",
  },
});

export default OptionCard;
