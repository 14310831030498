import { createAlchemyWeb3 } from "@alch/alchemy-web3";
import ENS from "ethjs-ens";
import { toast } from "react-toastify";
import DiamondBackground from "../assets/L3_MintableDiamond.webp";
import Helpers from "./helpers";

const { REACT_APP_ALCHEMY_URL, REACT_APP_ENV, REACT_APP_BASE_URL } =
  process.env;

export const BASE_URL = REACT_APP_BASE_URL
  ? REACT_APP_BASE_URL
  : REACT_APP_ENV === "production"
  ? "https://api.lobby3.io"
  : REACT_APP_ENV === "staging"
  ? "https://staging-api.lobby3.io"
  : "http://localhost:8000";

if (typeof window === "object") {
  const ALCHEMY_KEY = REACT_APP_ALCHEMY_URL;

  const web3 = createAlchemyWeb3(ALCHEMY_KEY);

  window.web3 = web3;
}

const { web3 } = window;

/*
DO NOT REMOVE
this function override needed to get ethjs-ens working.
https://github.com/ethjs/ethjs/issues/6
- joshlee
*/
if (web3 && web3.currentProvider) {
  web3.currentProvider.sendAsync = web3?.currentProvider?.send;
  const provider = web3.currentProvider;
  // const network = "isProductionEnv ? "1" : "2"";
  const network = "1";
  const ens = new ENS({ provider, network });
  window.ens = ens;
}

function initEnsService() {
  if (typeof window === "object") {
    const provider = web3.currentProvider;
    const network = "4";
    // const network = "isProductionEnv ? "1" : "2"";
    const ens = new ENS({ provider, network });

    /*
    DO NOT REMOVE
    this function override needed to get ethjs-ens working.
    https://github.com/ethjs/ethjs/issues/6
    - joshlee
    */
    web3.currentProvider.sendAsync = web3.currentProvider.send;

    window.ens = ens;
  }
}

export const getEnsFromAddress = async (address) => {
  if (!window?.ens) initEnsService();

  try {
    const ens = await window.ens.reverse(address);
    if (!ens) return null;
    const ensAddress = await window.ens.lookup(ens);
    // best practice: we need to verify that the ens & address are linked
    return address === ensAddress ? ens : "";
  } catch {
    return null;
  }
};

export const connectWallet = async () => {
  if (window.ethereum) {
    try {
      const addressArray = await window.ethereum.request({
        method: "eth_requestAccounts",
      });
      const obj = {
        status: "Connected.",
        address: addressArray[0],
      };
      return obj;
    } catch (err) {
      console.log(err);
      return {
        address: "",
        status: err.message,
      };
    }
  } else {
    return {
      address: "",
      status:
        "You must install Metamask, a virtual Ethereum wallet, in your browser.",
    };
  }
};

export async function fetchMuralAvatars() {
  return fetch(BASE_URL + "/profile/mural/?nft_set=true")
    .then((data) => data.json())
    .then((body) => {
      const { results } = body;
      return results;
    })
    .catch((error) => console.log("error", error));
}

export const getCurrentWalletConnected = async () => {
  if (window.ethereum) {
    try {
      const addressArray = await window.ethereum.request({
        method: "eth_accounts",
      });
      if (addressArray.length > 0) {
        return {
          address: addressArray[0],
          status: "Connected.",
        };
      } else {
        return {
          address: "",
          status: "Connect to Metamask to start.",
        };
      }
    } catch (err) {
      return {
        address: "",
        status: err.message,
      };
    }
  } else {
    return {
      address: "",
      status:
        "You must install Metamask, a virtual Ethereum wallet, in your browser.",
    };
  }
};

export const getAccounts = async () => {
  if (window?.ethereum) {
    const _accounts = [];
    try {
      _accounts.concat(
        await window.ethereum.request({
          method: "eth_requestAccounts",
        })
      );
      return _accounts;
    } catch {
      return _accounts;
    }
  } else {
    // The user doesn't have Metamask installed.
  }
};

export const alchemyGetNftsByAddress = async (address, pageKeyInput) => {
  const params = {
    owner: address,
    withMetadata: true,
  };

  if (pageKeyInput) {
    params.pageKey = pageKeyInput;
  }
  try {
    const alchemyRes = await web3.alchemy.getNfts(params);
    const { ownedNfts, pageKey, totalCount } = alchemyRes;

    // dedupe & remove nfts that don't have any uri, metadata, or images
    const hash = {};
    const nftsToReturn = ownedNfts
      .filter((nft) => {
        if (!nft) return false;
        const img = nft?.metadata?.image;
        if (!img) return false;
        if (hash[img]) return false;
        hash[img] = true;

        return img;
      })
      .map((nft) => {
        nft.src = setNftImgSrc(nft);
        nft.type = "nft";
        nft.id = nft?.id?.tokenId;
        return nft;
      });
    return { userOwnedNfts: nftsToReturn, pageKeyNext: pageKey, totalCount };
  } catch (err) {
    console.log(err);
    return { userOwnedNfts: [], pageKeyNext: null };
  }
};

function setNftImgSrc(nft) {
  if (!nft?.media.length || !nft?.metadata?.image) return null;
  return nft?.metadata?.image ? nft?.metadata?.image : nft?.media[0]?.gateway;
}

export function getColorNameFromHex(color) {
  const { hex } = color;
  const COLOR_API = `https://www.thecolorapi.com/id?hex=${hex.slice(1)}`;
  return fetch(COLOR_API)
    .then((body) => body.json())
    .then((res) => res)
    .catch((err) => {
      console.log("err", err);
      return {};
    });
}

// SERVER API
export async function getUserTokens(ethAddress) {
  return fetch(BASE_URL + `/profile/?eth_address=${ethAddress}`)
    .then(Helpers.checkStatus)
    .then(Helpers.parseJSON)
    .then((body) => {
      if (body.results.length) {
        return body.results.map(formatTokenName);
      }
      return body.results;
    })
    .catch((error) => console.log("error", error));
}

function formatTokenName(userToken) {
  try {
    const { level, token_id } = userToken;
    const levels = {
      standard: "Member",
      gold: "Advocate",
      diamond: "Founder",
    };
    userToken.name =
      token_id !== null && token_id !== undefined
        ? levels[level] + ` #${token_id}`
        : "Lobby3 " + levels[level];
    return userToken;
  } catch (err) {
    userToken.name = "Lobby3 Member";
    return userToken;
  }
}

export async function getImageFromProxy(imageUrl) {
  return fetch(BASE_URL + `/proxy/?url=${imageUrl}`)
    .then((data) => data.blob())
    .then((blob) => {
      const urlCreator = window.URL || window.webkitURL;
      return urlCreator.createObjectURL(blob);
    });
}

export async function getMuralAvatars() {
  return fetch(BASE_URL + "/profile/?nft_set=true")
    .then(Helpers.checkStatus)
    .then(Helpers.parseJSON)
    .then((body) => {
      return body.results;
    })
    .catch((error) => console.log("error", error));
}

export async function postTokenTransaction(
  transactionHash,
  numOfTokens,
  membership,
  ethAddress
) {
  const transaction = {
    eth_address: ethAddress,
    transaction_hash: transactionHash,
    level: membership?.tier.toLowerCase(),
  };
  const transactions = new Array(numOfTokens).fill(transaction);

  const POST_CONFIG = {
    method: "POST",
    body: JSON.stringify(transactions),
    headers: { "Content-Type": "application/json" },
  };

  return fetch(BASE_URL + "/profile/", POST_CONFIG)
    .then(Helpers.checkStatus)
    .then(Helpers.parseJSON)
    .then((data) => {
      return data;
    })
    .catch((err) => {
      console.log("err", err);
      return err;
    });
}

export async function patchAvatar(formData, id) {
  const PATCH_CONFIG = {
    method: "PATCH",
    body: formData,
  };

  return fetch(BASE_URL + `/profile/${id}/`, PATCH_CONFIG)
    .then(Helpers.checkStatus)
    .then(Helpers.parseJSON)
    .then((data) => {
      return data;
    })
    .catch((err) => {
      console.log("err", err);
      let message = err?.message?.detail;

      if (!message) {
        message =
          "Sorry, an error happened! Please post in the discord and try again later.";
      }

      if (err?.message?.non_field_errors) {
        message = err?.message?.non_field_errors[0];
      }

      toast.error(message, {
        toastId: "avatar-patch",
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      return { err, error: true };
    });
}

export async function checkNFTExists(profileId) {
  return fetch(BASE_URL + `/profile/${profileId}/nft_exists/`)
    .then(Helpers.checkStatus)
    .then(Helpers.parseJSON)
    .then((res) => {
      return { tokenId: res.token_id, exists: true };
    })
    .catch((err) => {
      return { exists: false };
    });
}

export async function alchemyGetUserTokens(address) {
  try {
    const alchemyRes = await web3.alchemy.getNfts({
      owner: address,
      contractAddresses: [process.env.REACT_APP_LOBBY3_TOKEN_CONTRACT],
      withMetadata: true,
    });
    const { ownedNfts } = alchemyRes;

    return ownedNfts.map((nft, i) => {
      const tokenId = nft?.id?.tokenId;
      nft.src = DiamondBackground;
      nft.type = "token";
      nft.name = "Lobby3 Avatar";
      nft.id = `Avatar: ${tokenId}`;
      nft.title = `Avatar: ${tokenId}`;
      if (i === 0) {
        _formatDummyAvatar(nft);
      }
      return nft;
    });
  } catch (err) {
    return [];
  }
}

function _formatDummyAvatar(avatar) {
  const hands = {
    back: "/avatar/back/hands/Back_Hand1.png",
    front: "/avatar/front/hands/Front_Hand1.png",
    id: "hands-1",
    type: "hands",
    value: "teal",
  };

  const pants = {
    back: "/avatar/back/pants/Back_Pants1.png",
    front: "/avatar/front/pants/Front_Pants1.png",
    id: "pants-1",
    type: "pants",
    value: "navy",
  };

  const shirt = {
    back: "/avatar/back/shirts/Back_Shirt4.png",
    front: "/avatar/front/shirts/Front_Shirt4.png",
    id: "shirt-4",
    type: "shirt",
    value: "red",
  };

  const color = {
    hex: "#ffffff",
    hsv: { h: 0, s: 0.010279605263157894, v: 100, a: undefined },
    rgb: { r: 255, g: 255, b: 255, a: undefined },
  };

  const faces = [
    {
      id: "face-4",
      src: "/avatar/faces/face4.png",
      type: "face",
      value: "happy",
    },
    {
      id: "face-5",
      src: "/avatar/faces/face5.png",
      type: "face",
      value: "mysterious",
    },
    {
      id: "face-4",
      src: "/avatar/faces/face4.png",
      type: "face",
      value: "happy",
    },
  ];

  avatar.metadata = {
    faces,
    color,
    hands,
    pants,
    shirt,
  };

  return avatar;
}
